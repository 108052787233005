<template>
  <div>
    <v-card class="text-center">
      <v-tabs v-model="tab">
        <v-tab>Active</v-tab>
        <v-tab>In Active</v-tab>
      </v-tabs>

      <div class="d-flex justify-space-between mb-5 pa-4">
        <h2 class="font-weight-medium d-inline">Media</h2>

        <div class="d-flex">
          <div style="width: 400px; margin-right: 20px; max-width: 100%">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              dense
              hide-details
              label="Search by Title ..."
              solo
              @change="loadData"
              @click:append="loadData"
            />
          </div>
          <v-btn
            color="primary"
            v-if="getUser().scopes.includes('media:new')"
            @click="toggleModal"
            v-html="newModalOpen ? 'Close' : 'Add New'"
          ></v-btn>
          <v-btn icon style="margin-left: 10px">
            <v-icon @click="openFilter">mdi-filter</v-icon>
          </v-btn>
        </div>
      </div>

      <div v-show="newModalOpen" class="new-media-container drag-area">
        <h3 id="header" class="mb-1">Drop files to upload</h3>
        <span class="mb-5">or</span>
        <input id="file" multiple style="display: none" type="file" />
        <label id="button" class="selectBtn" for="file">Select Files</label>
        <span class="mb-2 mt-10" style="font-size: 13px"
          >Maximum upload file size: 250MB</span
        >
      </div>

      <span
        v-if="!loading && data && data.length <= 0"
        id="mainDiv"
        class="mx-auto d-block pb-6"
        >No Media Found</span
      >

      <v-row
        v-if="!loading && data && data.length > 0"
        id="mainDiv"
        class="mt-2 px-4"
      >
        <v-col
          v-for="(item, index) in data"
          :key="index"
          class="col-6 col-sm-4 col-md-3 col-lg-2 d-flex flex-col items-center"
        >
          <div
            class="d-flex flex-col items-center mediaCol"
            style="width: 100%"
            @click="openEditModal(item)"
          >
            <div
              v-if="item.type === 1"
              class="d-flex justify-center"
              style="background-color: white; width: 100%"
            >
              <img
                :src="item.url"
                alt="Media Image"
                class="image"
                loading="lazy"
                style="object-fit: cover"
              />
            </div>

            <div
              v-else-if="item.type === 2"
              class="d-flex justify-center relative"
              style="background-color: white; width: 100%"
            >
              <img
                :src="item.thumbnailUrl"
                alt="Video Thumbnail"
                class="image"
                loading="lazy"
                style="object-fit: cover"
              />
              <div
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background: rgba(0, 0, 0, 0.3);
                "
              ></div>
              <img alt="" class="play-icon" src="../../assets/play-icon.png" />
            </div>

            <v-icon
              v-else-if="item.type === 3"
              class="image fileIcon"
              color="#4396e4"
              size="60"
            >
              mdi-file-document
            </v-icon>

            <h5 class="font-weight-light fileName">
              {{ item.title ? item.title : '----------' }}
            </h5>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="loading" id="mainDiv" class="mt-2 mx-4">
        <v-col v-for="i in 18" :key="i" cols="12" lg="2" md="3" sm="4">
          <v-skeleton-loader type="image" />
        </v-col>
      </v-row>

      <div class="d-flex justify-center align-center mt-8">
        <v-pagination
          :length="totalPages + 1"
          :value="currentPage + 1"
          class="my-2"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          total-visible="10"
          @input="loadMoreMedia"
        />
        <v-text-field
          v-model="pageInput"
          :max="totalPages + 1"
          dense
          hide-details
          min="1"
          outlined
          placeholder="Page #"
          style="max-width: 80px"
          type="number"
          v-on:keydown.enter="loadMoreMedia(parseInt(pageInput), true)"
        />
      </div>
    </v-card>

    <v-dialog v-model="showFilter" persistent width="40%">
      <v-card>
        <v-card-title>Filter By Field</v-card-title>
        <div class="px-6 pt-2 pb-6">
          <v-select
            :items="[...filterAttr.category.map((item) => item.name)]"
            :value="filterSelectedAttr.category"
            clearable
            dense
            label="Category"
            outlined
            placeholder="Select Category"
            @change="selectFilterCategory"
          />

          <v-select
            :items="[...filterAttr.subcategory]"
            :value="filterSelectedAttr.subcategory"
            clearable
            dense
            label="Subcategory"
            outlined
            placeholder="Select Subcategory"
            @change="selectFilterSubCategory"
          >
            <template v-slot:no-data>
              <div class="px-2">
                <p v-if="filterSelectedAttr.category" class="ma-0 text-caption">
                  No data available
                </p>
                <p v-else class="ma-0 text-caption">Select Category First</p>
              </div>
            </template>
          </v-select>

          <v-select
            :items="[...filterAttr.type]"
            :value="filterSelectedAttr.type"
            clearable
            dense
            label="Type"
            outlined
            placeholder="Select Type"
            @change="selectFilterType"
          />

          <div class="d-flex justify-space-between">
            <v-btn
              class="mr-2"
              @click="cancelFilter"
              color="primary"
              outlined
              elevation="0"
              >Cancel</v-btn
            >
            <div>
              <v-btn
                class="mr-2"
                @click="filterClear"
                dark
                color="red"
                elevation="0"
                >Clear</v-btn
              >
              <v-btn
                class="ml-2"
                color="primary"
                @click="applyFilter"
                elevation="0"
                >Apply
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      eager
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="false" id="editModalBtn" v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template>
      <v-card class="rounded-0">
        <v-toolbar color="primary" dark dense elevation="0">
          <v-btn dark icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="selectedMedia"
            >{{ selectedMedia.title ? selectedMedia.title : 'No Title' }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!--                <v-btn-->
            <!--                    dark-->
            <!--                    text-->
            <!--                    @click="update"-->
            <!--                >-->
            <!--                  Save-->
            <!--                </v-btn>-->
          </v-toolbar-items>
        </v-toolbar>
        <v-list class="pa-0" subheader three-line>
          <div>
            <v-row class="ma-0">
              <v-col
                class="flex justify-space-between items-center fileContainer overflow-hidden"
                cols="12"
                md="8"
                sm="6"
              >
                <v-btn
                  :disabled="!enableSelectPrevBtn"
                  class="mr-2 rounded-circle"
                  color="primary"
                  dark
                  icon
                  outlined
                  @click="selectPrevious"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>

                <div
                  v-if="selectedEditMedia.type === 1"
                  class="d-flex justify-center"
                  style="background-color: white; width: 100%; height: 100%"
                >
                  <img :src="selectedEditMedia.url" alt="" class="editImage" />
                </div>

                <div
                  v-if="selectedEditMedia.type === 2"
                  class="d-flex justify-center"
                  style="background-color: white; width: 100%; height: 100%"
                >
                  <video
                    :src="selectedEditMedia.url"
                    class="editImage"
                    controls
                  ></video>
                </div>

                <v-icon
                  v-if="selectedEditMedia.type === 3"
                  class="editFileIcon"
                  color="#4396e4"
                  size="120"
                >
                  mdi-file-document
                </v-icon>

                <v-btn
                  :disabled="!enableSelectNextBtn"
                  class="ml-2 rounded-circle"
                  color="primary"
                  dark
                  icon
                  outlined
                  @click="selectNext"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-row class="pt-5">
                  <v-col cols="12">
                    <v-checkbox
                      v-model="isActive"
                      label="Is Active Media?"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-if="selectedEditMedia"
                      v-model="selectedEditMedia.title"
                      dense
                      hide-details="true"
                      label="Title"
                      outlined
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-if="selectedEditMedia"
                      v-model="selectedEditMedia.description"
                      dense
                      hide-details="true"
                      label="Description"
                      outlined
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      :items="[...categories.map((item) => item.name)]"
                      :value="selectedEditMedia.category"
                      clearable
                      dense
                      hide-details="true"
                      label="Category"
                      outlined
                      placeholder="Select Category"
                      @change="selectCategory"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      :items="[...subcategories]"
                      :value="selectedEditMedia.sub_category"
                      clearable
                      dense
                      hide-details="true"
                      label="Subcategory"
                      outlined
                      placeholder="Select Subcategory"
                      @change="selectSubCategory"
                    >
                      <template v-slot:no-data>
                        <div class="px-2">
                          <p
                            v-if="selectedEditMedia.category"
                            class="ma-0 text-caption"
                          >
                            No data available
                          </p>
                          <p v-else class="ma-0 text-caption">
                            Select Category First
                          </p>
                        </div>
                      </template>
                    </v-select>
                  </v-col>

                  <!--                  <v-col v-if="selectedEditMedia.type===3" cols="12">-->
                  <!--                    <v-file-input-->
                  <!--                        v-model="selectedEditMedia.pdfThumbnail"-->
                  <!--                        dense-->
                  <!--                        hide-details-->
                  <!--                        label="Select Pdf thumbnail"-->
                  <!--                        outlined-->
                  <!--                        prepend-icon=""-->
                  <!--                        accept="image/*"-->
                  <!--                    ></v-file-input>-->
                  <!--                  </v-col>-->

                  <div
                    class="px-4 d-flex justify-space-between align-center"
                    style="width: 100%; gap: 10px"
                  >
                    <div
                      v-if="
                        selectedEditMedia.type === 3 && selectedPdfThumbnailUrl
                      "
                      style="width: 50%"
                    >
                      <img
                        :src="selectedPdfThumbnailUrl"
                        alt="thumbanil"
                        style="
                          width: 100%;
                          height: 170px;
                          object-fit: scale-down;
                        "
                      />
                      <h5
                        v-if="selectedPdfThumbnailUrl"
                        id="img-uploaded-title"
                        class="font-weight-light d-flex justify-center align-center"
                        style="width: 100% !important"
                      >
                        Thumbnail
                      </h5>
                    </div>

                    <div
                      v-if="
                        selectedEditMedia.type === 3 &&
                        selectedEditMedia.pdfThumbnail &&
                        previewUrl
                      "
                      style="width: 50%"
                    >
                      <img
                        :src="previewUrl"
                        alt="thumbanil"
                        style="
                          width: 100%;
                          height: 170px;
                          object-fit: scale-down;
                        "
                      />
                      <h5
                        id="img-preview-title"
                        class="font-weight-light d-flex justify-center align-center"
                        style="width: 100% !important"
                      >
                        New Thumbnail
                      </h5>
                    </div>
                  </div>

                  <v-col class="text-end" cols="12">
                    <v-btn
                      class="mr-4"
                      color="primary darken-1"
                      outlined
                      v-if="selectedMedia.question_media_id"
                      @click="
                        $router.push(
                          `/question-media?id=${selectedMedia.question_media_id}`
                        )
                      "
                    >
                      Media Details
                    </v-btn>

                    <v-btn
                      class="mr-2"
                      color="red darken-1"
                      outlined
                      v-if="getUser().scopes.includes('media:delete')"
                      @click="deleteMedia"
                    >
                      Delete
                    </v-btn>

                    <a
                      v-show="false"
                      id="link"
                      :href="selectedMedia.url"
                      target="_blank"
                    />

                    <v-btn
                      class="mr-2 ml-2"
                      color="primary darken-1"
                      outlined
                      @click="openInNewTab"
                    >
                      Preview in new Tab
                    </v-btn>
                    <v-btn
                      class="ml-2"
                      color="primary"
                      elevation="0"
                      v-if="getUser().scopes.includes('media:edit')"
                      @click="update"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-list>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="loadingDialog" :message="loaderMessage" />
  </div>
</template>

<script>
import { MediaGalleryService } from '@/services/media-gallery-service';
import { storage } from '@/plugins/firebase';
import LoadingDialog from '@/components/LoadingDialog';
import { CategoryService } from '@/services/category-service';
import axios from 'axios';
import { getUser, getVideoDuration } from '@/utils/local';

export default {
  name: 'Media',

  components: {
    LoadingDialog
  },

  watch: {
    tab() {
      this.tab === 0 ? (this.isActive = true) : (this.isActive = false);
    },

    isActive() {
      this.selectedEditMedia.category = '';
      this.selectedEditMedia.sub_category = '';
      this.loadCategories();
      this.filterClear();
    }
  },

  data() {
    return {
      tab: 0,
      isActive: true,
      selectedPdfThumbnailUrl: '',
      pageInput: '',
      isUpdated: false,
      enableSelectPrevBtn: true,
      enableSelectNextBtn: true,
      loading: false,
      loadingDialog: false,
      showFilter: false,
      search: '',
      filterQuery: '',
      categories: [],
      subcategories: [],
      loaderMessage: 'Loading ...',
      newModalOpen: false,
      dialog: false,
      service: new MediaGalleryService(),
      categoryService: new CategoryService(),
      selectedMedia: {
        title: ''
      },
      selectedEditMedia: {
        title: ''
      },
      filterAttr: {
        category: [],
        subcategory: [],
        type: ['Images', 'Videos', 'PDFs']
      },
      filterSelectedAttr: {
        category: '',
        subcategory: '',
        type: null
      },
      data: [],
      totalPages: 0,
      currentPage: 0,
      perPage: 18
    };
  },

  computed: {
    previewUrl() {
      return this.selectedEditMedia &&
        this.selectedEditMedia.pdfThumbnail &&
        this.selectedEditMedia.pdfThumbnail.type.includes('image')
        ? URL.createObjectURL(this.selectedEditMedia.pdfThumbnail)
        : null;
    }
  },

  methods: {
    getUser,
    getVideoDuration,
    async closeDialog() {
      if (this.isUpdated) {
        await this.loadData(false);
        this.isUpdated = false;
      }
      this.dialog = false;
    },

    selectPrevious() {
      let index;
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === this.selectedEditMedia.id) {
          index = i;
          break;
        }
      }
      if (index > 0) {
        this.selectedMedia = { ...this.data[index - 1] };
        this.subcategories = this.categories
          .find((item) => item.name === this.selectedMedia.category)
          ?.subCategories.map((item) => item.name);
        this.selectedEditMedia = { ...this.data[index - 1] };

        if (this.selectedEditMedia.type === 3) {
          this.checkImageUpload(this.selectedMedia);
        }
      }
      this.toggleNextPrevBtn();
    },

    selectNext() {
      let index;
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === this.selectedEditMedia.id) {
          index = i;
          break;
        }
      }
      if (index < this.data.length - 1) {
        this.selectedMedia = { ...this.data[index + 1] };
        this.subcategories = this.categories
          .find((item) => item.name === this.selectedMedia.category)
          ?.subCategories.map((item) => item.name);
        this.selectedEditMedia = { ...this.data[index + 1] };

        if (this.selectedEditMedia.type === 3) {
          this.checkImageUpload(this.selectedMedia);
        }
      }
      this.toggleNextPrevBtn();
    },

    toggleNextPrevBtn() {
      let index;
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === this.selectedEditMedia.id) {
          index = i;
          break;
        }
      }
      if (index <= 0) {
        this.enableSelectPrevBtn = false;
      } else {
        this.enableSelectPrevBtn = true;
      }

      if (index >= this.data.length - 1) {
        this.enableSelectNextBtn = false;
      } else {
        this.enableSelectNextBtn = true;
      }
    },

    selectCategory(value) {
      if (!value) {
        this.subcategories = [];
        this.selectedEditMedia.category = '';
        this.selectedEditMedia.sub_category = '';
      } else {
        this.subcategories = this.categories
          .find((item) => item.name === value)
          .subCategories.map((item) => item.name);
        this.selectedEditMedia.category = value;
      }
    },

    selectSubCategory(value) {
      if (value) {
        this.selectedEditMedia.sub_category = value;
      } else {
        this.selectedEditMedia.sub_category = '';
      }
    },

    selectFilterCategory(value) {
      if (!value) {
        this.filterAttr.subcategory = [];
        this.filterSelectedAttr.category = '';
        this.filterSelectedAttr.subcategory = '';
      } else {
        this.filterAttr.subcategory = this.filterAttr.category
          .find((item) => item.name === value)
          .subCategories.map((item) => item.name);
        this.filterSelectedAttr.category = value;
      }
    },

    selectFilterSubCategory(value) {
      if (value) {
        this.filterSelectedAttr.subcategory = value;
      } else {
        this.filterSelectedAttr.subcategory = '';
      }
    },

    selectFilterType(value) {
      if (value) {
        this.filterSelectedAttr.type = value;
      } else {
        this.filterSelectedAttr.type = null;
      }
    },

    toast(text) {
      this.$toast.error(text, {
        position: 'top-right',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false
      });
    },

    openInNewTab() {
      document.getElementById('link').click();
    },

    openFilter() {
      this.showFilter = true;
    },

    async generateFilterQuery() {
      let query = '';
      if (this.filterSelectedAttr.category !== '') {
        query += `&category=${this.filterSelectedAttr.category}`;
      }
      if (this.filterSelectedAttr.subcategory !== '') {
        query += `&sub_category=${this.filterSelectedAttr.subcategory}`;
      }
      if (this.filterSelectedAttr.type !== null) {
        let type = this.filterSelectedAttr.type;
        let code;
        if (type === 'Images') {
          code = 1;
        } else if (type === 'Videos') {
          code = 2;
        } else if (type === 'PDFs') {
          code = 3;
        }
        query += `&type=${code}`;
      }
      this.filterQuery = query;
    },

    async applyFilter() {
      this.showFilter = false;
      await this.generateFilterQuery();
      await this.loadData();
    },

    async filterClear() {
      this.filterSelectedAttr = {
        category: '',
        subcategory: '',
        type: null
      };
      await this.applyFilter();
    },

    cancelFilter() {
      this.showFilter = false;
    },

    toggleModal() {
      this.newModalOpen = !this.newModalOpen;
    },

    openEditModal(item) {
      this.selectedMedia = { ...item };
      this.subcategories = this.categories
        .find((item) => item.name === this.selectedMedia.category)
        ?.subCategories.map((item) => item.name);
      this.selectedEditMedia = { ...item };
      this.toggleNextPrevBtn();
      document.getElementById('editModalBtn').click();

      if (this.selectedEditMedia.type === 3) {
        this.checkImageUpload(this.selectedMedia);
      }
    },

    async checkImageUpload(item) {
      let parts = item.url.split('pdfs%2F');

      let newUrl = parts[0] + 'pdfs%2Fthumbnails%2F' + parts[1];

      this.loaderMessage = 'Loading...';
      this.loadingDialog = true;
      await axios
        .get(newUrl)
        .then((response) => {
          if (response.status === 200) {
            this.selectedPdfThumbnailUrl = newUrl;
          }
        })
        .catch((error) => {
          if (error.status === 404) {
            this.selectedPdfThumbnailUrl = null;
          }
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },

    async loadData(resetToFirstPage = true) {
      try {
        this.loading = true;
        await this.terminateRequests();

        if (resetToFirstPage) {
          this.currentPage = 0;
          this.pageInput = '';
        }
        let res = await this.service.paginate(
          this.isActive,
          this.perPage,
          this.currentPage,
          this.search,
          this.filterQuery
        );

        this.totalPages = res.total_pages;
        this.data = res.data;
        this.data = this.data.map((item) => {
          if (item.type === 2) {
            let url = item.url;
            let parts = url.split('videos%2F');
            item.thumbnailUrl = parts[0] + 'videos%2Fthumbnails%2F' + parts[1];
          }

          return item;
        });

        this.loading = false;
      } catch (error) {
        console.log('error', error);
      }
    },

    async terminateRequests() {
      // By setting empty url & thumbnailUrl to the previous images will terminate the loading process.
      this.data = this.data.map((item) => {
        if (item.type === 2) {
          item.thumbnailUrl = '';
          item.url = '';
        } else {
          item.url = '';
        }
        return item;
      });
    },

    async loadMoreMedia(value, byPageInput = false) {
      if (value > 0 && value <= this.totalPages + 1) {
        this.loading = true;

        await this.terminateRequests();

        this.currentPage = value - 1;

        let res = await this.service.paginate(
          this.isActive,
          this.perPage,
          this.currentPage,
          this.search,
          this.filterQuery
        );
        let responseData = res.data;
        let data = responseData.map((item) => {
          if (item.type === 2) {
            let url = item.url;
            let parts = url.split('videos%2F');
            item.thumbnailUrl = parts[0] + 'videos%2Fthumbnails%2F' + parts[1];
          }
          return item;
        });
        this.loading = false;
        this.data = data;
      }
      if (byPageInput === false) {
        this.pageInput = '';
      }
    },

    async uploadFile(files) {
      this.loaderMessage = 'Uploading ...';
      this.loadingDialog = true;

      for (const file of files) {
        // Set Limit of 250mb
        if (file.size > 262144000) {
          // Alert
          this.toast(file.name + ' is too large');
        } else {
          let type = file.type;
          // Allow all types of images, videos and pdf
          let validExtensions = [
            'image/jpeg',
            'image/png',
            'image/gif',
            'image/jpg',
            'image/heic',
            'video/hevc',
            'video/mp4',
            'application/pdf'
          ];

          if (validExtensions.includes(type)) {
            let fileName = file.name;
            let fileType = type.split('/')[0];

            let newMedia = {
              title: fileName.split('.').slice(0, -1).join(''),
              category: '',
              sub_category: ''
            };

            let folderName;

            if (fileType === 'image') {
              folderName = 'images';
              newMedia.type = 1;
            } else if (fileType === 'video') {
              folderName = 'videos';
              newMedia.type = 2;
            } else if (fileType === 'application') {
              folderName = 'pdfs';
              newMedia.type = 3;
            }

            // Create custom file name
            fileName =
              fileName.split('.').slice(0, -1).join('') +
              '~' +
              new Date().getTime() +
              fileName.split('.').slice(-1)[0];

            // Upload File
            let reference = storage.ref(folderName + '/' + fileName);
            await reference.put(file);

            // Get download url from firebase storage and add to newMedia object
            newMedia.url = await storage
              .ref(folderName)
              .child(fileName)
              .getDownloadURL();

            if (fileType === 'video') {
              // Create Thumbnail
              const duration = await this.getVideoDuration(file);
              if (duration) {
                newMedia.total_duration = duration;
              }
              let thumbnail = await this.generateThumbnail(file);

              // Upload Thumbnail
              let thumbnailReference = storage.ref(
                folderName + '/thumbnails/' + fileName
              );
              await thumbnailReference.put(thumbnail);
            }

            try {
              await this.service.create(newMedia);
            } catch (error) {
              console.log('Error', error);
            }
          } else {
            // Alert
            this.toast(file.name + ' is not a valid file');
          }
        }
      }
      this.loadingDialog = false;
      await this.loadData();
    },

    async generateThumbnail(item) {
      const binaryData = [];
      binaryData.push(item);
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const video = document.createElement('video');
      video.setAttribute('src', URL.createObjectURL(new Blob(binaryData)));
      video.load();
      let thumbnail = await new Promise((resolve) => {
        video.onloadedmetadata = async () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          video.currentTime = video.duration / 2;
          await video.play();
          context.drawImage(video, 0, 0);
          video.pause();
          const blob = await new Promise((resolve) => {
            return canvas.toBlob(function (blob) {
              resolve(blob);
            }, 'image/webp');
          });
          resolve(blob);
        };
      });
      return thumbnail;
    },

    async deleteMedia() {
      let approved = confirm(
        'Are you sure you want to delete this media? \nIf you delete this media, it will be deleted from all the associated questions.'
      );
      if (approved) {
        try {
          let item = this.selectedEditMedia;
          this.loaderMessage = 'Deleting ...';
          this.loadingDialog = true;
          // Delete Data
          await this.service.delete(item.id);
          this.dialog = false;

          this.loadingDialog = false;
          await this.loadData();
        } catch (error) {
          console.log('Error: ', error);
        }
      }
    },

    async update() {
      if (this.selectedEditMedia.title.trim() === '') {
        alert('Title is required');
        return;
      }

      this.loaderMessage = 'Updating ...';
      this.loadingDialog = true;

      if (
        this.selectedEditMedia.pdfThumbnail &&
        this.selectedEditMedia.pdfThumbnail.size > 2000000
      ) {
        // Alert
        this.toast(this.selectedEditMedia.pdfThumbnail.name + ' is too large');
        this.loadingDialog = false;
        return;
      } else {
        if (this.selectedEditMedia.pdfThumbnail) {
          let pdfThumbnail = this.selectedEditMedia.pdfThumbnail;
          let validExtensions = ['image/jpeg', 'image/png', 'image/jpg'];

          if (validExtensions.includes(pdfThumbnail.type)) {
            let imageUrl;
            await new Promise(function (resolve) {
              let rawImage = new Image();

              rawImage.addEventListener('load', function () {
                resolve(rawImage);
              });

              rawImage.src = URL.createObjectURL(pdfThumbnail);
            })
              .then(async function (rawImage) {
                // Convert image to webp ObjectURL via a canvas blob
                return await new Promise(function (resolve) {
                  let canvas = document.createElement('canvas');
                  let ctx = canvas.getContext('2d');

                  canvas.width = rawImage.width;
                  canvas.height = rawImage.height;
                  ctx.drawImage(rawImage, 0, 0);

                  canvas.toBlob(function (blob) {
                    resolve(URL.createObjectURL(blob));
                  }, 'image/webp');
                });
              })
              .then(async function (imageURL) {
                // Load image for display on the page
                imageUrl = imageURL;
              });

            pdfThumbnail = await fetch(imageUrl).then((r) => r.blob());

            let url = this.selectedEditMedia.url
              .split('?alt')[0]
              .split('pdfs%2F')[1];

            let thumbnailReference = storage.ref('pdfs/thumbnails/' + url);
            await thumbnailReference.put(pdfThumbnail);
          } else {
            // Alert
            this.toast(pdfThumbnail.name + ' is not a valid file');
            this.selectedEditMedia.pdfThumbnail = null;
            this.loadingDialog = false;
            return;
          }
        }
      }

      try {
        // Update Data
        await this.service.update(this.selectedEditMedia);

        let index;
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].id === this.selectedEditMedia.id) {
            index = i;
            break;
          }
        }

        this.data[index] = this.selectedEditMedia;

        if (document.getElementById('img-uploaded-title')) {
          document.getElementById('img-uploaded-title').innerHTML = 'Thumbnail';
        }

        this.$toast.success('Thumbnail uploaded successfully!');

        this.selectedPdfThumbnailUrl = URL.createObjectURL(
          this.selectedEditMedia.pdfThumbnail
        );
        this.selectedEditMedia.pdfThumbnail = null;

        this.isUpdated = true;
      } catch (error) {
        console.log('error', error);
      }
      this.loadingDialog = false;
    },

    async loadCategories() {
      try {
        let res = this.isActive
          ? await this.categoryService.fetchAll()
          : await this.categoryService.fetchAllInactive();
        this.categories = res;
        this.filterAttr.category = res;
      } catch (error) {
        console.log('error', error);
      }
    }
  },

  async mounted() {
    let _this = this;
    await _this.loadData();

    this.loadCategories();

    // Infinite Scroll Listener
    // const myDiv = document.getElementById("mainDiv");
    // myDiv.addEventListener('scroll', () => {
    //   if (Math.ceil(myDiv.offsetHeight + myDiv.scrollTop) >= myDiv.scrollHeight) {
    //     _this.loadMoreMedia();
    //   }
    // })
    // Infinite Scroll Listener

    // ---------------------------------------------------------------------------------------------------------
    // ----------------------------------Drag & Drop Upload-----------------------------------------------------
    // ---------------------------------------------------------------------------------------------------------

    //selecting all required elements
    const dropArea = document.querySelector('.drag-area'),
      dragText = dropArea.querySelector('#header'),
      input = dropArea.querySelector('#file');

    // If user select files using button
    input.addEventListener('change', function (e) {
      _this.uploadFile(e.target.files);
    });

    //If user Drag File Over DropArea
    dropArea.addEventListener('dragover', (event) => {
      event.preventDefault(); //preventing from default behaviour
      dragText.textContent = 'Drop your files here';
    });

    //If user leave dragged File from DropArea
    dropArea.addEventListener('dragleave', () => {
      dragText.textContent = 'Drop files to upload';
    });

    //If user drop File on DropArea
    dropArea.addEventListener('drop', (e) => {
      e.preventDefault(); //preventing from default behaviour
      _this.uploadFile(e.dataTransfer.files);
    });

    // ---------------------------------------------------------------------------------------------------------
    // ----------------------------------Drag & Drop Upload-----------------------------------------------------
    // ---------------------------------------------------------------------------------------------------------
  }
};
</script>

<style scoped>
.new-media-container {
  border-radius: 5px !important;
  border: 2px dashed gray;
  margin: 1rem 0;
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.selectBtn {
  /*upload Btn*/
  border: 2px solid #1976d2;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 5px #1976d2;
}

.selectBtn:hover {
  background-color: #1976d2;
  color: white;
}

.mediaCol {
  background-color: #1976d2;
  overflow: hidden;
  max-height: fit-content;
  cursor: pointer;
}

.image {
  height: 170px;
  width: 100%;
}

.editImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.fileIcon {
  background-color: white;
}

.editFileIcon {
  height: 100%;
  background-color: white;
}

.mediaCol:hover {
  box-shadow: 0 0 5px #1976d2;
}

.fileName {
  white-space: nowrap !important;
  overflow: hidden !important;
  color: white !important;
  line-height: 25px !important;
  font-size: 12px !important;
}

.fileContainer {
  border-right: 2px dotted #1976d2;
  height: calc(100vh - 3rem);
}

.play-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 35%;
  width: 35%;
  object-fit: contain;
}

/*#mainDiv {*/
/*  height: 75vh;*/
/*  overflow: hidden;*/
/*  overflow-y: scroll;*/
/*}*/

#mainDiv {
  height: calc(100vh - 16rem);
  overflow-y: auto;
}
</style>
