<template>
  <SimpleForm
    :onSubmit="submit"
    @done="isEdit ? reload() : $router.push('/question-medias')"
  >
    <div class="d-flex align-center justify-space-between span-2 mt-3 mb-8">
      <v-btn
        v-if="isEdit"
        fab
        icon
        elevation="0"
        color="primary"
        @click="loadMoreMedia(false)"
        :disabled="!previous"
        small
        outlined
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <p class="text-h5 font-weight-bold ma-0 text-center flex-grow-1">
        {{ isEdit ? 'Update Question Media' : 'Add New Question Media' }}
      </p>
      <v-btn
        v-if="isEdit"
        fab
        icon
        elevation="0"
        color="primary"
        @click="loadMoreMedia(true)"
        small
        outlined
        :disabled="!next"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>

    <v-checkbox
      v-model="isActiveQuestionMedia"
      label="Is question media for active categories?"
      class="span-2"
      color="primary"
      @change="
        loadCategories();
        questionMedia.categories = [];
        questionMedia.subCategories = [];
      "
    />

    <v-combobox
      v-model="questionMedia.keywords"
      multiple
      label="Keywords"
      class="span-2"
      :delimiters="[',']"
      append-icon=""
      :rules="[requiredArray()]"
      chips
      deletable-chips
      outlined
      :search-input.sync="search"
      @update:search-input="getKeywords"
    />

    <v-select
      class="span-2"
      v-model="questionMedia.categories"
      label="Select Category"
      item-text="name"
      item-value="id"
      :items="categories"
      :loading="loadingCategories"
      multiple
      outlined
      @change="filterSubCategories"
    />

    <v-select
      class="span-2"
      v-model="questionMedia.subCategories"
      label="Select Sub Category"
      item-text="name"
      item-value="id"
      :items="subCategories"
      :loading="loadingSubCategories"
      multiple
      outlined
    />

    <v-divider class="span-2" style="margin-bottom: 20px" />

    <div class="mb-4 d-flex justify-space-between items-center span-2">
      <h3>Select Media</h3>
      <v-btn
        outlined
        elevation="0"
        color="primary"
        @click="openMediaPickerModal"
        >Select</v-btn
      >
    </div>
    <MediaPicker
      :selectedData="selectedData"
      @SetNewSelectedData="setSelectedData"
      :isActive="isActiveQuestionMedia"
    />

    <div class="span-2 carousel-display">
      <h4
        class="mt-3 text-center font-weight-light"
        v-if="
          selectedData.images.length <= 0 &&
          selectedData.videos.length <= 0 &&
          selectedData.pdfs.length <= 0
        "
      >
        No Media Selected
      </h4>

      <h2 class="mt-2 mb-3" v-if="selectedData.images.length === 1">Image</h2>
      <h2 class="mt-2 mb-3" v-if="selectedData.images.length > 1">Images</h2>
      <v-carousel
        :show-arrows="selectedData.images.length > 1"
        v-if="selectedData.images.length > 0"
        hide-delimiters
        style="width: 100%; height: fit-content"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item
          v-for="(item, i) in selectedData.images"
          :key="i"
          :src="item"
        >
        </v-carousel-item>
      </v-carousel>

      <h2 class="mt-2 mb-3" v-if="selectedData.videos.length === 1">Video</h2>
      <h2 class="mt-2 mb-3" v-if="selectedData.videos.length > 1">Videos</h2>
      <v-carousel
        :show-arrows="selectedData.videos.length > 1"
        v-if="selectedData.videos.length > 0"
        hide-delimiters
        style="width: 100%; height: fit-content"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item v-for="(item, i) in selectedData.videos" :key="i">
          <video
            style="width: 100%; height: 100%"
            width="auto"
            height="auto"
            :src="item"
            controls
          />
        </v-carousel-item>
      </v-carousel>

      <h2 class="mt-2 mb-3 float-start" v-if="selectedData.pdfs.length === 1">
        PDF
      </h2>
      <h2 class="mt-2 mb-3 float-start" v-if="selectedData.pdfs.length > 1">
        PDFs
      </h2>

      <a
        v-if="selectedData.pdfs.length > 0"
        :href="selectedData.pdfs[displayedPdfIndex]"
        class="text-center float-end text-decoration-none"
        target="_blank"
      >
        <v-btn small class="mt-3" elevation="0" color="black">
          <v-icon size="20" color="white"> mdi-arrow-expand-all </v-icon>
        </v-btn>
      </a>

      <v-carousel
        :show-arrows="selectedData.pdfs.length > 1"
        v-if="selectedData.pdfs.length > 0"
        @change="pdfChange"
        hide-delimiters
        style="width: 100%; height: fit-content"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item v-for="(item, i) in selectedData.pdfs" :key="i">
          <iframe style="width: 100%; height: 100%" :src="item" />
        </v-carousel-item>
      </v-carousel>
    </div>

    <v-divider
      v-if="isEdit && questionMedia.questions"
      class="span-2"
      style="margin-bottom: 20px"
    />

    <div
      v-if="
        isEdit && questionMedia.questions && questionMedia.questions.length > 0
      "
      class="span-2 pa-2 mb-2 rounded"
      style="gap: 30px; border: 1px solid black"
    >
      <p>Questions</p>
      <v-chip
        @click="openQuestion(question)"
        @click:close="excludeQuestion(question)"
        v-for="(question, i) in questionMedia.questions"
        :key="i"
        class="mb-2"
        close
      >
        {{ question.statement.substring(0, 50)
        }}{{ question.statement.length > 50 ? '...' : '' }}
      </v-chip>
    </div>

    <div
      v-if="isEdit && questionMedia.excluded && questionMedia.excluded.length"
      class="span-2 pa-2 mb-2 rounded"
      style="gap: 30px; border: 1px solid black"
    >
      <p>Excluded Questions</p>
      <v-chip
        @click="openQuestion(question)"
        v-for="(question, i) in questionMedia.excluded"
        :key="i"
        class="mb-2"
      >
        {{ question.statement.substring(0, 50)
        }}{{ question.statement.length > 50 ? '...' : '' }}
      </v-chip>
    </div>
    <!--    <v-combobox-->
    <!--        v-model="questionMedia.questions"-->
    <!--        multiple-->
    <!--        readonly-->
    <!--        v-if="isEdit && questionMedia.questions"-->
    <!--        label="Questions"-->
    <!--        class="span-2"-->
    <!--        append-icon=""-->
    <!--        :rules="[requiredArray()]"-->
    <!--        chips-->
    <!--        outlined-->
    <!--    />-->

    <loading-dialog v-model="loading" message="Loading Question Media" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import MediaPicker from '../../components/media/MediaPicker';
import { required, requiredArray } from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';
import { QuestionMediaService } from '@/services/question_media-service';
import { storage } from '@/plugins/firebase';
import { CategoryService } from '../../services/category-service';
import router from '@/router';

// async function deleteFiles(context, list, message) {
//   context.changeLoadingMessage(message + ' ...');
//   for (const item of list) {
//     context.changeLoadingMessage(message + ': ' + item);
//     const fileRef = storage.refFromURL(item);
//     fileRef.delete().then(() => {
//       window.console.log('file deleted')
//     }).catch((e) => {
//       window.console.log(e)
//     })
//   }
// }

async function uploadWithMessage(context, list, message, type) {
  context.changeLoadingMessage(message + ' ...');

  const newList = [];
  for (const item of list) {
    const fileName =
      item.filename + '~' + new Date().getTime() + '' + item.fileExtension;
    let reference = storage.ref(type + '/' + fileName);
    let task = reference.put(item.file);
    await task
      .then(async () => {
        if (type === 'videos') {
          const thumbnail = await generateThumbnail(item);
          let thumbRef = storage.ref(type + '/thumbnails/' + fileName);
          let thumbTask = thumbRef.put(thumbnail);
          await thumbTask
            .then(() => {
              window.console.log('thumbnail posted');
            })
            .catch((e) => window.console.log('uploading image error => ', e));
        }
        newList.push(await storage.ref(type).child(fileName).getDownloadURL());
      })
      .catch((e) => window.console.log('uploading image error => ', e));
    context.changeLoadingMessage(
      message + ': ' + item.filenameWithoutExtension
    );
  }

  return newList;
}

async function generateThumbnail(item) {
  const binaryData = [];
  binaryData.push(item.file);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const video = document.createElement('video');
  video.setAttribute('src', URL.createObjectURL(new Blob(binaryData)));
  video.load();
  let thumbnail = await new Promise((resolve) => {
    video.onloadedmetadata = async () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      video.currentTime = video.duration / 2;
      await video.play();
      context.drawImage(video, 0, 0);
      video.pause();
      const blob = await new Promise((resolve) => {
        return canvas.toBlob(function (blob) {
          resolve(blob);
        });
      });
      resolve(blob);
    };
  });
  return thumbnail;
}

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm, MediaPicker },

  data: () => ({
    isActiveQuestionMedia: true,
    displayedPdfIndex: null,
    selectedData: {
      images: [],
      videos: [],
      pdfs: []
    },
    videosServer: null,
    isEdit: false,
    search: null,
    questionMedia: {
      categories: [],
      subCategories: [],
      keywords: [],
      excluded: []
    },
    previous: null,
    next: null,
    loading: false,
    categories: [],
    subCategories: [],
    loadingCategories: false,
    loadingSubCategories: false,

    service: new QuestionMediaService(),
    categoriesService: new CategoryService(),
    pdfs: [],
    images: [],
    videos: [],
    pdfsToBeDeleted: [],
    imagesToBeDeleted: [],
    videosToBeDeleted: []
  }),

  computed: {
    uploadedVideos() {
      return (this.questionMedia?.videos || []).map((image) => {
        return {
          source: image,
          options: { type: 'local', metadata: { uploaded: true } }
        };
      });
    },
    uploadedPdfs() {
      return (this.questionMedia?.pdfs || []).map((image) => {
        return {
          source: image,
          options: { type: 'local', metadata: { uploaded: true } }
        };
      });
    },
    uploadedImages() {
      return (this.questionMedia?.images || []).map((image) => {
        return {
          source: image,
          options: { type: 'local', metadata: { uploaded: true } }
        };
      });
    }
  },

  watch: {
    '$route.params.id': {
      handler: function () {
        this.loadQuestion();
      },
      deep: true,
      immediate: true
    }
  },

  mounted() {
    this.loadQuestion();
    this.loadCategories();
  },

  methods: {
    required,
    requiredArray,

    filterSubCategories() {
      this.loadingSubCategories = true;
      this.subCategories = [];
      this.categories.forEach((category) => {
        if (this.questionMedia?.categories?.includes(category.id)) {
          this.subCategories.push(...category.subCategories);
        }
      });
      this.loadingSubCategories = false;
    },

    reload() {
      window.location.reload();
    },

    loadMoreMedia(towardNext) {
      if (towardNext) {
        router.push({ path: 'question-media', query: { id: this.next } });
      } else {
        router.push({ path: 'question-media', query: { id: this.previous } });
      }
    },

    pdfChange(index) {
      this.displayedPdfIndex = index;
    },

    getKeywords() {
      if (this.search && this.search.split(',').length > 1) {
        this.questionMedia.keywords = this.questionMedia.keywords.concat(
          this.search
            .split(',')
            .filter((term) => !this.questionMedia?.keywords?.includes(term))
        );
        this.search = '';
      }
    },

    async loadQuestion() {
      if (!this.$route.query.id) return;
      if (this.$route.query.inactive) {
        console.log('inactive');
        this.isActiveQuestionMedia = false;
      }
      this.isEdit = true;
      this.loading = true;
      let res = await this.service.fetchOne(this.$route.query.id);
      this.questionMedia = res.current;
      this.filterSubCategories();
      this.previous = res.previous;
      this.next = res.next;

      this.selectedData = {
        images: this.questionMedia.images,
        videos: this.questionMedia.videos,
        pdfs: this.questionMedia.pdfs
      };

      if (!this.questionMedia.excluded) this.questionMedia.excluded = [];
      this.loading = false;
    },

    async loadCategories() {
      this.loadingCategories = true;
      this.categories = this.isActiveQuestionMedia
        ? await this.categoriesService.fetchAll()
        : await this.categoriesService.fetchAllInactive();
      this.loadingCategories = false;

      this.filterSubCategories();
    },

    openMediaPickerModal() {
      document.getElementById('mediaPickerBtn').click();
    },

    setSelectedData(data) {
      this.selectedData = data;
      this.questionMedia.images = data.images;
      this.questionMedia.videos = data.videos;
      this.questionMedia.pdfs = data.pdfs;
    },

    async submit(context) {
      // if (this.isEdit) {
      //   await deleteFiles(context, this.imagesToBeDeleted, 'Deleting Images');
      //   await deleteFiles(context, this.pdfsToBeDeleted, 'Deleting Pdfs');
      //   await deleteFiles(context, this.videosToBeDeleted, 'Deleting Videos');
      // }
      if (this.questionMedia.images && this.questionMedia.images.length > 0) {
        this.questionMedia.images = [
          ...this.questionMedia.images,
          ...(await uploadWithMessage(
            context,
            this.images,
            'Uploading Images',
            'images'
          ))
        ];
      } else {
        this.questionMedia.images = [
          ...(await uploadWithMessage(
            context,
            this.images,
            'Uploading Images',
            'images'
          ))
        ];
      }
      if (this.questionMedia.pdfs && this.questionMedia.pdfs.length > 0) {
        this.questionMedia.pdfs = [
          ...this.questionMedia.pdfs,
          ...(await uploadWithMessage(
            context,
            this.pdfs,
            'Uploading Pdfs',
            'pdfs'
          ))
        ];
      } else {
        this.questionMedia.pdfs = [
          ...(await uploadWithMessage(
            context,
            this.pdfs,
            'Uploading Pdfs',
            'pdfs'
          ))
        ];
      }
      if (this.questionMedia.videos && this.questionMedia.videos.length > 0) {
        this.questionMedia.videos = [
          ...this.questionMedia.videos,
          ...(await uploadWithMessage(
            context,
            this.videos,
            'Uploading Videos',
            'videos'
          ))
        ];
      } else {
        this.questionMedia.videos = [
          ...(await uploadWithMessage(
            context,
            this.videos,
            'Uploading Videos',
            'videos'
          ))
        ];
      }
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Question Media');
        try {
          // this.questionMedia.deletedImages = this.imagesToBeDeleted
          // this.questionMedia.deletedVideos = this.videosToBeDeleted
          // this.questionMedia.deletedPDFS = this.pdfsToBeDeleted

          // console.log('this.questionMedia: ', this.questionMedia);

          await this.service.update(this.questionMedia);
          return true;
        } catch (e) {
          // await deleteFiles(context, this.questionMedia.images, 'Deleting Images');
          // await deleteFiles(context, this.questionMedia.pdfs, 'Deleting Pdfs');
          // await deleteFiles(context, this.questionMedia.videos, 'Deleting Videos');
          return false;
        }
      } else {
        try {
          context.changeLoadingMessage('Creating New Question Media');
          await this.service.create(this.questionMedia);
          return true;
        } catch (e) {
          // await deleteFiles(context, this.questionMedia.images, 'Deleting Images');
          // await deleteFiles(context, this.questionMedia.pdfs, 'Deleting Pdfs');
          // await deleteFiles(context, this.questionMedia.videos, 'Deleting Videos');
          return false;
        }
      }
    },

    pdfRemoved(error, file) {
      if (!error && file.getMetadata().uploaded) {
        this.pdfsToBeDeleted.push(file.source);
        this.questionMedia.pdfs.splice(
          this.questionMedia.pdfs.indexOf(file.source),
          1
        );
      }
      this.pdfs.splice(this.pdfs.indexOf(file));
    },

    videoRemoved(error, file) {
      if (!error && file.getMetadata().uploaded) {
        this.videosToBeDeleted.push(file.source);
        this.questionMedia.videos.splice(
          this.questionMedia.videos.indexOf(file.source),
          1
        );
      }
      this.videos.splice(this.videos.indexOf(file));
    },

    async confirmFileRemoval() {
      return confirm(
        'Warning! This file will be removed from all question. Do you want to proceed?'
      );
    },

    imageRemoved(error, file) {
      if (!error && file.getMetadata().uploaded) {
        this.imagesToBeDeleted.push(file.source);
        this.questionMedia.images.splice(
          this.questionMedia.images.indexOf(file.source),
          1
        );
      }
      this.images.splice(this.images.indexOf(file));
    },

    clearPond() {
      this.$router.go();
    },

    pdfAdded(error, file) {
      if (!error && !file.getMetadata().uploaded) this.pdfs.push(file);
    },

    videoAdded(error, file) {
      if (!error && !file.getMetadata().uploaded) this.videos.push(file);
    },

    imageAdded(error, file) {
      if (!error && !file.getMetadata().uploaded) this.images.push(file);
    },

    async loadFile(source, load) {
      await fetch(source.image_url || source.url || source)
        .then((res) => res.blob())
        .then(load);
    },

    openQuestion(question) {
      if (question && question._id) window.open('/question?id=' + question._id);
    },

    excludeQuestion(question) {
      const index = this.questionMedia.questions.findIndex(
        (o) => o._id === question._id
      );
      if (index !== -1) {
        this.questionMedia.questions.splice(index, 1);
        this.questionMedia.excluded.push(question);
        this.questionMedia.questions = [...this.questionMedia.questions];
        this.questionMedia.excluded = [...this.questionMedia.excluded];
      }
    }
  }
};
</script>

<style scoped>
.modified-message >>> div {
  text-align: center;
}

p {
  font-weight: bold;
  text-align: left;
}

.image {
  width: 240px;
  height: 240px;
  object-fit: cover;
}

.fileIcon {
  border: 1px solid #4396e4;
}
</style>
