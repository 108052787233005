import axios from "axios";

export class AdvertisementsService {
    async fetchAll() {
        return (await axios.get('/advertisements')).data;
    }

    async fetchOne(id) {
        return (await axios.get(`/advertisements/${id}`)).data;
    }

    async create(data) {
        return (await axios.post('/advertisements', data)).data;
    }

    async update(data) {
        return (await axios.patch('/advertisements', data)).data;
    }

    async delete(id) {
        return (await axios.delete(`/advertisements/${id}`)).data;
    }
}