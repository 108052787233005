import axios from 'axios';

export class RoadmapModeService {
  async fetchAll(filterQuery, populate) {
    return (
      await axios.get('/roadmap-game-mode', {
        params: {
          ...(populate && { populate: true }),
          ...filterQuery,
          sort: true
        }
      })
    ).data;
  }

  async fetchAllInactive(filterQuery, populate) {
    return (
      await axios.get('/roadmap-game-mode/inactive', {
        params: {
          ...(populate && { populate: true }),
          ...filterQuery,
          sort: true
        }
      })
    ).data;
  }

  async fetchOne(id, populate) {
    return (
      await axios.get('/roadmap-game-mode/' + id, {
        params: {
          ...(populate && { populate: true })
        }
      })
    ).data;
  }

  async create(chapter) {
    return (await axios.post('/roadmap-game-mode', chapter)).data;
  }

  async update(chapter) {
    return (await axios.patch('/roadmap-game-mode', chapter)).data;
  }

  async delete(chapter) {
    return (
      await axios.delete(
        '/roadmap-game-mode/' + chapter._id + '/' + chapter.category
      )
    ).data;
  }

  // Flashcards
  async fetchAllFlashcard(filterQuery, populate) {
    return (
      await axios.get('/roadmap-game-mode/flashcards', {
        params: {
          ...(populate && { populate: true }),
          ...filterQuery,
          sort: true
        }
      })
    ).data;
  }

  async fetchOneFlashcard(id, populate) {
    return (
      await axios.get('/roadmap-game-mode/flashcard/' + id, {
        params: {
          ...(populate && { populate: true })
        }
      })
    ).data;
  }

  async createFlashcard(flashcard) {
    return (await axios.post('/roadmap-game-mode/flashcard', flashcard)).data;
  }

  async createMultipleFlashcard(flashcard) {
    return (
      await axios.post('/roadmap-game-mode/flashcard', flashcard, {
        params: {
          multiple: true
        }
      })
    ).data;
  }

  async updateFlashcard(flashcard) {
    return (await axios.patch('/roadmap-game-mode/flashcard', flashcard)).data;
  }

  async deleteFlashcard(flashcard) {
    return (await axios.delete('roadmap-game-mode/flashcard/' + flashcard._id))
      .data;
  }

  async fetchAllUserSuggestions(filterQuery, populate) {
    return (
      await axios.get('/roadmap-game-mode/chapters-suggestions', {
        params: {
          ...(populate && { populate: true }),
          ...filterQuery,
          sort: true
        }
      })
    ).data;
  }

  async updateUserSuggestion(suggestion) {
    return (
      await axios.patch('/roadmap-game-mode/chapters-suggestions', suggestion)
    ).data;
  }

  // Stats
  async fetchStats(page, limit, filterByUserId = '') {
    return (
      await axios.get(
        `/roadmap-game-mode/new/roadmap-stats/admin?page=${page}&limit=${limit}&userId=${filterByUserId}`
      )
    ).data;
  }
}
