<template>
  <SimpleForm
    :onSubmit="submit"
    @done="$router.back()"
    :btnText="isEdit ? 'Update' : 'Submit'"
  >
    <p class="span-2 form__title">
      {{ isEdit ? 'Update Daily Bonus' : 'Create New Daily Bonus' }}
    </p>

    <v-select
      :items="days"
      v-model="selectedDay"
      :disabled="isEdit"
      @change="handleSelectChange"
      label="Day"
      :rules="[required('A day name must be selected')]"
      outlined
      class="span-2"
    ></v-select>
    <v-text-field
      v-model.number="prizeInfo.prize"
      type="number"
      label="Prize"
      dense
      :rules="[required('Prize must be provided')]"
      outlined
      class="span-2"
    />
    <loading-dialog v-model="loading" message="Fetching User Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { DailyBonusService } from '@/services/daily-bonus';
import LoadingDialog from '../../components/LoadingDialog';
import { required, email } from '@/utils/validators';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    showPassword: false,
    days: [
      { text: 'Monday', disabled: false },
      { text: 'Tuesday', disabled: false },
      { text: 'Wednesday', disabled: false },
      { text: 'Thursday', disabled: false },
      { text: 'Friday', disabled: false },
      { text: 'Saturday', disabled: false },
      { text: 'Sunday', disabled: false }
    ],
    service: new DailyBonusService(),
    selectedDay: null,
    confirmPassword: '',
    prizeInfo: {
      day: null,
      prize: null
    }
  }),

  mounted() {
    this.loadUser();
  },

  methods: {
    required,
    email,
    async loadUser() {
      if (!this.$route.query.id) {
        this.loading = true;
        this.service
          .fetchAll()
          .then((response) => {
            const apiDays = response.map((item) => item.day);
            this.days.forEach((day, index) => {
              if (apiDays.includes(index + 1)) {
                day.disabled = true;
              }
            });
          })
          .catch((error) => {
            console.error(error);
          });
        this.loading = false;
      } else {
        this.isEdit = true;
        this.loading = true;
        this.service
          .fetchOne(this.$route.query.id)
          .then((response) => {
            this.selectedDay = this.days[response.day - 1];
            this.prizeInfo.day = response.day;
            this.prizeInfo.prize = response.prize;
            this.prizeInfo.createdAt = response.createdAt;
            this.prizeInfo.id = response.id;
          })
          .catch((error) => {
            console.error(error);
          });
        this.loading = false;
      }
    },

    handleSelectChange() {
      const selectedIndex = this.days.findIndex(
        (day) => day.text === this.selectedDay
      );
      this.prizeInfo.day = selectedIndex + 1;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Coins');
        try {
          await this.service.update(this.prizeInfo);
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while Updating Coins',
            description: e.response.data.error
          });

          return false;
        }
      } else {
        context.changeLoadingMessage('Creating A New Coins');
        try {
          await this.service.create(this.prizeInfo);
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating Coins',
            description: e.response.data.error
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
