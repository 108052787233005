<template>
  <QuestionForm />
</template>

<script>
import QuestionForm from '@/components/questions/QuestionForm.vue';

export default {
  name: 'Form',
  components: { QuestionForm }
};
</script>

<style scoped></style>
