<template>
  <v-card
      :class='["mr-3 pa-3 slide", data.side === "back" && "mr-10"]'
      color="white"
      elevation="0"
      min-width="300px"
      width="300px"
      min-height="100%"
  >
    <div class="body">
      <div class="content">
        <div class="title">
          {{ data.side === 'front' ? 'Front' : 'Back' }} side
        </div>
        <div class="content-row" v-for="(item, index) in data.content" :key="index">
          <v-textarea
              v-model="item.value"
              v-if="item.type === 'text'"
              label="Enter text here"
              outlined
              persistent-hint
              hide-details
              class="text"
              no-resize
              height="130px"
          />

          <img
              v-else-if="item.type === 'image'"
              :src="item.value"
              class="image"
          />

          <video
              v-else-if="item.type === 'video'"
              :src="item.value"
              class="video"
              controls
          ></video>
        </div>
      </div>
    </div>
    <div class="footer">
      <v-text-field placeholder="Name" dense hide-details outlined v-if="data.side === 'front'" type="text"
                    v-model="data.name"></v-text-field>
      <v-autocomplete
          v-else
          v-model="data.category"
          label="Select Category"
          item-text="name"
          item-value="id"
          :items="categories"
          :loading="loadingCategories"
          :disabled="isEdit"
          class="span-2"
          :rules="[required('Select a category')]"
          outlined
          dense
          hide-details
      />
      <v-menu
          transition="slide-y-transition"
          bottom offset-y offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon fab x-small outlined elevation="0" plain v-bind="attrs"
                 v-on="on" style="background-color: white">
            <v-icon size="26">
              mdi-dots-horizontal
            </v-icon>
          </v-btn>
        </template>
        <v-list class="flex flex-col pa-2">
          <v-btn width="100%" class="my-1" :color="item.type" text v-for="(item, i) in items"
                 :key="i" @click="menuClick(item.actionType)">
            {{ item.title }}
          </v-btn>
        </v-list>
      </v-menu>
    </div>
  </v-card>
</template>

<script>
import {required} from "@/utils/validators";

export default {
  name: "Card",

  props: {
    data: {
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    loadingCategories: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    isEdit: false,
    items: [
      {title: 'Add text field', type: 'primary', actionType: 'addTextField'},
      {title: 'Add image', type: 'primary', actionType: 'addImage'},
      {title: 'Add Video', type: 'primary', actionType: 'addVideo'},
      {title: 'Move left', type: 'primary', actionType: 'moveLeft'},
      {title: 'Remove Last section', type: 'red', actionType: 'removeLastSection'},
      {title: 'Delete Flashcard', type: 'red', actionType: 'deleteSlide'},
    ],
  }),

  mounted() {
    if (this.$route.query.id) {
      this.isEdit = true;
    }
    if (!this.isEdit) {
      const item = {title: 'Duplicate slide', type: 'primary', actionType: 'duplicateSlide'}
      this.items.splice(4, 0, item)
    }
  },

  methods: {
    required,

    menuClick(actionType) {
      switch (actionType) {
        case 'addTextField':
          this.$emit('addTextField');
          break;
        case 'addImage':
          this.$emit('addImage');
          break;
        case 'addVideo':
          this.$emit('addVideo');
          break;
        case 'duplicateSlide':
          this.$emit('duplicateSlide');
          break;
        case 'moveLeft':
          this.$emit('moveLeft');
          break;
        case 'removeLastSection':
          this.$emit('removeLastSection');
          break;
        case 'deleteSlide':
          this.$emit('deleteSlide');
          break;
      }
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep.slide {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:hover {
    box-shadow: 0 0 6px 1px #1976d2 !important;
    border: 1px solid #1976d2 !important;
  }

  .body {
    flex-grow: 1;

    .content {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .title {
        text-align: center;
        margin-bottom: 10px;
      }

      .text {
        resize: none;
        min-height: 50px;
        max-height: 300px;
      }

      .image {
        object-fit: cover;
        width: 100%;
        height: 200px;
      }

      .video {
        width: 100%;
        height: 200px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 42px;
    gap: 5px;
    margin-top: 10px;
  }
}

</style>