<template>
  <SimpleForm :onSubmit="updateSpinTheWheelCoins" btnText="Update">
    <p class="span-2 form__title">Manage Curve Coins</p>

    <v-text-field
      v-model.number="coinsData.spin_the_wheel_pricing_curve_coins"
      type="number"
      label="Coins Price"
      dense
      :rules="[
        (v) =>
          (v !== null && v !== undefined && v !== '') || 'Value is required',
        (v) => v >= 0 || 'Value must be positive'
      ]"
      outlined
      class="span-2"
      :disabled="isCoinsFree"
    />

    <v-switch
      v-model="isCoinsFree"
      label="Free"
      color="primary"
      class="span-2 ml-auto mt-n2"
      @change="
        isCoinsFree ? (coinsData.spin_the_wheel_pricing_curve_coins = 0) : null
      "
    />

    <loading-dialog v-model="loading" message="Please wait..." />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import { required } from '@/utils/validators';

export default {
  name: 'SpinTheWheelCoins',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    loading: false,
    isCoinsFree: false,
    coinsData: {}
  }),

  mounted() {
    this.loadCoinsData();
  },

  methods: {
    required,

    async loadCoinsData() {
      this.coinsData = (await this.$axios.get('/app-status')).data;
    },

    async updateSpinTheWheelCoins() {
      this.loading = true;

      try {
        await this.$axios.patch('/app-status', {
          id: this.coinsData.id,
          spin_the_wheel_pricing_curve_coins:
            this.coinsData.spin_the_wheel_pricing_curve_coins
        });

        this.$toast.success('Curve Coins updated successfully');
      } catch (error) {
        console.error(error);

        this.$toast.error('Failed to update Curve Coins');
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
