<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <div class="span-2 d-flex align-center justify-space-between">
      <p class="span-2 form__title">
        {{ isEdit ? 'Update Popup Notification' : 'Create Popup Notification' }}
      </p>

      <v-btn
        @click="$router.back()"
        dark
        color="primary"
        elevation="0"
        class="mb-8 d-none d-md-block"
      >
        Back
      </v-btn>
    </div>

    <v-switch
      v-model="popupNotification.active"
      :label="popupNotification.active ? 'Active' : 'Inactive'"
      class="span-2 ml-auto"
    />

    <v-row class="span-2 px-4 mb-2 d-flex justify-space-between">
      <v-checkbox v-model="popupNotification.ios" label="iOS" />
      <v-checkbox v-model="popupNotification.android" label="Android" />
      <v-checkbox v-model="popupNotification.web" label="Web" />
    </v-row>

    <div class="span-2 mb-8">
      <v-card
        v-for="(content, index) in popupNotification.content"
        :key="index"
        outlined
        class="mb-4"
      >
        <v-card-title>
          <p class="span-2">Content {{ index + 1 }}</p>
          <v-btn
            v-if="index !== 0"
            icon
            @click="popupNotification.content.splice(index, 1)"
            class="ml-auto"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="content.title"
            label="Title"
            dense
            outlined
            persistent-hint
            :rules="[required()]"
            class="span-2"
          />
          <v-textarea
            v-model="content.description"
            label="Description"
            dense
            outlined
            persistent-hint
            :rules="[required()]"
            class="span-2"
          />

          <v-img
            v-if="content.image"
            :src="content.image"
            max-height="200px"
            contain
            class="span-2 mb-6 rounded-lg"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
                style="background-color: #f5f5f5"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-file-input
            v-model="content.imageFile"
            label="Upload Image"
            outlined
            accept="image/*"
            @change="previewImage(content)"
            @click:clear="content.image = ''"
          >
          </v-file-input>
        </v-card-text>
      </v-card>
      <v-btn
        elevation="0"
        color="green"
        dark
        small
        class="d-flex ml-auto"
        @click="
          popupNotification.content.push({
            title: '',
            description: '',
            image: ''
          })
        "
      >
        <v-icon small class="mr-1 ml-n2">mdi-plus</v-icon>
        Add Content
      </v-btn>
    </div>

    <v-radio-group v-model="popupNotification.oneTime" row class="span-2">
      <v-radio label="One Time" :value="true"></v-radio>
      <v-radio label="Recurring" :value="false"></v-radio>
    </v-radio-group>

    <v-select
      v-model="popupNotification.type"
      :items="popupNotificationTypes"
      label="Type"
      outlined
      persistent-hint
      class="span-2"
    />

    <div class="span-2 mb-5">
      <v-chip
        v-for="(user, index) in popupNotification.specificUserList"
        :key="index"
        close
        @click:close="popupNotification.specificUserList.splice(index, 1)"
      >
        {{ user }}
      </v-chip>
    </div>

    <v-alert
      v-if="getUserError"
      type="error"
      outlined
      dense
      dismissible
      class="span-2"
    >
      No user found with this email
    </v-alert>

    <v-text-field
      v-if="popupNotification.type === 1"
      label="Search User by Email"
      append-icon="mdi-magnify"
      @click:append="getUser($event.target.value)"
      @keyup.enter="getUser($event.target.value)"
      @input="getUserError = false"
      outlined
      persistent-hint
      class="span-2"
      :loading="getUserLoading"
    />

    <v-text-field
      v-model="popupNotification.schedule.startDate"
      label="Start Date"
      type="date"
      outlined
      persistent-hint
      :rules="[required()]"
      :min="new Date().toISOString().substr(0, 10)"
      class="span-1"
    />

    <v-text-field
      v-model="popupNotification.schedule.endDate"
      label="End Date"
      type="date"
      outlined
      persistent-hint
      :rules="[
        required(),
        (v) =>
          v > popupNotification.schedule.startDate ||
          'End date must be greater than start date'
      ]"
      :min="
        popupNotification.schedule.startDate ||
        new Date().toISOString().substr(0, 10)
      "
      :disabled="!popupNotification.schedule.startDate"
      class="span-1"
    />

    <v-text-field
      v-model="popupNotification.button"
      label="Button Text"
      dense
      outlined
      persistent-hint
      class="span-2"
      :rules="[required()]"
    />

    <v-text-field
      v-model="popupNotification.link"
      label="Redirect Link"
      dense
      outlined
      persistent-hint
      class="span-2"
      :rules="[urlValidator()]"
    />

    <loading-dialog
      v-model="loading"
      message="Fetching Popup Notification Data"
    />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import { PopupNotificationsService } from '@/services/popup-notifications-service';
import { required, urlValidator } from '@/utils/validators';
import { storage, users } from '@/plugins/firebase';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    service: new PopupNotificationsService(),
    getUserLoading: false,
    getUserError: false,

    oldContent: [],
    popupNotificationTypes: [
      { text: 'All Users', value: 0 },
      { text: 'Specific Users', value: 1 },
      { text: 'Premium Users', value: 2 },
      { text: 'Non-Premium Users', value: 3 }
    ],
    popupNotification: {
      content: [
        {
          title: '',
          description: '',
          image: ''
        }
      ],
      schedule: {
        startDate: null,
        endDate: null
      },
      oneTime: true,
      type: 'popupNotificationAll',
      specificUserList: [],
      button: '',
      link: '',
      active: true,
      ios: true,
      android: true,
      web: true
    }
  }),

  mounted() {
    this.loadPopupNotification();
  },

  methods: {
    required,
    urlValidator,
    formatDate(date) {
      return new Date(date).toISOString().substr(0, 10);
    },
    async getUser(email) {
      if (
        !email ||
        this.popupNotification?.specificUserList?.includes(email.trim())
      )
        return;
      this.getUserError = false;
      this.getUserLoading = true;
      try {
        const user = await users
          .where('email', '==', email.trim())
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              this.getUserError = true;
              return;
            }
            return querySnapshot.docs[0].data();
          });
        this.popupNotification.specificUserList.push(user.email);
      } catch (e) {
        this.userError = true;
      }

      this.getUserLoading = false;
    },
    previewImage(content) {
      if (content.imageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          content.image = e.target.result;
        };
        reader.readAsDataURL(content.imageFile);
      }
    },
    async loadPopupNotification() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.popupNotification = await this.service.fetchOne(
        this.$route.query.id
      );
      this.oldContent = JSON.parse(
        JSON.stringify(this.popupNotification.content)
      );
      this.popupNotification.schedule.startDate = this.formatDate(
        this.popupNotification.schedule.startDate
      );
      this.popupNotification.schedule.endDate = this.formatDate(
        this.popupNotification.schedule.endDate
      );
      if (this.popupNotification.specificUserList === null) {
        this.popupNotification.specificUserList = [];
      }
      this.loading = false;
    },
    preCheck(context) {
      return context.validate();
    },
    async submit(context) {
      if (this.preCheck(context)) {
        if (this.isEdit) {
          context.changeLoadingMessage('Updating Popup Notification');
          try {
            for (let i = 0; i < this.popupNotification.content.length; i++) {
              if (this.popupNotification.content[i].imageFile) {
                const imageRef = storage.ref(
                  `popup-notifications/${Date.now()}-${
                    this.popupNotification.content[i].imageFile.name
                  }`
                );
                await imageRef.put(this.popupNotification.content[i].imageFile);

                // delete old image
                const oldImage = this.oldContent[i]?.image;
                if (oldImage) {
                  await storage.refFromURL(oldImage).delete();
                }

                this.popupNotification.content[i].image =
                  await imageRef.getDownloadURL();
              }
            }

            this.popupNotification.schedule.startDate = new Date(
              this.popupNotification.schedule.startDate
            );
            this.popupNotification.schedule.endDate = new Date(
              this.popupNotification.schedule.endDate
            );

            await this.service.update(this.popupNotification);
            return true;
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating Popup Notification',
              description: e.toString()
            });
            return false;
          }
        } else {
          context.changeLoadingMessage('Creating A New Popup Notification');
          try {
            for (let i = 0; i < this.popupNotification.content.length; i++) {
              if (this.popupNotification.content[i].imageFile) {
                const storageRef = storage.ref(
                  `popup-notifications/${Date.now()}-${
                    this.popupNotification.content[i].imageFile.name
                  }`
                );
                await storageRef.put(
                  this.popupNotification.content[i].imageFile
                );
                this.popupNotification.content[i].image =
                  await storageRef.getDownloadURL();
              }
            }

            this.popupNotification.schedule.startDate = new Date(
              this.popupNotification.schedule.startDate
            );
            this.popupNotification.schedule.endDate = new Date(
              this.popupNotification.schedule.endDate
            );

            await this.service.create(this.popupNotification);
            return true;
          } catch (e) {
            context.reportError({
              title: 'Error occurred while creating Popup Notification',
              description: e.toString()
            });
            return false;
          }
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
