import axios from 'axios';

export class CoinsLeaderboardService {
  async fetchAll(page, limit, sort) {
    return (
      await axios.get(
        `/user-stats/curve-coins/leaderboard?page=${page}&limit=${limit}&sortType=${sort}`
      )
    ).data;
  }

  async fetchCoinsAverage() {
    return (await axios.get('/user-stats/curve-coins/average')).data;
  }
}
