<template>
  <div>
    <v-card class="pa-4">
      <div
        class="d-flex flex-col mb-5"
        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 50%' : ''"
      >
        <h2 class="font-weight-medium d-inline mb-5">Settings</h2>

        <h4 class="font-weight-medium">Developer Mode</h4>
        <v-switch
          class="ma-0 pa-3"
          :hide-details="!inReview"
          v-model="developMode"
          label="Development Mode"
          :disabled="
            !getUser().scopes.includes('settings:edit') ||
            inReview ||
            (developModeData &&
              developModeData.is_restricted_screen_shot &&
              !restriction)
          "
          hint="Disabled when app in review."
          :persistent-hint="inReview"
          @change="toggleDevelopMode"
        ></v-switch>
        <div v-if="user.username === 'arishsultan104@gmail.com'">
          <v-divider class="my-3" />
          <h4 class="font-weight-medium">Review Mode</h4>
          <v-switch
            class="ma-0 pa-3"
            hide-details
            v-model="inReview"
            label="In Review"
            @change="toggleInReview"
            :disabled="
              !getUser().scopes.includes('settings:edit') ||
              (developModeData &&
                developModeData.is_restricted_screen_shot &&
                !restriction)
            "
          ></v-switch>
          <v-divider class="my-3" />
          <h4 class="font-weight-medium">App Version Control</h4>
          <v-form>
            <div class="pa-2 d-flex flex-column" style="gap: 10px">
              <v-text-field
                v-model="appStoreVersion"
                outlined
                label="App Store Version"
                dense
                hide-details
                :rules="[required()]"
              />
              <v-text-field
                v-model="playStoreVersion"
                outlined
                label="Play Store Version"
                dense
                hide-details
                :rules="[required()]"
              />
              <v-btn
                color="primary"
                elevation="0"
                style="width: fit-content"
                class="align-self-end"
                v-if="getUser().scopes.includes('settings:edit')"
                @click="submitVersion"
                :disabled="
                  developModeData &&
                  developModeData.is_restricted_screen_shot &&
                  !restriction
                "
                >Save
              </v-btn>
            </div>
          </v-form>
        </div>
        <v-divider class="my-3" />
        <div>
          <h4 class="font-weight-medium">Screenshot restriction</h4>

          <v-switch
            class="ma-0 pa-3"
            :readonly="
              !getUser().scopes.includes('settings:edit') ||
              (developModeData && !developModeData.is_restricted_screen_shot)
            "
            v-model="restriction"
            label="Restrict Screenshots"
            hide-details
          ></v-switch>

          <v-form ref="restrictionForm" v-if="!restriction">
            <div class="pa-2 d-flex flex-column" style="gap: 10px">
              <v-text-field
                v-model="unRestrictionUntil"
                outlined
                label="Allow screenshots till"
                dense
                hide-details
                :rules="[required()]"
                type="datetime-local"
                :readonly="
                  developModeData && !developModeData.is_restricted_screen_shot
                "
              />
              <v-btn
                color="primary"
                elevation="0"
                style="width: fit-content"
                class="align-self-end"
                @click="submitRestriction"
                :disabled="
                  developModeData && !developModeData.is_restricted_screen_shot
                "
                >Save
              </v-btn>
            </div>
          </v-form>
        </div>
        <v-divider class="my-3" />
        <div>
          <h4 class="font-weight-medium">Three Day Free Trial</h4>

          <v-switch
            class="ma-0 pa-3"
            v-model="enable_three_day_free_trial"
            label="Enable Three Day Free Trial"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
          />

          <v-form ref="threeDayFreeTrial">
            <div class="pa-2 d-flex flex-column" style="gap: 10px">
              <v-text-field
                v-model="three_day_free_trial_text"
                outlined
                label="Three Day Free Trial Text"
                dense
                hide-details
                :rules="[required()]"
              />
              <v-btn
                color="primary"
                elevation="0"
                style="width: fit-content"
                class="align-self-end"
                @click="submitThreeDayFreeTrial"
                >Save
              </v-btn>
            </div>
          </v-form>
        </div>

        <v-divider class="my-3" />

        <div>
          <h4 class="font-weight-medium">Three Day Free Trial (Web App)</h4>

          <v-switch
            class="ma-0 pa-3"
            v-model="web_app_enable_three_day_free_trial"
            label="Enable Web App Three Day Free Trial"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
          />

          <v-form ref="webAppThreeDayFreeTrial">
            <div class="pa-2 d-flex flex-column" style="gap: 10px">
              <v-text-field
                v-model="web_app_three_day_free_trial_text"
                outlined
                label="Web App Three Day Free Trial Text"
                dense
                hide-details
                :rules="[required()]"
              />
              <v-btn
                color="primary"
                elevation="0"
                style="width: fit-content"
                class="align-self-end"
                @click="submitWebAppThreeDayFreeTrial"
                >Save
              </v-btn>
            </div>
          </v-form>
        </div>

        <v-divider class="my-3" />

        <div>
          <h4 class="font-weight-medium">Failsafe: Development Mode</h4>

          <v-switch
            class="ma-0 pa-3"
            v-model="adaptive_qbank"
            label="Adaptive Qbank"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
            @change="saveSettings"
          />

          <v-switch
            class="ma-0 pa-3"
            v-model="boolean_blitz"
            label="Boolean Blitz"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
            @change="saveSettings"
          />

          <v-switch
            class="ma-0 pa-3"
            v-model="endless_mode"
            label="Endless Mode"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
            @change="saveSettings"
          />

          <v-switch
            class="ma-0 pa-3"
            v-model="timed_mode"
            label="Timed Mode"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
            @change="saveSettings"
          />

          <v-switch
            class="ma-0 pa-3"
            v-model="multiplayer"
            label="Multiplayer"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
            @change="saveSettings"
          />

          <v-switch
            class="ma-0 pa-3"
            v-model="quiz_game_mode"
            label="Quiz Game Mode"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
            @change="saveSettings"
          />

          <v-switch
            class="ma-0 pa-3"
            v-model="review_mode"
            label="Review Mode"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
            @change="saveSettings"
          />

          <v-switch
            class="ma-0 pa-3"
            v-model="question_of_the_day"
            label="Question of the Day"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
            @change="saveSettings"
          />

          <v-switch
            class="ma-0 pa-3"
            v-model="passage_mode"
            label="Passage Mode"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
            @change="saveSettings"
          />

          <v-switch
            class="ma-0 pa-3"
            v-model="flashcards"
            label="Flashcards"
            :disabled="!getUser().scopes.includes('settings:edit')"
            hide-details
            @change="saveSettings"
          />
        </div>
        <v-divider class="my-3" />
        <!-- <div>
          <h4 class="font-weight-medium">Mobile App Banner</h4>
          <v-form ref="mobilePageBannerForm">
            <div class="pa-2 d-flex flex-column" style="gap: 10px">
              <v-text-field v-model="mobileSalesBanner.name" outlined label="Name" dense hide-details
                :rules="[required()]" />
              <v-text-field v-model="mobileSalesBanner.description" outlined label="Description" dense hide-details
                :rules="[required()]" />
              <v-text-field v-model="mobileSalesBanner.percentage_off" outlined label="Percentage Off" dense hide-details
                :rules="[required()]" />

              <DateTimePicker v-model="mobileSalesBanner.sale_period" timeFormat="hh:mm:ss a" label="Sale Period"
                :rules="[required()]">
              </DateTimePicker>

              <v-checkbox :label="(mobileSalesBanner.is_active) ? 'Active' : 'Inactive'"
                v-model="mobileSalesBanner.is_active" color="primary" class="ma-0 pa-0" hide-details></v-checkbox>
              <v-btn color="primary" elevation="0" style="width:fit-content" class="align-self-end"
                v-if="getUser().scopes.includes('settings:edit')" @click="submitMobilePageBanner"
                :disabled="developModeData && developModeData.is_restricted_screen_shot && !restriction">Save
              </v-btn>
            </div>
          </v-form>
        </div>
        <div>
          <h4 class="font-weight-medium">Web App Banner</h4>
          <v-form ref="desktopPageBannerForm">
            <div class="pa-2 d-flex flex-column" style="gap: 10px">
              <v-text-field v-model="desktopSalesBanner.name" outlined label="Name" dense hide-details
                :rules="[required()]" />
              <v-text-field v-model="desktopSalesBanner.description" outlined label="Description" dense hide-details
                :rules="[required()]" />
              <v-text-field v-model="desktopSalesBanner.percentage_off" outlined label="Percentage Off" dense hide-details
                :rules="[required()]" />

              <DateTimePicker v-model="desktopSalesBanner.sale_period" timeFormat="hh:mm:ss a" label="Sale Period"
                :rules="[required()]">
              </DateTimePicker>

              <v-checkbox :label="(desktopSalesBanner.is_active) ? 'Active' : 'Inactive'"
                v-model="desktopSalesBanner.is_active" color="primary" class="ma-0 pa-0" hide-details></v-checkbox>
              <v-btn color="primary" elevation="0" style="width:fit-content" class="align-self-end"
                v-if="getUser().scopes.includes('settings:edit')" @click="submitDesktopPageBanner"
                :disabled="developModeData && developModeData.is_restricted_screen_shot && !restriction">Save
              </v-btn>
            </div>
          </v-form>
        </div> -->
      </div>
    </v-card>

    <v-dialog
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'"
      v-model="developModeDialog"
      persistent
    >
      <v-card class="pa-5">
        <v-form ref="developModeForm" v-if="!loading">
          <v-card-title class="text-center"
            >Activate Development Mode</v-card-title
          >
          <v-textarea
            v-model="message"
            outlined
            label="Message"
            dense
            :rules="[required()]"
          />
          <div class="d-flex justify-end">
            <v-btn class="mr-2" color="error" @click="cancelDevelopModeForm"
              >Cancel</v-btn
            >
            <v-btn color="primary" @click="submitDevelopModeForm">Submit</v-btn>
          </div>
        </v-form>
        <div v-else class="py-16 d-flex justify-center align-center">
          <v-progress-circular indeterminate />
        </div>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="loading" message="Loading..." />
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-if="snackbarText !== 'Loading...'"
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { required } from '@/utils/validators';
import { getUser } from '@/utils/local';
import moment from 'moment-timezone';
const { format, parseISO } = require('date-fns');
import LoadingDialog from '@/components/LoadingDialog';
// import DateTimePicker from '@/components/settings/DateTimePicker';
export default {
  name: 'settings',
  components: { LoadingDialog },
  data() {
    return {
      user: {},
      developMode: false,
      developModeDialog: false,
      message: null,
      developModeData: null,
      inReview: null,
      restriction: null,
      unRestrictionUntil: '',
      loading: false,
      snackbar: false,
      snackbarText: 'Loading...',
      playStoreVersion: null,
      appStoreVersion: null,
      restrictionDateTime: null,
      enable_three_day_free_trial: false,
      three_day_free_trial_text: '',
      web_app_enable_three_day_free_trial: false,
      web_app_three_day_free_trial_text: '',

      adaptive_qbank: null,
      boolean_blitz: null,
      endless_mode: null,
      timed_mode: null,
      multiplayer: null,
      quiz_game_mode: null,
      review_mode: null,
      question_of_the_day: null,
      passage_mode: null,
      flashcards: null,

      desktopSalesBanner: {
        id: '',
        name: '',
        description: '',
        percentage_off: '',
        sale_period: '',
        is_active: null,
        createdAt: null
      },

      mobileSalesBanner: {
        id: '',
        name: '',
        description: '',
        percentage_off: '',
        sale_period: '',
        is_active: null,
        createdAt: null
      }
    };
  },
  mounted() {
    this.user = this.getUser();
    this.getDevelopMode();
    this.loading = true;
    this.$axios
      .get('/sales')
      .then((response) => {
        this.setBannersDetails(response);
      })
      .catch((error) => {
        console.error(error);
      });
    this.loading = false;
  },
  methods: {
    required,
    getUser,

    setBannersDetails(response) {
      const mobileBannerIndex = response.data.sales.findIndex(
        (sale) => sale.platform === 0
      );
      const desktopBannerIndex = response.data.sales.findIndex(
        (sale) => sale.platform === 1
      );

      // Check if mobile banner exists and populate mobileSalesBanner
      if (mobileBannerIndex !== -1) {
        const mobileBanner = response.data.sales[mobileBannerIndex];
        this.mobileSalesBanner.id = mobileBanner.id;
        this.mobileSalesBanner.name = mobileBanner.name;
        this.mobileSalesBanner.description = mobileBanner.description;
        this.mobileSalesBanner.percentage_off = mobileBanner.percentage_off;
        this.mobileSalesBanner.is_active = mobileBanner.is_active;
        this.mobileSalesBanner.createdAt = mobileBanner.createdAt;
        this.mobileSalesBanner.sale_period = this.formateDate(
          mobileBanner.sale_period,
          'yyyy-MM-dd hh:mm:ss a'
        );
      }

      // Check if desktop banner exists and populate desktopSalesBanner
      if (desktopBannerIndex !== -1) {
        const desktopBanner = response.data.sales[desktopBannerIndex];
        this.desktopSalesBanner.id = desktopBanner.id;
        this.desktopSalesBanner.name = desktopBanner.name;
        this.desktopSalesBanner.description = desktopBanner.description;
        this.desktopSalesBanner.percentage_off = desktopBanner.percentage_off;
        this.desktopSalesBanner.is_active = desktopBanner.is_active;
        this.desktopSalesBanner.createdAt = desktopBanner.createdAt;
        this.desktopSalesBanner.sale_period = this.formateDate(
          desktopBanner.sale_period,
          'yyyy-MM-dd hh:mm:ss a'
        );
      }
    },

    formateDate(inputDate, outputFormat) {
      const dateObj1 = parseISO(inputDate);
      const formattedDate = format(dateObj1, outputFormat);
      return formattedDate;
    },

    async getDevelopMode() {
      this.loading = true;
      this.developModeData = (await this.$axios.get('/app-status')).data;
      let date = '';
      if (
        this.developModeData.screenshot_restricted_until &&
        this.developModeData.screenshot_restricted_until !== ''
      ) {
        date = this.developModeData.screenshot_restricted_until.split(' ');
        date.pop();
        date = date.join(' ');
        date = moment(date).format('YYYY-MM-DDThh:mm:ss');
      } else {
        date = moment().format('YYYY-MM-DDThh:mm:ss');
      }
      if (this.developModeData) {
        this.developMode = !!this.developModeData.status;
        this.inReview = !!this.developModeData.in_review;
        this.appStoreVersion = this.developModeData.appstore_version;
        this.playStoreVersion = this.developModeData.playstore_version;
        this.restriction = this.developModeData.is_restricted_screen_shot;
        this.unRestrictionUntil = date;
        this.message = this.developModeData.message;
        this.three_day_free_trial_text =
          this.developModeData.three_day_free_trial_text;
        this.enable_three_day_free_trial =
          this.developModeData.enable_three_day_free_trial;
        this.web_app_three_day_free_trial_text =
          this.developModeData.web_app_three_day_free_trial_text;
        this.web_app_enable_three_day_free_trial =
          this.developModeData.web_app_enable_three_day_free_trial;

        this.adaptive_qbank = this.developModeData.adaptive_qbank;
        this.boolean_blitz = this.developModeData.boolean_blitz;
        this.endless_mode = this.developModeData.endless_mode;
        this.timed_mode = this.developModeData.timed_mode;
        this.multiplayer = this.developModeData.multiplayer;
        this.quiz_game_mode = this.developModeData.quiz_game_mode;
        this.review_mode = this.developModeData.review_mode;
        this.question_of_the_day = this.developModeData.question_of_the_day;
        this.passage_mode = this.developModeData.passage_mode;
        this.flashcards = this.developModeData.flashcards;
      }
      this.loading = false;
    },
    async toggleDevelopMode() {
      if (this.developModeData && !this.inReview) {
        if (this.developMode) {
          this.developModeDialog = true;
        } else {
          await this.saveSettings();
        }
      } else if (this.inReview) {
        this.snackbar = true;
        this.snackbarText = "Can't go into develop mode when in review.";
      }
    },
    async toggleInReview() {
      try {
        if (this.inReview) this.developMode = false;
        await this.saveSettings();
      } catch (e) {
        this.snackbarText = 'Some error occured';
        this.snackbar = true;
      }
    },
    async submitVersion() {
      if (this.$refs.versionForm.validate()) {
        await this.saveSettings();
      }
    },
    async submitRestriction() {
      if (this.$refs.restrictionForm.validate()) {
        if (moment(this.unRestrictionUntil).isSameOrBefore(moment())) {
          this.snackbar = true;
          this.snackbarText = 'Date selected should be a future date.';
          return;
        }

        await this.saveSettings();
        await this.$axios.post('/app-status/schedule');
      }
    },
    async submitThreeDayFreeTrial() {
      if (
        this.$refs.threeDayFreeTrial.validate() ||
        this.enable_three_day_free_trial === false
      ) {
        await this.saveSettings();
      }
    },
    async submitWebAppThreeDayFreeTrial() {
      if (
        this.$refs.webAppThreeDayFreeTrial.validate() ||
        this.web_app_enable_three_day_free_trial === false
      ) {
        await this.saveSettings();
      }
    },
    async submitMobilePageBanner() {
      if (this.$refs.mobilePageBannerForm.validate()) {
        this.loading = true;
        const date = new Date(this.mobileSalesBanner.sale_period);
        const clonedBanner = {
          ...this.mobileSalesBanner,
          sale_period: date.toISOString(),
          platform: 0
        };

        try {
          await this.$axios.patch('/sales', clonedBanner);
        } catch (error) {
          this.toast('Error occurred while updating Sales Banner');
        }
        this.loading = false;
      }
    },
    async submitDesktopPageBanner() {
      if (this.$refs.desktopPageBannerForm.validate()) {
        this.loading = true;
        const date = new Date(this.desktopSalesBanner.sale_period);
        const clonedBanner = {
          ...this.desktopSalesBanner,
          sale_period: date.toISOString(),
          platform: 1
        };
        try {
          await this.$axios.patch('/sales', clonedBanner);
        } catch (error) {
          this.toast('Error occurred while updating Sales Banner');
        }
        this.loading = false;
      }
    },
    async submitDevelopModeForm() {
      if (this.$refs.restrictionForm.validate()) {
        await this.saveSettings();
        await this.$axios.post('/app-status/schedule');
      }
    },
    toast(text) {
      this.$toast.error(text, {
        position: 'bottom-left',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false
      });
    },
    cancelDevelopModeForm() {
      this.loading = false;
      this.developMode = false;
      this.developModeDialog = false;
      this.$refs.developModeForm.reset();
      this.message = this.developModeData.message;
    },

    async saveSettings() {
      this.loading = true;
      const data = {
        id: this.developModeData.id,
        in_review: this.inReview,
        status: this.developMode ? 1 : 0,
        appstore_version: this.appStoreVersion,
        playstore_version: this.playStoreVersion,
        is_restricted_screen_shot: this.restriction,
        screenshot_restricted_until: moment
          .tz(this.unRestrictionUntil, moment.tz.guess())
          .format('DD-MM-YYYY hh:mm:ss A z'),
        three_day_free_trial_text: this.three_day_free_trial_text,
        enable_three_day_free_trial: this.enable_three_day_free_trial,
        web_app_three_day_free_trial_text:
          this.web_app_three_day_free_trial_text,
        web_app_enable_three_day_free_trial:
          this.web_app_enable_three_day_free_trial,

        adaptive_qbank: this.adaptive_qbank,
        boolean_blitz: this.boolean_blitz,
        endless_mode: this.endless_mode,
        timed_mode: this.timed_mode,
        multiplayer: this.multiplayer,
        quiz_game_mode: this.quiz_game_mode,
        review_mode: this.review_mode,
        question_of_the_day: this.question_of_the_day,
        passage_mode: this.passage_mode,
        flashcards: this.flashcards
      };
      if (this.message) {
        data.message = this.message;
      }
      await this.$axios.patch('/app-status', data);
      await this.getDevelopMode();
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
