<template>
  <data-table title="Promo Refers" :headers="headers" :allow-add="false" :loader="loadPromoRefers">
    <template #referred="{ item }">
      <div>
        <p class="ma-0">{{ item.referred_by_name }}</p>
        <p class="ma-0 caption">{{ item.referred_by_email }}</p>
      </div>
    </template>
    <template #redeemed="{ item }">
      <div>
        <p class="ma-0">{{ item.name }}</p>
        <p class="ma-0 caption">{{ item.email }}</p>
      </div>
    </template>
  </data-table>
</template>

<script>
import axios from 'axios';
import DataTable from '../../components/DataTable';

export default {
  name: 'PromoRefersView',
  components: {DataTable},

  data: () => ({
    headers: [
      {
        text: 'Referred By',
        value: 'referred'
      },
      {
        text: 'Redeemed By',
        value: 'redeemed'
      },
    ]
  }),

  methods: {
    async loadPromoRefers() {
      return (await axios.get('/user-stats/promo-refers')).data;
    }
  }
};
</script>

<style scoped></style>
