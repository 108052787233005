<template>
  <data-table title="Reviews" :headers="headers" :allow-add="false" :loader="loadReviews">
    <template #type="{ item }">
      <v-chip>{{ item.type }}</v-chip>
    </template>

    <template #createdAt="{ item }">
      <span>{{ formatDate(item.createdAt) }}</span>
    </template>

    <template #stepsToReproduce="{ item }">
      <span
          v-if="!item.stepsToReproduce"
          style="color: grey; font-style: italic; font-size: 10px"
      >
        None
      </span>
      <span v-else>{{ item.stepsToReproduce }}</span>
    </template>
  </data-table>
</template>

<script>
import axios from 'axios';
import DataTable from '../../components/DataTable';
import moment from "moment/moment";

export default {
  name: 'ReviewsView',
  components: {DataTable},

  data: () => ({
    headers: [
      {
        text: 'Type',
        value: 'type'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Details',
        value: 'feedback',
        width: '40%'
      },
      {
        text: 'Steps To Reproduce',
        value: 'stepsToReproduce'
      },
      {
        text: 'App Version',
        value: 'app_version'
      },
      {
        text: 'Created At',
        value: 'createdAt'
      }
    ]
  }),

  methods: {
    async loadReviews() {
      return (await axios.get('/reviews')).data;
    },
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY HH:MM A')
    }
  }
};
</script>

<style scoped></style>
