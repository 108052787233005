<template>
  <div>
    <data-table
      v-if="dataLoaded"
      :key="componentKey"
      :loader="loadData"
      :headers="headers"
      title="Student Group Leaderboard"
      :allow-add="false"
      @done="$router.back()"
    >
      <template #accuracy="{ item }">
        {{ item.accuracy.toFixed(2) }} %
      </template>
    </data-table>

    <loading-dialog v-model="loading" message="Please Wait..." />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import { getUser } from '@/utils/local';
import { StudentGroupsService } from '@/services/student-groups-service';
import { CategoryService } from '@/services/category-service';
import dayjs from 'dayjs';

export default {
  name: 'LeaderboardView',
  components: {
    LoadingDialog,
    DataTable
  },

  data: () => ({
    loading: false,
    search: '',
    service: new StudentGroupsService(),
    categoryService: new CategoryService(),
    allCategories: [],

    stats: [],
    sortBy: '',
    sortByDes: false,
    sortedItem: [],

    componentKey: 0,
    dataLoaded: false,

    headers: []
  }),

  async mounted() {
    await this.loadCategories();
    await this.loadData();

    this.dataLoaded = true;

    this.componentKey += 1;
  },

  watch: {
    search(current) {
      if (this.customSearch) {
        if (current) {
          this.searchAllData();
        } else {
          this.tempItems = this.items;
        }
      }
    }
  },

  methods: {
    getUser,
    startsWithEnglishAlphabet(value) {
      return /^[A-Za-z]/.test(value);
    },
    onSort(e) {
      this.sortBy = e;
      if (e && !this.startsWithEnglishAlphabet(e)) {
        this.stats = this.stats.sort((a, b) => {
          return (
            b?.category_stats[`${e}`]?.percentage -
            a?.category_stats[`${e}`]?.percentage
          );
        });

        // this.componentKey += 1;
      }
    },
    onSortDes(e) {
      if (e) this.onSort(this.sortBy);
    },
    logger(item) {
      console.log(item);
    },
    formatDate(date) {
      return dayjs(date).format('MMMM D, YYYY');
    },
    isValidCategoryStat(item, categoryId) {
      return (
        typeof item === 'object' &&
        item[categoryId] &&
        typeof item[categoryId].percentage === 'number'
      );
    },
    async loadData() {
      this.loading = true;
      if (this.$route.params.id) {
        this.stats = await this.service.fetchLeaderboardDetails(
          this.$route.params.id
        );

        this.stats = this.stats.map((item, index) => {
          this.allCategories.forEach((category) => {
            const stats = item.category_stats[category.id];
            if (stats) {
              const keyName = category.name.toLowerCase().replace(/\s+/g, '_');
              item[keyName] = `${stats.percentage.toFixed(2)} %`;
            } else {
              const keyName = category.name.toLowerCase().replace(/\s+/g, '_');
              item[keyName] = '--';
            }
          });

          item.rank = index + 1;
          return item;
        });

        this.loading = false;

        return this.stats;
      } else {
        this.loading = false;
        return [];
      }
    },
    async loadCategories() {
      this.loading = true;
      this.allCategories = await this.categoryService.fetchAll();

      const baseHeaders = [
        // { text: 'Rank', value: 'rank', sortable: true },
        { text: 'Name', value: 'name', sortable: true },
        // { text: 'Email', value: 'email', sortable: true },
        { text: 'Accuracy', value: 'accuracy', sortable: true },
        { text: 'Total Game Plays', value: 'total', sortable: true },
        { text: 'Total Correct Answers', value: 'right', sortable: true },
        { text: 'Total Wrong Answers', value: 'wrong', sortable: true }
      ];

      if (this.allCategories && this.allCategories.length > 0) {
        const categoryHeaders = this.allCategories.map(({ name }) => ({
          text: name,
          // value: id,
          value: name.toLowerCase().replace(/\s+/g, '_')
        }));

        this.headers = [...baseHeaders, ...categoryHeaders];
      } else {
        this.headers = baseHeaders;
      }

      this.loading = false;
    }
  }
};
</script>
