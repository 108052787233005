<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title">
      {{ isEdit ? 'Update Student Group' : 'Create New Student Group' }}
    </p>

    <v-text-field
      v-model="studentGroup.name"
      label="Name"
      dense
      :rules="[required('A name must be provided')]"
      outlined
      persistent-hint
      class="span-2"
    />

    <v-autocomplete
      v-if="getUser().scopes.includes('studentGroups:all')"
      v-model="studentGroup.admins"
      :items="admins"
      item-text="text"
      item-value="value"
      :loading="adminsLoading"
      :rules="[required('An admin must be selected')]"
      label="Admins"
      multiple
      dense
      outlined
      persistent-hint
      class="span-2"
    />
    <v-switch
      v-if="!isEdit"
      label="Bulk Creation"
      v-model="isBulkCreation"
    ></v-switch>
    <br />

    <div v-if="isBulkCreation" class="span-2">
      <v-btn color="primary" width="120">
        <label for="file" class="py-2 px-2" style="cursor: pointer">
          <v-icon class="v-btn__pre-icon" size="16">mdi-upload</v-icon>
          Upload CSV
        </label>
      </v-btn>
      <div v-if="file" class="file-name">
        <span v-if="file"
          >{{ file.name }}
          <v-icon
            size="18"
            color="red"
            @click="
              () => {
                $refs.inputRef.reset();
              }
            "
          >
            mdi-delete
          </v-icon>
        </span>
      </div>
    </div>

    <v-file-input
      v-model="file"
      id="file"
      accept=".csv"
      class="d-none"
      ref="inputRef"
    ></v-file-input>

    <v-text-field
      v-if="!isBulkCreation"
      v-model="searchUser"
      label="Search User by Email"
      dense
      append-icon="mdi-magnify"
      @click:append="getSearchUser"
      @keyup.enter="getSearchUser"
      outlined
      persistent-hint
      class="span-2"
      :loading="userLoading"
    />

    <div
      class="span-2 d-flex justify-space-between"
      v-if="searchUserResult || userError"
    >
      <p>
        {{ searchUserResult ? 'User Found: ' : 'No User Found' }}
        <span style="font-weight: 400; color: #6c757d">
          {{ searchUserResult.email }}</span
        >
      </p>

      <v-btn
        elevation="0"
        small
        color="primary"
        :disabled="!searchUserResult"
        @click="
          if (
            !studentGroup.user_ids.find(
              (user) => user.email === searchUserResult.email
            )
          ) {
            studentGroup.user_ids.push({
              email: searchUserResult.email,
              id: searchUserResult.id
            });
          }

          searchUserResult = null;
          userError = false;
        "
      >
        Add User
      </v-btn>
    </div>

    <div
      v-for="user in studentGroup.user_ids"
      :key="user.id"
      class="my-2 span-2 d-flex justify-space-between align-center"
    >
      <span
        >{{ studentGroup.user_ids.indexOf(user) + 1 }}. {{ user.email }}</span
      >

      <div>
        <v-btn
          x-small
          elevation="0"
          color="primary"
          class="mr-2"
          @click="viewUserDetails(user)"
        >
          <v-icon x-small> mdi-eye </v-icon>
        </v-btn>

        <v-btn
          x-small
          elevation="0"
          color="error"
          @click="
            studentGroup.user_ids.splice(studentGroup.user_ids.indexOf(user), 1)
          "
        >
          <v-icon x-small> mdi-delete </v-icon>
        </v-btn>
      </div>
    </div>

    <loading-dialog v-model="loading" message="Fetching Student Group Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import { StudentGroupsService } from '@/services/student-groups-service';
import { UsersService } from '@/services/users-service';
import { users } from '@/plugins/firebase';
import { required } from '@/utils/validators';
import { getDays } from '@/utils/local';
import moment from 'moment';
import { getUser } from '@/utils/local';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    userServices: new UsersService(),
    service: new StudentGroupsService(),
    file: null,
    isBulkCreation: false,

    admins: [],
    adminsLoading: false,

    searchUser: '',
    searchUserResult: null,
    userLoading: false,
    userError: false,

    studentGroupScopes: [
      'studentGroups:new',
      'studentGroups:view',
      'studentGroups:edit',
      'studentGroups:delete'
    ],

    studentGroup: {
      name: '',
      admins: [],
      user_ids: []
    }
  }),

  async mounted() {
    this.loadData();

    this.adminsLoading = true;
    await this.userServices.fetchAll().then((users) => {
      this.admins = users.map((user) => ({
        text: user.username,
        value: user.id
      }));
    });
    this.adminsLoading = false;
  },

  methods: {
    required,
    getDays,
    getUser,

    async loadData() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.studentGroup = await this.service.fetchOne(this.$route.query.id);

      this.studentGroup.user_ids = await Promise.all(
        this.studentGroup.user_ids.map(async (id) => {
          const snapshot = await users.doc(id).get();
          return {
            id: snapshot.id,
            email: snapshot.data().email
          };
        })
      );

      this.loading = false;
    },

    async getSearchUser() {
      if (!this.searchUser) return;
      this.userLoading = true;
      this.searchUser = this.searchUser.replace(/\s/g, '');
      const snapshot = await users.where('email', '==', this.searchUser).get();
      snapshot.forEach((doc) => {
        this.searchUserResult = doc.data();
      });

      if (!this.searchUserResult) {
        this.userError = true;
      }

      this.userLoading = false;
    },

    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Student Group');

        if (this.studentGroup.user_ids.length === 0) {
          context.reportError({
            title: 'Error while updating student group',
            description: 'At least one user must be added to the student group'
          });
          return false;
        }

        try {
          this.studentGroup.user_ids = this.studentGroup.user_ids.map(
            (user) => user.id
          );
          await this.service.update(this.studentGroup);
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while updating student group',
            description: e.toString()
          });
          return false;
        }
      } else {
        try {
          context.changeLoadingMessage('Creating New Student Group');

          if (
            this.studentGroup.user_ids?.length === 0 &&
            !this.isBulkCreation
          ) {
            context.reportError({
              title: 'Error while creating student group',
              description:
                'At least one user must be added to the student group'
            });
            return false;
          }

          if (!this.getUser().scopes.includes('studentGroups:all')) {
            this.studentGroup.admins = [this.getUser().id];
          }

          if (this.isBulkCreation) {
            delete this.studentGroup.user_ids;
            const res = await this.service.createBulkStudents({
              file: this.file,
              ...this.studentGroup
            });

            const notFoundUsers = res?.not_found_users;

            if (notFoundUsers && notFoundUsers.length > 0) {
              const textarea = document.createElement('textarea');
              textarea.value = notFoundUsers.join('\n');
              document.body.appendChild(textarea);
              textarea.select();
              document.execCommand('copy');
              document.body.removeChild(textarea);
              this.$toast.success(
                'Success! Not found users copied to clipboard.'
              );
            } else {
              this.$toast.success(
                'Student Group created successfully and access for group students is also ensured.'
              );
            }
          } else {
            this.studentGroup.user_ids = this.studentGroup.user_ids.map(
              (user) => user.id
            );
            await this.service.create(this.studentGroup);

            Promise.all(
              this.studentGroup.admins.map(async (admin) => {
                const adminData = await this.userServices.fetchOne(admin);
                adminData.scopes = [
                  ...adminData.scopes,
                  ...this.studentGroupScopes
                ];

                await this.userServices.update(adminData);

                return adminData;
              })
            );

            this.$toast.success('Student Group created successfully.');
          }

          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating student group',
            description: e.toString()
          });
          return false;
        }
      }
    },
    formatTime(time) {
      return moment(time, 'HH:mm').format('h:mm A');
    },
    async viewUserDetails(email) {
      const snapshot = await users.where('email', '==', email.email).get();

      if (snapshot.empty) {
        return;
      }

      snapshot.forEach((doc) => {
        this.$router.push(`/customer-detail?id=${doc.data().id}`);
      });
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}

.file-name {
  color: white;
  font-weight: bold;
  font-size: 12px;

  display: inline-block;
  margin-left: 20px;
  background-color: rgba(73, 93, 183, 0.4);
  border-radius: 20px;
  padding: 5px 10px 5px 20px;
}
</style>
