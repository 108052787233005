import axios from 'axios';

export class CustomerService {
  async fetchCustomerStats(id) {
    return (await axios.get('/user-stats/' + id)).data;
  }

  async fetchCustomerMetrics(id) {
    return (await axios.get('/surveys/exam/' + id)).data;
  }

  async fetchOnBoardingCustomers() {
    return (await axios.get('/user-stats/users/on-boarding')).data;
  }

  async fetchRegisteredUsersCount() {
    return (await axios.get('/user-stats/count-registered-users')).data;
  }

  async fetchAllCheatingCustomers() {
    return (await axios.get('/prohibitions-stats/all')).data;
  }

  async getProhibitionsStats(customerId) {
    return (await axios.get(`/prohibitions-stats/${customerId}`)).data;
  }

  async unbanCustomer(customerId) {
    return (await axios.patch(`/prohibitions-stats/unban/full/${customerId}`))
      .data;
  }

  async customerStats(customerId) {
    return (await axios.get(`user-stats/new/individual/${customerId}`)).data;
  }

  async getPredictionScore(customerId) {
    return (await axios.get(`/games-stats/prediction/${customerId}`)).data;
  }
}
