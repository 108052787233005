<template>
  <data-table
      :loader="loadData"
      :headers="headers"
      title="Scheduled QOTD"
      :allow-add="false"
      @done="$router.back()"
      :delete-handler="null"
      :edit-handler="getUser() ? getUser().scopes.includes('scheduledQotd:edit') ? edit : null : null"
  >
    <template #day="{ item }">
      <span class="font-weight-black body-1">{{ item.day }}</span>
    </template>
    <template #category="{ item }">
      {{ item.category.name }}
    </template>
    <template #subCategory="{ item }">
      {{ item.subCategory.name }}
    </template>
    <template #createdAt="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
  </data-table>
</template>

<script>
import dayjs from 'dayjs';
import DataTable from '../../components/DataTable';
import {getDays, getUser} from '@/utils/local';
import {ScheduledQotdService} from "@/services/scheduled-qotd-service";


export default {
  components: {DataTable},
  data: () => ({
    items: [],
    loading: false,
    service: new ScheduledQotdService(),

    headers: [
      {
        text: 'Day',
        value: 'day',
        sortable: false
      },

      {
        text: 'Date',
        value: 'createdAt',
        sortable: true,
        width: 150
      },
      {
        text: 'Question',
        value: 'statement',
        sortable: false,
      },
      {
        text: 'Category',
        value: 'category',
        sortable: false,
      },
      {
        text: 'Sub Category',
        value: 'subCategory',
        sortable: false,
      },
    ]
  }),

  methods: {
    getUser,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY');
    },
    edit(item) {
      this.$router.push(`/scheduled-qotd?id=${item.id}&day=${item.index}`)
    },
    async loadData() {
      this.items = await this.service.fetchAll()
      const startDate = new Date(this.items.createdAt)
      this.items.questions = this.items.questions.map((item, index) => {
        item.index = index;
        item.day = getDays(index + 1)
        const newDate = new Date(startDate);
        newDate.setDate(startDate.getDate() + index);
        item.createdAt = newDate;
        return item;
      })
      return this.items.questions;
    }
  }
};
</script>

<style scoped></style>
