<template>
  <data-table
    :loader="loadData"
    :headers="headers"
    :showSearch="false"
    title="Daily Bonus"
    :allow-add="isCreatable"
    @add-new="addNew"
    @done="$router.back()"
    :edit-handler="
      getUser() ? (getUser().scopes.includes('users:edit') ? edit : null) : null
    "
  >
    <template #primary-action>
      <v-btn
        v-if="getUser().scopes.includes('spin-the-wheel-coins:view')"
        color="primary"
        outlined
        class="mr-2"
        @click="$router.push('/spin-the-wheel-coins')"
      >
        Manage Curve Coins
      </v-btn>
    </template>
    <template #day="{ item }">
      {{ days[item.day - 1] }}
    </template>
    <template #scopes="{ item }">
      {{ item.prize }}
    </template>
    <template #createdAt="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
  </data-table>
</template>

<script>
import dayjs from 'dayjs';
import { DailyBonusService } from '@/services/daily-bonus';
import DataTable from '../../components/DataTable';
import { getUser } from '@/utils/local';

export default {
  components: { DataTable },

  data: () => ({
    items: [],
    loading: false,
    fetchedData: {},
    service: new DailyBonusService(),
    days: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ],

    headers: [
      {
        text: 'Day Name',
        value: 'day',
        sortable: false
      },

      {
        text: 'Coins',
        value: 'scopes',
        sortable: false
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: false
      }
    ]
  }),

  methods: {
    getUser,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },

    addNew() {
      this.$router.push('/bonus');
    },
    edit(item) {
      this.$router.push(`/bonus?id=${item.id}`);
    },
    loadData() {
      const data = this.service
        .fetchAll()
        .then((response) => {
          // Code to handle successful response
          this.fetchedData = response;
          return this.fetchedData;
        })
        .catch((error) => {
          // Code to handle error
          console.error(error);
        });

      return data;
    }
  },
  computed: {
    isCreatable() {
      return Object.keys(this.fetchedData).length < 7 ? true : false;
    }
  }
};
</script>

<style scoped></style>
