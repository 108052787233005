<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      title="Roadmap Suggestions"
      :allow-add="false"
      @done="$router.back()"
      :view-handler="view"
    >
      <template v-slot:chapter="{ item }">
        {{ item.chapter_id.name }}
      </template>

      <template v-slot:resolve="{ item }">
        <v-checkbox
          v-model="item.resolve"
          @change="updateData(item)"
        ></v-checkbox>
      </template>

      <template v-slot:suggestion="{ item }">
        {{
          item.suggestion.length > 50
            ? item.suggestion.substring(0, 50) + '...'
            : item.suggestion
        }}
      </template>
      <template v-slot:createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </data-table>

    <v-dialog v-if="showDialog" v-model="showDialog" width="700">
      <v-card style="padding: 20px; max-height: 250px; overflow: scroll">
        <h4>Chapter:</h4>
        <p>{{ currentSuggestion.chapter_id.name }}</p>
        <h4>Suggestion:</h4>
        <p>{{ currentSuggestion.suggestion }}</p>

        <h4>Resolved:</h4>
        <p>{{ currentSuggestion.resolve ? 'Yes' : 'No' }}</p>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" message="Updating ..." />
  </div>
</template>

<script>
import { getUser } from '@/utils/local';
import DataTable from '@/components/DataTable.vue';
import { RoadmapModeService } from '@/services/roadmap-mode-service';
import LoadingDialog from '@/components/LoadingDialog.vue';
import { required } from '@/utils/validators';
import moment from 'moment';

export default {
  name: 'SuggestionView',

  components: { DataTable, LoadingDialog },

  data: () => ({
    items: [],
    loading: false,
    service: new RoadmapModeService(),
    showDialog: false,
    currentSuggestion: null,

    headers: [
      {
        text: 'Name',
        value: 'user_name',
        sortable: true
      },
      {
        text: 'Email',
        value: 'user_email',
        sortable: true
      },
      {
        text: 'Chapter',
        value: 'chapter',
        sortable: true
      },
      {
        text: 'Suggestion',
        value: 'suggestion',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      },
      {
        width: 120,
        text: 'Resolved',
        value: 'resolve'
      }
    ]
  }),

  methods: {
    getUser,
    required,

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY - hh:mm A');
    },

    view(item) {
      this.currentSuggestion = item;
      this.showDialog = true;
    },

    async updateData(item) {
      this.loading = true;
      await this.service.updateUserSuggestion({
        id: item.id,
        resolve: item.resolve ? 1 : 0
      });
      this.loading = false;
    },

    async loadData() {
      const data = await this.service.fetchAllUserSuggestions(this.filterQuery);

      // Sort by resolved
      data.sort((a, b) => {
        if (a.resolve && !b.resolve) return 1;
        if (!a.resolve && b.resolve) return -1;
        return 0;
      });

      return data;
    }
  }
};
</script>

<style scoped></style>
