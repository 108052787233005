<template>
  <PassageQuestionFormComponent />
</template>

<script>
import PassageQuestionFormComponent from '@/components/questions/PassageQuestionForm.vue';

export default {
  name: 'PassageQuestionForm',
  components: { PassageQuestionFormComponent }
};
</script>

<style scoped></style>
