import axios from 'axios';

export class QuestionMediaService {
  async paginate(
    isActive = true,
    limit,
    currentPage,
    search,
    mediaType,
    QCountSort,
    category,
    subCategory
  ) {
    let url = `/question-media${isActive ? '' : '/inactive'}?${
      limit ? `limit=${limit}` : ''
    }${currentPage ? `&page=${currentPage}` : ''}${
      search ? `&search=${search}` : ''
    }${mediaType ? `&type=${mediaType}` : ''}${
      QCountSort ? `&sortBy=${QCountSort}` : ''
    }${category ? `&category=${category}` : ''}${
      subCategory ? `&subCategory=${subCategory}` : ''
    }`;

    return (await axios.get(url)).data;
  }

  async fetchOne(id) {
    return (await axios.get('/question-media/get/' + id)).data;
  }

  async create(media) {
    return (await axios.post('/question-media', media)).data;
  }

  async update(media) {
    return (await axios.patch('/question-media', media)).data;
  }

  async delete(media) {
    return (await axios.delete('/question-media/' + media.id)).data;
  }
  async reRunMedia() {
    return (await axios.get('/question-media/rerun/new')).data;
  }
}
