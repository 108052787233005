<template>
  <v-card>
    <v-tabs class="mb-4" v-model="tab" color="primary">
      <v-tab v-for="(type, index) in types" :key="index">
        {{ type }}
      </v-tab>
    </v-tabs>

    <data-table
      :loader="loadData"
      :headers="headers"
      title="Sub-Categories"
      :allow-add="true"
      @add-new="addNew"
      @done="$router.back()"
      :delete-handler="null"
      :edit-handler="
        getUser()
          ? getUser().scopes.includes('sub-categories:edit')
            ? edit
            : null
          : null
      "
      :key="tab"
    >
    </data-table>
  </v-card>
</template>

<script>
import DataTable from '../../components/DataTable';
import { getUser } from '@/utils/local';
import { SubCategoryService } from '@/services/subCategory-service';

export default {
  components: { DataTable },

  mounted() {},

  data: () => ({
    loading: false,
    service: new SubCategoryService(),
    tab: null,
    types: ['Active', 'Inactive'],

    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      }
    ]
  }),

  methods: {
    getUser,
    addNew() {
      this.$router.push('/sub-category');
    },
    edit(item) {
      this.$router.push(`/sub-category?id=${item.id}`);
    },
    async loadData() {
      if (this.tab === 0) {
        return await this.service.fetchAll();
      }

      if (this.tab === 1) {
        return await this.service.getFalseStatus();
      }
    }
  }
};
</script>

<style scoped></style>
