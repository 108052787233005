<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      title="MCAT Metrics Notifications"
      :show-search="false"
      :allow-add="false"
      :defaultFooter="false"
    >
    </data-table>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import { NotificationsService } from '../../services/notifications-service';

export default {
  components: { DataTable },

  data: () => ({
    services: new NotificationsService(),
    headers: [
      {
        text: 'Notification Title',
        value: 'title',
        sortable: false
      },
      {
        text: 'Total Sent',
        value: 'count',
        sortable: false
      }
    ]
  }),

  methods: {
    async loadData() {
      try {
        const response = await this.services.fetchMcatMetricsNotifications();
        return [
          {
            title: 'Good luck today, future doctor!',
            count: response.today
          },
          {
            title: 'Next week is the big day! You got this!',
            count: response.week
          },
          {
            title: 'Your MCAT is in one month! Keep it up!',
            count: response.month
          }
        ];
      } catch (e) {
        console.log(e, 'error');
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>
