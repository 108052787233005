<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-prices:view') : false
        "
        >Packages</v-tab
      >
      <v-tab
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-coupons:view') : false
        "
        >Coupons</v-tab
      >
      <v-tab
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-coupons:view') : false
        "
        >Spin the Wheel</v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-prices:view') : false
        "
      >
        <package-view />
      </v-tab-item>
      <v-tab-item
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-coupons:view') : false
        "
      >
        <coupons-view />
      </v-tab-item>
      <v-tab-item
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-coupons:view') : false
        "
      >
        <data-table
          :loader="loadData"
          :headers="headers"
          :allow-add="false"
          title="Spin the Wheel"
        >
          <template #primary-action>
            <v-btn color="primary" elevation="0" @click="save">
              <v-icon>mdi-disk</v-icon>
              Save
            </v-btn>
          </template>

          <template #web="{ item }"> {{ item.value }}% </template>

          <template #hide="{ item }">
            <v-checkbox
              label="Active"
              color="primary"
              v-model="item.isActive"
            ></v-checkbox>
          </template>
        </data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import DataTable from '../../components/DataTable';
import { getUser } from '@/utils/local';
import CouponsView from '@/views/offers/CouponsView.vue';
import PackageView from '@/views/offers/PackageView.vue';

export default {
  name: 'WebAppPurchaseOffersView',
  components: { PackageView, CouponsView, DataTable },
  data: () => ({
    tab: 0,
    headers: [
      {
        text: 'Percentage',
        value: 'web',
        sortable: false
      },
      {
        text: 'Action',
        value: 'hide',
        sortable: false
      }
    ],
    items: [],
    spinTheWheelData: {}
  }),
  methods: {
    getUser,

    async save() {
      const data = this.items
        .filter((item) => item.isActive)
        .map((item) => item.value);

      if (data.length < 3)
        return this.$toast.error(
          'Please select at least 3 percentages for spin the wheel',
          {
            position: 'bottom-left'
          }
        );

      this.spinTheWheelData.web = data;

      await this.$axios.patch(
        '/app-status/spin-the-wheel-percentage',
        this.spinTheWheelData
      );

      this.$toast.success('Spin the wheel percentages saved successfully', {
        position: 'bottom-left'
      });
    },

    async loadData() {
      let spinTheWheelData = (
        await this.$axios.get('/app-status/spin-the-wheel-percentage')
      ).data;
      this.spinTheWheelData = spinTheWheelData;

      let data = spinTheWheelData.web;

      if (!data) {
        data = [];
        for (let i = 10; i <= 70; i += 10) {
          data.push({
            value: i,
            isActive: false
          });
        }

        return (this.items = data);
      }

      data = data.map((item) => {
        return {
          value: item,
          isActive: true
        };
      });

      for (let i = 10; i <= 70; i += 10) {
        const index = data.findIndex((item) => item.value === i);

        if (index === -1) {
          data.push({
            value: i,
            isActive: false
          });
        }
      }

      data.sort((a, b) => {
        return a.value - b.value;
      });

      return (this.items = data);
    }
  }
};
</script>

<style scoped></style>
