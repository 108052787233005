<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      title="Facts"
      :allow-add="getUser() ? getUser().scopes.includes('facts:new') : false"
      @add-new="addNew"
      @done="$router.back()"
      :delete-handler="
        getUser()
          ? getUser().scopes.includes('facts:delete')
            ? service.delete
            : null
          : null
      "
      :edit-handler="
        getUser()
          ? getUser().scopes.includes('facts:edit')
            ? edit
            : null
          : null
      "
    >
      <template #active="{ item }">
        <v-checkbox hide-details dense v-model="item.active" readonly />
      </template>
    </data-table>

    <loading-dialog v-model="loadingDialog" message="Sending Notification" />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import { getUser } from '@/utils/local';
import { FactsService } from '@/services/facts-service';

export default {
  components: { LoadingDialog, DataTable },

  data: () => ({
    loading: false,
    loadingDialog: false,
    service: new FactsService(),

    headers: [
      {
        text: 'Fact',
        value: 'fact'
      },
      {
        width: 120,
        text: 'Is Active',
        value: 'active'
      }
    ]
  }),

  methods: {
    getUser,
    edit(item) {
      this.$router.push(`/fact?id=${item.id}`);
    },
    addNew() {
      this.$router.push('/fact');
    },
    loadData() {
      return this.service.fetchAll();
    }
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>
