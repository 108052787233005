<template>
  <SimpleForm :onSubmit="submit" @done="$router.push('/video-section-modal')">
    <p class="span-2 form__title">Add New Video Sections</p>
    <div class="span-2 mb-6 text-right">
      <v-btn
        class="add-terms-btn"
        elevation="0"
        @click="
          () => {
            videos.push({
              category: null,
              subCategory: null,
              media: null,
              selectedData: {
                images: [],
                videos: [],
                pdfs: [],
                mediaIds: []
              }
            });
          }
        "
      >
        <v-icon small dark class="mr-1"> mdi-plus-circle</v-icon>
        Add Video Section
      </v-btn>
    </div>
    <div
      v-for="(item, idx) of videos"
      :key="String(idx)"
      class="span-2 add-video-form"
    >
      <div class="d-flex justify-end mb-4">
        <v-icon
          size="25"
          color="red"
          class="mr-1 delete-btn"
          @click="removeSectionForm(idx)"
        >
          mdi-delete
        </v-icon>
      </div>
      <v-select
        v-model="item.category"
        :items="categories"
        :loading="loadingCategories"
        :rules="[required('Select a category')]"
        dense
        item-text="name"
        item-value="id"
        label="Select Category"
        outlined
        return-object
        @change="selectCategory(idx)"
        class="span-2"
      />
      <v-select
        v-model="item.subCategory"
        :disabled="!item.category"
        :items="item.subCategories"
        :rules="[required('Select a sub-category')]"
        dense
        item-text="name"
        label="Select Subcategory"
        class="span-2"
        outlined
        return-object
      />

      <div class="mb-4 span-2">
        <h5 class="mb-2">Select Videos:</h5>
        <v-btn
          color="primary"
          elevation="0"
          outlined
          @click="openMediaPickerModal(idx)"
        >
          Select Videos
        </v-btn>
      </div>

      <div class="span-2 carousel-display">
        <h4
          v-if="
            item.selectedData.videos && item.selectedData.videos.length <= 0
          "
          class="mt-6 text-center font-weight-light mb-4"
        >
          No Video Selected
        </h4>

        <h2
          v-if="item.selectedData.videos && item.selectedData.videos.length > 0"
          class="mt-5 mb-3"
        >
          {{ item.selectedData.videos.length > 1 ? 'Videos' : 'Video' }}
        </h2>

        <MediaPicker
          :id="`mediaPickerBtn${idx + 1}`"
          :selectedData="item.selectedData"
          @SetNewSelectedData="SetNewSelectedData"
          :select-media-id="true"
        />

        <div v-if="item.selectedData.videos">
          <v-carousel
            v-if="item.selectedData.videos.length > 0"
            :show-arrows="item.selectedData.videos.length > 1"
            hide-delimiters
            style="width: 100%; height: fit-content"
          >
            <template v-slot:prev="{ on, attrs }">
              <v-icon
                color="#4396e4"
                style="
                  padding: 10px;
                  background-color: white;
                  border-radius: 50%;
                "
                v-bind="attrs"
                v-on="on"
                >mdi-arrow-left
              </v-icon>
            </template>
            <template v-slot:next="{ on, attrs }">
              <v-icon
                color="#4396e4"
                style="
                  padding: 10px;
                  background-color: white;
                  border-radius: 50%;
                "
                v-bind="attrs"
                v-on="on"
                >mdi-arrow-right
              </v-icon>
            </template>

            <v-carousel-item
              v-for="(url, i) in item.selectedData.videos"
              :key="i"
            >
              <video
                :src="url"
                controls
                height="auto"
                style="width: 100%; height: 100%"
                width="auto"
              />
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
    </div>

    <loading-dialog v-model="loading" message="Loading Video Data" />
    <ErrorDialog v-model="error" :error="errorVal" @value="$router.back()" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';

import { required } from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '@/components/ErrorDialog';
import MediaPicker from '@/components/media/MediaPicker.vue';
import { VideoSectionService } from '@/services/video-session-service';
import { CategoryService } from '@/services/category-service';

export default {
  name: 'VideoSessionForm',
  components: {
    MediaPicker,
    ErrorDialog,
    LoadingDialog,
    SimpleForm
  },

  data: () => ({
    error: false,
    errorVal: {},

    videos: [
      {
        subCategories: [],
        category: null,
        subCategory: null,
        media: null,
        selectedData: {
          images: [],
          videos: [],
          pdfs: [],
          mediaIds: []
        }
      }
    ],
    loading: false,

    categories: [],

    selectedData: {
      images: [],
      videos: [],
      pdfs: []
    },

    videoSectionService: new VideoSectionService(),
    categoriesService: new CategoryService(),
    loadingCategories: false
  }),

  mounted() {
    this.loadCategories();
  },

  methods: {
    required,
    async loadCategories() {
      this.loadingCategories = true;
      this.categories = await this.categoriesService.fetchAll();
      this.videos[0].category = this.categories.find(
        (item) => item.id === this.$route.query.category
      );
      this.videos[0].subCategory = this.videos[0].category.subCategories.find(
        (item) => item.id === this.$route.query.subcategory
      );
      this.videos[0].subCategories = this.videos[0].category.subCategories;
      this.loadingCategories = false;
    },
    selectCategory(idx) {
      this.videos[idx].subCategories = this.videos[idx].category.subCategories;
    },

    // Media Picker
    openMediaPickerModal(idx) {
      document.getElementById(`mediaPickerBtn${idx + 1}`).click();
    },

    SetNewSelectedData(data, index) {
      if (index >= 0) this.videos[index].selectedData.mediaIds = data;
    },

    async submit(context) {
      context.changeLoadingMessage('Adding New Video');
      try {
        const data = [];
        this.videos.forEach((video) => {
          data.push({
            category: video.category.id,
            sub_category: video.subCategory.id,
            media: JSON.parse(JSON.stringify(video.selectedData.mediaIds))
          });
        });
        await this.videoSectionService.create(data);
        this.$toast.success('Section Added Successfully');
        return true;
      } catch (e) {
        return false;
      }
    },
    removeSectionForm(idx) {
      this.videos.splice(idx, 1);
    }
  }
};
</script>

<style scoped>
.modified-message >>> div {
  text-align: center;
}

p {
  font-weight: bold;
  text-align: left;
}

.add-terms-btn {
  border: 2px solid #1976d2 !important;
  color: #1976d2 !important;
  background-color: #fff !important;
}

.add-video-form {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.delete-btn {
  cursor: pointer;
}
</style>
