<template>
  <div>
    <data-table
      ref="table"
      :allow-add="getUser() ? getUser().scopes.includes('banners:new') : false"
      :delete-handler="
        getUser()
          ? getUser().scopes.includes('banners:delete')
            ? deleteHandler
            : null
          : null
      "
      :headers="headers"
      :loader="loadBanners"
      :show-search="false"
      title="Banners"
      @add-new="$router.push('/banner')"
    >
      <template #placementPage="{ item }">
        {{ getBannerPage(item.placementPage) }}
      </template>
      <template #placementPosition="{ item }">
        {{ getBannerPosition(item.placementPosition) }}
      </template>
      <template #action="{ item }">
        {{ getBannerAction(item.action) }}
      </template>
      <template #total-counts="{ item }">
        {{ item.stats.totalClickCount }}
      </template>
      <template #ios-counts="{ item }">
        {{ item.stats.iosClickCount }}
      </template>
      <template #android-counts="{ item }">
        {{ item.stats.androidClickCount }}
      </template>
      <template #web-counts="{ item }">
        {{ item.stats.webClickCount }}
      </template>
      <template #active="{ item }">
        <v-checkbox v-model="item.active" dense hide-details readonly />
      </template>
      <template #ios="{ item }">
        <v-checkbox v-model="item.ios" dense hide-details readonly />
      </template>
      <template #android="{ item }">
        <v-checkbox v-model="item.android" dense hide-details readonly />
      </template>

      <template #web="{ item }">
        <v-checkbox v-model="item.web" dense hide-details readonly />
      </template>

      <template #images="{ item }">
        <img
          v-if="item.image"
          :src="item.image"
          :style="{
            background: 'white',
            border: '1px solid white',
            borderRadius: '50px',
            objectFit: 'cover'
          }"
          alt="Default Image"
          height="40"
          loading="lazy"
          width="40"
        />
        <p v-else class="ma-0">No Image</p>
      </template>
      <template #extra-actions="{ item }">
        <v-icon
          color="success"
          small
          @click="$router.push(`/banner?id=${item.id}`)"
          >mdi-eye
        </v-icon>
        <v-icon
          color="success"
          small
          @click="$router.push(`/banner?edit=true&id=${item.id}`)"
          >mdi-pencil
        </v-icon>
      </template>
    </data-table>

    <loading-dialog v-model="loading" :message="loadingMessage" />
    <error-dialog v-model="error" :error="errorValue" />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import {
  getBannerAction,
  getBannerPage,
  getBannerPosition,
  getUser
} from '@/utils/local';
import { required, requiredV2 } from '@/utils/validators';
import { AdvertisementsService } from '@/services/advertisements-service';

export default {
  name: 'BannersView',
  components: { ErrorDialog, LoadingDialog, DataTable },

  data: () => ({
    headers: [
      {
        value: 'images',
        text: 'Images',
        sortable: false
      },
      {
        value: 'title',
        text: 'Title'
      },
      {
        value: 'action',
        text: 'Placement Action'
      },
      {
        value: 'ios-counts',
        text: 'IOS Count',
        sortable: false
      },
      {
        value: 'android-counts',
        text: 'Android Count',
        sortable: false
      },
      {
        value: 'web-counts',
        text: 'Web Count',
        sortable: false
      },
      {
        value: 'total-counts',
        text: 'Count',
        sortable: false
      },
      {
        value: 'active',
        text: 'Active'
      },
      {
        width: 100,
        text: 'For iOS',
        value: 'ios'
      },
      {
        width: 120,
        text: 'For Android',
        value: 'android'
      },
      {
        width: 120,
        text: 'For Web',
        value: 'web'
      }
    ],

    error: false,
    errorValue: {},

    loading: false,
    loadingMessage: '',
    service: new AdvertisementsService()
  }),

  methods: {
    getUser,
    requiredV2,
    required,
    getBannerPage,
    getBannerPosition,
    getBannerAction,

    async loadBanners() {
      let data = await this.service.fetchAll();
      data = data.map((item) => {
        const stats = this.countClicks(item.placements);
        return { ...item, stats };
      });
      return data;
    },

    async deleteHandler(item) {
      return this.service.delete(item.id);
    },

    countClicks(items) {
      // Initialize counts for each type of click
      let iosClickCount = 0;
      let androidClickCount = 0;
      let webClickCount = 0;
      let totalClickCount = 0;

      // Iterate through each item and update the counts
      items.forEach((item) => {
        iosClickCount += item.ios_click_count;
        androidClickCount += item.android_click_count;
        webClickCount += item.web_click_count;
        totalClickCount +=
          item.ios_click_count +
          item.android_click_count +
          item.web_click_count;
      });

      // Return an object containing the counts for each type of click
      return {
        iosClickCount,
        androidClickCount,
        webClickCount,
        totalClickCount
      };
    }
  }
};
</script>

<style scoped></style>
