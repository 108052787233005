import axios from 'axios';

export class MediaGalleryService {
  async paginateWithSelectedData(
    isActive = true,
    selectedData,
    limit,
    currentPage,
    search,
    filterQuery
  ) {
    let url = `/media-gallery/filter${isActive ? '' : '/inactive'}?sort=true${
      limit ? `&limit=${limit}` : ''
    }${currentPage ? `&page=${currentPage}` : ''}${
      search ? `&search=${search}` : ''
    }${filterQuery ? filterQuery : ''}`;
    return (
      await axios.post(url, {
        media: selectedData
      })
    ).data;
  }

  async paginate(isActive = true, limit, currentPage, search, filterQuery) {
    let url = `/media-gallery/filter${isActive ? '' : '/inactive'}?sort=true${
      limit ? `&limit=${limit}` : ''
    }${currentPage ? `&page=${currentPage}` : ''}${
      search ? `&search=${search}` : ''
    }${filterQuery ? filterQuery : ''}`;
    return (await axios.get(url)).data;
  }

  async fetchMediaByUrl(data) {
    return (await axios.post('/media-gallery/urls', data)).data;
  }

  async fetchMediaByIds(data) {
    return (await axios.post('/media-gallery/urls?ids=true', data)).data;
  }

  async create(media) {
    return (await axios.post('/media-gallery', media)).data;
  }

  async update(media) {
    return (await axios.patch('/media-gallery', media)).data;
  }

  async delete(id) {
    return (await axios.delete('/media-gallery/' + id)).data;
  }
}
