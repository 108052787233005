<template>
  <v-card>
    <v-tabs class="mb-4" v-model="tab" color="primary">
      <v-tab v-for="(type, index) in types" :key="index">
        {{ type }}
      </v-tab>
    </v-tabs>

    <data-table
      :loader="loadData"
      :headers="headers"
      title="Categories"
      :allow-add="true"
      @add-new="addNew"
      @done="$router.back()"
      :delete-handler="null"
      :edit-handler="
        getUser()
          ? getUser().scopes.includes('categories:edit')
            ? edit
            : null
          : null
      "
      :key="tab"
    >
      <template v-slot:subCategories="{ item }">
        <span v-if="item.subCategories && item.subCategories.length > 0">
          {{ item.subCategories.map((e) => e.name).join(', ') }}
        </span>
        <span v-else style="color: #aaa; font-style: italic">
          No Sub Categories
        </span>
      </template>

      <template v-slot:restrictedModes="{ item }">
        <div v-if="item.restrictedModes && item.restrictedModes.length > 0">
          <v-chip
            v-for="(mode, index) in item.restrictedModes"
            :key="index"
            color="primary"
            outlined
            small
            class="ma-1"
          >
            {{ mode }}
          </v-chip>
        </div>

        <v-chip v-else color="primary" outlined small class="ma-1">
          No Restricted Modes
        </v-chip>
      </template>

      <template v-slot:isMultiplayer="{ item }">
        <v-icon v-if="item.isMultiplayer" color="green" small>
          mdi-check-bold
        </v-icon>
        <v-icon v-else color="red" small> mdi-close </v-icon>
      </template>
    </data-table>
  </v-card>
</template>

<script>
import DataTable from '../../components/DataTable';
import { CategoryService } from '@/services/category-service';
import { getUser, restrictionGameModes } from '@/utils/local';

export default {
  components: { DataTable },

  data: () => ({
    loading: false,
    tab: null,
    types: ['Active', 'Inactive'],
    service: new CategoryService(),
    restrictionGameModes,

    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'Sub Categories',
        value: 'subCategories',
        sortable: false
      },
      {
        text: 'Restricted Game Modes',
        value: 'restrictedModes',
        sortable: false
      },
      {
        text: 'Multiplayer',
        value: 'isMultiplayer',
        sortable: false
      }
    ]
  }),

  methods: {
    getUser,
    addNew() {
      this.$router.push('/category');
    },
    edit(item) {
      this.$router.push(`/category?id=${item.id}`);
    },
    async loadData() {
      if (this.tab === 0) {
        let data = await this.service.fetchAll();

        data = data.map((data) => {
          return {
            ...data,
            restrictedModes: restrictionGameModes
              .filter((mode) => data.included_in?.includes(mode.value))
              .map((mode) => mode.text)
          };
        });

        return data;
      }

      if (this.tab === 1) {
        let data = await this.service.getFalseStatus();

        data = data.map((data) => {
          return {
            ...data,
            restrictedModes: restrictionGameModes
              .filter((mode) => data.included_in?.includes(mode.value))
              .map((mode) => mode.text)
          };
        });

        return data;
      }
    }
  }
};
</script>
