<template>
  <v-card :class="[hasElevation ? 'elevation-2' : 'elevation-0']">
    <v-card-title
      class="d-flex flex-column flex-md-row justify-start align-start"
      :style="$vuetify.breakpoint.mdAndUp ? '' : 'gap: 10px'"
    >
      <span class="data-table__header">{{ title }}</span>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />

      <div
        v-if="showSearch"
        style="width: 400px; margin-right: 20px; max-width: 100%"
      >
        <!-- search icon -->
        <v-text-field
          v-model="search"
          label="Search anything..."
          dense
          clearable
          outlined
          append-icon="mdi-magnify"
          :disabled="loading"
        />
      </div>
      <slot name="primary-action" />
      <v-btn
        v-if="allowAdd"
        color="primary"
        elevation="0"
        @click="$emit('add-new')"
      >
        <v-icon class="v-btn__pre-icon" small>mdi-plus</v-icon>&nbsp; Add New
      </v-btn>
      <v-btn
        v-if="allowRefresh"
        id="refresh"
        class="refresh"
        icon
        style="margin-left: 15px"
        @click="loadData"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <v-btn
        id="searchDataReload"
        class="refresh"
        icon
        style="margin-left: 15px; display: none"
        @click="searchDataReload"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <v-btn v-if="allowFilters" icon style="margin-left: 10px">
        <v-icon @click="$emit('filter')">mdi-filter</v-icon>
      </v-btn>
    </v-card-title>

    <v-data-table
      :loading="loading"
      :items="customSearch ? tempItems : items"
      :headers="headersValue"
      :search="search"
      :disable-filtering="customSearch"
      height="calc(100vh - 270px)"
      :hide-default-footer="!defaultFooter"
      @update:options="$emit('custom-sort', $event)"
    >
      <template v-slot:item="{ item }">
        <tr v-if="$vuetify.breakpoint.smAndUp" @click="onRowClick(item)">
          <td
            v-for="(elem, key) of headers"
            :key="key"
            :class="`text-${elem.align === 'right' ? 'end' : 'start'}`"
          >
            <slot :name="elem.value" :item="item">{{ item[elem.value] }}</slot>
          </td>

          <td
            v-if="viewHandler || editHandler || deleteHandler"
            style="max-width: 300px; min-width: 80px; text-align: right"
          >
            <slot name="extra-actions" :item="item" />

            <v-icon
              v-if="viewHandler"
              small
              @click="viewHandler(item)"
              title="View Details"
            >
              mdi-eye
            </v-icon>
            <v-icon
              v-if="editHandler"
              small
              @click="editHandler(item)"
              color="green"
              title="Edit"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="deleteHandler"
              small
              @click="onDelete(item)"
              color="red"
              title="Delete"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
        <tr
          v-else
          class="v-data-table__mobile-table-row"
          @click="onRowClick(item)"
        >
          <td
            v-for="(elem, key) of headers"
            :key="key"
            :class="`v-data-table__mobile-row text-${
              elem.align === 'right' ? 'end' : 'start'
            }`"
          >
            <div class="v-data-table__mobile-row__header">{{ elem.text }}</div>
            <div class="v-data-table__mobile-row__cell">
              <slot :name="elem.value" :item="item"
                >{{ item[elem.value] }}
              </slot>
            </div>
          </td>

          <td
            v-if="viewHandler || editHandler || deleteHandler"
            class="v-data-table__mobile-row text-end"
            style="text-align: right"
          >
            <div class="v-data-table__mobile-row__header">Action</div>
            <div class="v-data-table__mobile-row__cell">
              <slot name="extra-actions" :item="item" />

              <v-icon
                v-if="viewHandler"
                small
                @click="viewHandler(item)"
                title="View Details"
              >
                mdi-eye
              </v-icon>
              <v-icon
                v-if="editHandler"
                small
                color="green"
                @click="editHandler(item)"
                title="Edit"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="deleteHandler"
                small
                color="red"
                @click="onDelete(item)"
                title="Delete"
              >
                mdi-delete
              </v-icon>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:footer>
        <slot name="custom-footer" />
      </template>
    </v-data-table>

    <error-dialog v-model="error" :error="errorValue" />
  </v-card>
</template>

<script>
import ErrorDialog from './ErrorDialog';

export default {
  name: 'DataTable',
  components: { ErrorDialog },
  props: {
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowRefresh: {
      type: Boolean,
      default: true
    },
    defaultFooter: {
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    },

    allowFilters: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: null
    },

    loader: {
      type: Function,
      required: true
    },

    headers: {
      type: Array,
      required: true
    },

    editHandler: {
      type: Function,
      default: null
    },

    viewHandler: {
      type: Function,
      default: null
    },

    deleteHandler: {
      type: Function,
      default: null
    },
    deleteMessage: {
      type: String,
      default: 'This item will be deleted. Are you sure?'
    },

    hasElevation: {
      type: Boolean,
      default: true
    },
    customSearch: {
      type: Boolean,
      default: false
    }
  },

  emits: ['add-new'],

  mounted() {
    this.headersValue = [...this.headers];
    if (this.editHandler || this.deleteHandler || this.viewHandler) {
      this.headersValue.push({
        text: 'Actions',
        align: 'right',
        search: false,
        sortable: false
      });
    }

    this.loadData();
  },

  data: () => ({
    search: '',
    items: [],
    tempItems: [],
    partialNameIndex: {},
    error: false,
    loading: false,
    errorValue: {},
    headersValue: []
  }),

  watch: {
    search(current) {
      if (this.customSearch) {
        if (current) {
          this.searchAllData();
        } else {
          this.tempItems = this.items;
        }
      }
    }
  },

  methods: {
    async loadData() {
      this.loading = true;
      try {
        this.items = await this.loader();
        if (this.customSearch) {
          this.tempItems = this.items;
          this.partialNameIndex = this.createPartialNameIndex(this.items);
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.errorValue = {
          title: 'Error while loading data',
          description: e?.response?.data?.error ?? 'Some Error occurred'
        };
        this.error = true;
      }
    },

    async searchDataReload() {
      this.loading = true;
      try {
        this.items = await this.loader(true);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.errorValue = {
          title: 'Error while loading data',
          description: e?.response?.data?.error ?? 'Some Error occurred'
        };
        this.error = true;
      }
    },

    async onDelete(item) {
      if (confirm(this.deleteMessage)) {
        try {
          this.loading = true;
          await this.deleteHandler(item);
          this.loading = false;
          this.items.splice(this.items.indexOf(item), 1);
        } catch (e) {
          window.console.log(e);
        }
      }
    },

    onRowClick(item) {
      this.$emit('click:row', item);
    },

    // performAction(callback) {
    //   return callback({
    //     changeLoadingStatus: (status) => (this.loading = status)
    //   });
    // },

    createPartialNameIndex(arr) {
      const partialNameIndex = {};
      arr.forEach((item) => {
        const lowercaseName = item.name.toLowerCase();
        for (let i = 1; i <= lowercaseName.length; i++) {
          const partialName = lowercaseName.slice(0, i);
          if (!partialNameIndex[partialName]) {
            partialNameIndex[partialName] = [];
          }
          partialNameIndex[partialName].push(item);
        }
      });
      return partialNameIndex;
    },

    searchAllData() {
      const lowercasePartialName = this.search.toLowerCase();
      this.tempItems = this.partialNameIndex[lowercasePartialName] || [];
    }
  }
};
</script>

<style lang="sass" scoped>
.data-table
  &__header
    font-size: 25px
    font-family: google-sans, sans-serif
</style>
