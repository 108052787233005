import axios from 'axios';

export class WebAppOfferService {
  async fetchAllCoupon() {
    return (await axios.get('/stripe/coupons')).data;
  }

  async fetchAllPackages() {
    return (await axios.get('/stripe/products')).data;
  }

  async createCoupon(coupon) {
    return (await axios.post('/stripe/coupons', coupon)).data;
  }

  async updateCoupon(id, coupon) {
    return (await axios.post('/stripe/coupons/' + id, coupon)).data;
  }

  async deleteCoupon(id) {
    return (await axios.delete('/stripe/coupons/' + id)).data;
  }

  async fetchAllPrice() {
    return (await axios.get('/stripe/prices')).data;
  }

  async fetchPackagesPrices(id) {
    return (await axios.get('/stripe/products/prices/' + id)).data;
  }

  async updatePrice(id, price) {
    return (await axios.post('/stripe/prices/' + id, price)).data;
  }

  async applyCouponToPrice(id, coupon) {
    return (await axios.post('/stripe/prices/' + id + '/metadata', coupon))
      .data;
  }
}
