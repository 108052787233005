import axios from 'axios';

export class DecksService {
    async fetchAll() {
        return (await axios.get('/decks/admin-dashboard')).data;
    }

    async fetchOne(id) {
        return (await axios.get('/decks/' + id)).data;
    }

    async create(deck) {
        return (await axios.post('/decks', deck)).data;
    }

    async delete(deck) {
        return (await axios.delete('/flashcards/deck/' + deck.id)).data;
    }

    async update(deck) {
        return (await axios.patch('/decks', deck)).data;
    }
}
