import { getUser } from '@/utils/local';
import VideoSectionView from '@/views/video_session/VideoSessionView.vue';
import VideoSessionForm from '@/views/video_session/VideoSessionForm.vue';
import VideoSectionModal from '@/views/video_session/VideoSectionModal.vue';

export const videoSessionRoutes = [
  getUser()?.scopes.includes('video:view')
    ? {
        name: 'VideoSectionModal',
        path: '/video-section-modal',
        component: VideoSectionModal
      }
    : null,
  getUser()?.scopes.includes('video:view')
    ? {
        name: 'VideoSectionView',
        path: '/video-sections',
        component: VideoSectionView
      }
    : null,
  getUser()?.scopes.includes('video:edit')
    ? {
        name: 'VideoSectionFrom',
        path: '/video-section',
        component: VideoSessionForm
      }
    : null
];

export const videoSessionRouter = videoSessionRoutes.filter(function (x) {
  return x !== null;
});
