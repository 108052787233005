<template>
  <div>
    <div class="wrapper">
      <img src="../assets/logo.png" alt="logo" class="logo" />

      <div class="links">
        <a href="https://nclex-admin.kingofthecurve.org/" target="blank">
          <v-btn color="primary" elevation="0" outlined>
            NCLEX Admin
            <v-icon small class="ml-1">mdi-open-in-new</v-icon>
          </v-btn>
        </a>

        <a
          href="https://console.firebase.google.com/project/kings-of-the-curve-sonal/"
          target="blank"
        >
          <v-btn color="primary" elevation="0" outlined>
            Firebase Console <v-icon small class="ml-1">mdi-open-in-new</v-icon>
          </v-btn>
        </a>

        <a href="https://dashboard.branch.io/" target="blank">
          <v-btn color="primary" elevation="0" outlined>
            Branch: Dashboard
            <v-icon small class="ml-1">mdi-open-in-new</v-icon>
          </v-btn>
        </a>

        <a href="https://customer.io/" target="blank">
          <v-btn color="primary" elevation="0" outlined>
            Customer.io
            <v-icon small class="ml-1">mdi-open-in-new</v-icon>
          </v-btn>
        </a>

        <a href="https://dat-admin.kingofthecurve.org/" target="blank">
          <v-btn color="primary" elevation="0" outlined>
            DAT Admin <v-icon small class="ml-1">mdi-open-in-new</v-icon>
          </v-btn>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

img {
  height: 20vh;
  border-radius: 20px;
}

.links {
  margin-top: 250px !important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media (max-width: 600px) {
  .links {
    margin-top: 30%;
  }
}
</style>
