import View from './View';
import Form from './Form';
import LeaderboardView from './LeaderboardView.vue';
import { getUser } from '@/utils/local';

const studentGroupsRoutes = [
  getUser()?.scopes.includes('studentGroups:view')
    ? {
        name: 'StudentGroups',
        path: '/student-groups',
        component: View
      }
    : null,
  getUser()?.scopes.includes('studentGroups:view')
    ? {
        name: 'LeaderboardView',
        path: '/student-groups/leaderboard/:id',
        component: LeaderboardView
      }
    : null,
  getUser()?.scopes.includes('studentGroups:new') ||
  getUser()?.scopes.includes('studentGroups:edit')
    ? {
        name: 'NewStudentGroup',
        path: '/student-group',
        component: Form
      }
    : null
];

export const studentGroupsRouter = studentGroupsRoutes.filter(function (x) {
  return x !== null;
});
