<template>
  <div>
    <div class="flashcard">
      <p class="flashcard__title span-2">
        {{ isEdit ? 'Edit Flashcard' : 'New Flashcards' }}
      </p>
      <v-card v-if="flagFlashcards" class="span-1 mb-5 pa-4">
        <v-card-title>Flag Reason</v-card-title>
        <p class="body-1 px-4">{{ flagFlashcards.reason }}</p>
        <div></div>
      </v-card>
      <v-card
        class="mb-4 pa-3 d-flex overflow-x-auto span-2"
        color="grey lighten-2"
        elevation="0"
        min-height="500px"
        ref="cardsSection"
      >
        <Card
          v-for="(card, index) in cards"
          :key="index"
          :data="card"
          :loadingCategories="loadingCategories"
          :categories="categories"
          @addTextField="addTextField(index)"
          @addImage="addImage(index)"
          @addVideo="addVideo(index)"
          @duplicateSlide="duplicateCard(index)"
          @moveLeft="moveLeft(index)"
          @removeLastSection="removeLastSection(index)"
          @deleteSlide="deleteCard(index)"
        />

        <div v-if="!isEdit" class="d-flex items-center">
          <v-btn
            @click="addCard"
            icon
            fab
            elevation="0"
            class="pa-2 mr-3"
            plain
            style="background-color: white"
          >
            <v-icon size="26"> mdi-plus </v-icon>
          </v-btn>
        </div>
      </v-card>

      <v-checkbox
        v-if="!isEdit"
        v-model="isActiveCategory"
        label="Is this flashcard for active category?"
        class="span-2 mb-4"
        hide-details
        @change="loadCategories"
      ></v-checkbox>

      <div class="d-flex justify-end span-2">
        <v-btn
          v-if="!isEdit"
          @click="openSaveToChapterModal"
          color="primary"
          outlined
          class="mr-4"
          >Save in Chapter</v-btn
        >
        <v-btn @click="saveFlashcards" color="primary" elevation="0"
          >Save</v-btn
        >
      </div>
    </div>
    <MediaPicker
      allowedType="image"
      :selectedData="selectedData"
      @SetNewSelectedData="setSelectedImage"
    />
    <MediaPicker
      id="mediaPickerBtn1"
      allowedType="video"
      :selectedData="selectedData"
      @SetNewSelectedData="setSelectedVideo"
    />

    <v-dialog v-model="openChapterModal" width="470">
      <v-card class="pa-4 text-center">
        <p class="span-2 form__title">
          {{ isEdit ? 'Update Institution' : 'Save to Chapter' }}
        </p>
        <v-autocomplete
          v-model="selectedChapter"
          label="Select Chapter"
          item-text="name"
          item-value="id"
          :items="chapters"
          :loading="loadingChapters"
          :disabled="isEdit"
          class="span-2 mb-4"
          :rules="[required('Select a chapter')]"
          outlined
          dense
          hide-details
        />
        <v-btn
          @click="saveFlashcardsToChapter"
          color="primary"
          dark
          :disabled="!selectedChapter"
          >Save in Chapter
        </v-btn>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loadingDialog" :message="loaderMessage" />
    <error-dialog v-model="error" :error="errorVal" />
  </div>
</template>

<script>
import Card from '@/components/flashcard/Card.vue';
import MediaPicker from '@/components/media/MediaPicker.vue';
import { RoadmapModeService } from '@/services/roadmap-mode-service';
import { MediaGalleryService } from '@/services/media-gallery-service';
import LoadingDialog from '@/components/LoadingDialog.vue';
import { CategoryService } from '@/services/category-service';
import ErrorDialog from '@/components/ErrorDialog.vue';
import { required } from '@/utils/validators';

export default {
  name: 'FlashcardForm',

  components: {
    Card,
    MediaPicker,
    LoadingDialog,
    ErrorDialog
  },

  data: () => ({
    service: new RoadmapModeService(),
    mediaService: new MediaGalleryService(),
    categoryService: new CategoryService(),
    isActiveCategory: true,
    categories: [],
    chapters: [],
    loadingCategories: false,
    loadingChapters: false,
    cards: [
      {
        side: 'front',
        name: 'Flashcard 1',
        content: [
          {
            type: 'text',
            value: ''
          }
        ]
      },
      {
        side: 'back',
        category: '',
        content: [
          {
            type: 'text',
            value: ''
          }
        ]
      }
    ],
    selectedIndex: 0,
    selectedData: {
      images: [],
      videos: [],
      pdfs: []
    },
    flashcard: null,
    selectedChapter: null,
    loadingDialog: false,
    loaderMessage: 'Loading ...',
    openChapterModal: false,
    error: false,
    errorVal: {},
    isEdit: false,
    flagFlashcards: null
  }),

  async mounted() {
    if (this.$route.query?.isActiveCategory === 'false') {
      this.isActiveCategory = false;
    }
    await this.loadCategories();

    if (this.$route.query.id) {
      this.isEdit = true;
      this.loaderMessage = 'Loading flashcard ...';
      this.loadingDialog = true;
      this.flashcard = await this.service.fetchOneFlashcard(
        this.$route.query.id
      );
      await this.setFlashcard(this.flashcard);
      if (this.$route.query.reason) {
        this.flagFlashcards = { reason: this.$route.query.reason };
      }
      this.loadingDialog = false;
    }
  },

  methods: {
    required,
    async loadCategories() {
      this.loadingCategories = true;
      this.categories = this.isActiveCategory
        ? await this.categoryService.fetchAll()
        : await this.categoryService.fetchAllInactive();
      this.loadingCategories = false;
    },
    async saveFlashcards() {
      let data = null;
      let flashcards = null;

      if (this.isEdit) {
        data = await this.constructObjectForSingleFlashcard();
      } else {
        data = await this.constructObject();
      }

      if (!this.checkAllFlashcardsHaveCategory()) {
        this.errorVal = {
          title: 'Error',
          description: 'All flashcards must have a category'
        };
        this.error = true;
        return;
      }

      if (!this.checkNoFlashCardIsWithEmptyData()) {
        this.errorVal = {
          title: 'Error',
          description: "You can't save flashcard with empty data"
        };
        this.error = true;
        return;
      }

      this.loadingDialog = true;
      if (this.isEdit) {
        this.loaderMessage = 'Updating flashcard ...';
        delete data.id;
        data._id = this.flashcard._id;
        data.timestamped = this.flashcard.timestamped;
        await this.service.updateFlashcard(data);
      } else {
        this.loaderMessage = 'Creating flashcard ...';
        flashcards = await this.service.createMultipleFlashcard({ data });
      }

      // If we edit flashcard from the chapter, we need to close the tab
      const closeTab = this.$route?.query?.close_tab;
      const reason = this.$route?.query?.reason;

      if (closeTab) {
        window.close();
      } else {
        if (reason) {
          await this.$router.push(`/roadmap-flagged-questions`);
        } else {
          await this.$router.push('/roadmap-flashcards');
        }
        this.loadingDialog = false;
        return flashcards;
      }
    },

    async saveFlashcardsToChapter() {
      const flashcards = await this.saveFlashcards();
      const chapter = await this.service.fetchOne(this.selectedChapter);
      chapter.flashcards = [...chapter.flashcards, ...flashcards.ids];
      await this.service.update(chapter);
    },

    async openSaveToChapterModal() {
      if (!this.checkAllFlashcardsHaveCategory()) {
        this.errorVal = {
          title: 'Error',
          description: 'All flashcards must have a category'
        };
        this.error = true;
        return;
      }

      if (!this.checkAllFlashcardsHaveSameCategory()) {
        this.errorVal = {
          title: 'Error',
          description: 'All flashcards must have same category'
        };
        this.error = true;
        return;
      }

      this.openChapterModal = true;
      const query = {
        category: this.cards[1].category
      };
      this.chapters = await this.service.fetchAll(query);
    },

    addTextField(index) {
      this.cards[index].content.push({
        type: 'text',
        value: ''
      });
    },

    setSelectedImage(data) {
      data.images.forEach((image) => {
        this.cards[this.selectedIndex].content.push({
          type: 'image',
          value: image
        });
      });

      // Reset selected data
      this.selectedData = {
        images: [],
        videos: [],
        pdfs: []
      };
    },

    setSelectedVideo(data) {
      data.videos.forEach((video) => {
        this.cards[this.selectedIndex].content.push({
          type: 'video',
          value: video
        });
      });

      // Reset selected data
      this.selectedData = {
        images: [],
        videos: [],
        pdfs: []
      };
    },

    addImage(index) {
      this.selectedIndex = index;
      document.getElementById('mediaPickerBtn').click();
    },

    addVideo(index) {
      this.selectedIndex = index;
      document.getElementById('mediaPickerBtn1').click();
    },

    duplicateCard(index) {
      if (index % 2 === 0) {
        let newCards = JSON.parse(
          JSON.stringify(this.cards.slice(index, index + 2))
        );
        this.cards.splice(index + 2, 0, newCards[0], newCards[1]);
      } else {
        let newCards = JSON.parse(
          JSON.stringify(this.cards.slice(index - 1, index + 1))
        );
        this.cards.splice(index + 1, 0, newCards[0], newCards[1]);
      }
    },

    moveLeft(index) {
      if (index > 1) {
        if (index % 2 === 0) {
          let cards = this.cards.slice(index, index + 2);
          this.cards.splice(index - 2, 0, cards[0], cards[1]);
          this.cards.splice(index + 2, 2);
        } else {
          let cards = this.cards.slice(index - 1, index + 1);
          this.cards.splice(index - 3, 0, cards[0], cards[1]);
          this.cards.splice(index + 1, 2);
        }
      }
    },

    removeLastSection(index) {
      if (this.cards[index].content.length > 0) {
        this.cards[index].content.pop();
      }
    },

    deleteCard(index) {
      if (this.cards.length > 2) {
        if (index % 2 === 0) {
          this.cards.splice(index, 2);
        } else {
          this.cards.splice(index - 1, 2);
        }
      }
    },

    addCard() {
      this.cards = this.cards.concat([
        {
          side: 'front',
          name: 'Flashcard ' + (this.cards.length / 2 + 1).toString(),
          content: [
            {
              type: 'text',
              value: ''
            }
          ]
        },
        {
          side: 'back',
          category: '',
          content: [
            {
              type: 'text',
              value: ''
            }
          ]
        }
      ]);

      this.scrollToEnd();
    },

    scrollToEnd() {
      setTimeout(() => {
        this.$refs.cardsSection.$el.scroll({
          left: this.$refs.cardsSection.$el.scrollWidth,
          behavior: 'smooth'
        });
      }, 50);
    },

    async constructObject() {
      let urls = [];
      this.cards.forEach((card) => {
        card.content.forEach((section) => {
          if (section.type === 'image' || section.type === 'video') {
            urls.push(section.value);
          }
        });
      });

      let media = await this.mediaService.fetchMediaByUrl({ urls });

      let contents = this.cards.map((card) => {
        return {
          side: card.side === 'front' ? 0 : 1,
          theory: card.content.map((section) => {
            return {
              text: section.type === 'text' ? section.value : '',
              media:
                section.type === 'image' || section.type === 'video'
                  ? media.find((item) => item.url === section.value).id
                  : null
            };
          })
        };
      });

      let flashcards = [];

      for (let i = 0; i < contents.length; i += 2) {
        flashcards.push({
          name: this.cards[i].name,
          category: this.cards[i + 1].category,
          content: [contents[i], contents[i + 1]]
        });
      }

      return flashcards;
    },

    async setFlashcard(flashcard) {
      let mediaIds = [];

      flashcard.content.forEach((item) => {
        item.theory.forEach((section) => {
          if (section.media) {
            mediaIds.push(section.media);
          }
        });
      });

      let media = await this.mediaService.fetchMediaByIds({ urls: mediaIds });

      this.cards = flashcard.content.map((item) => {
        return {
          side: item.side === 0 ? 'front' : 'back',
          name: flashcard.name,
          category: flashcard.category,
          content: item.theory.map((section) => {
            return {
              type: section.media
                ? media.find((media) => media.id === section.media)?.type === 1
                  ? 'image'
                  : 'video'
                : 'text',
              value: section.media
                ? media.find((media) => media.id === section.media)?.url
                : section.text
            };
          })
          // createdAt: flashcard.createdAt,
          // updatedAt: flashcard.updatedAt,
        };
      });
    },

    async constructObjectForSingleFlashcard() {
      let urls = [];
      this.cards.forEach((card) => {
        card.content.forEach((section) => {
          if (section.type === 'image' || section.type === 'video') {
            urls.push(section.value);
          }
        });
      });

      let media = await this.mediaService.fetchMediaByUrl({ urls });

      let contents = this.cards.map((card) => {
        return {
          side: card.side === 'front' ? 0 : 1,
          theory: card.content.map((section) => {
            return {
              text: section.type === 'text' ? section.value : '',
              media:
                section.type === 'image' || section.type === 'video'
                  ? media.find((item) => item.url === section.value).id
                  : null
            };
          })
        };
      });

      let flashcard = {};

      flashcard.id = this.$route.query.id;
      flashcard.name = this.cards[0].name;
      flashcard.category = this.cards[1].category;
      flashcard.content = [contents[0], contents[1]];

      // flashcard.createdAt = this.cards[0].createdAt;
      // flashcard.updatedAt = this.cards[0].updatedAt;

      return flashcard;
    },

    checkNoFlashCardIsWithEmptyData() {
      let flashcardsWithoutData = [];
      this.cards.forEach((card) => {
        const obj = JSON.parse(JSON.stringify(card));
        const filteredCards = obj.content.map((section) => {
          return section.value !== '';
        });
        if (filteredCards.every((item) => item === false)) {
          flashcardsWithoutData.push(obj);
        }
      });
      return flashcardsWithoutData.length === 0;
    },

    checkAllFlashcardsHaveCategory() {
      let flashcards = this.cards.filter((card) => card.side === 'back');
      let flashcardsWithoutCategory = flashcards.filter(
        (flashcard) => flashcard.category === ''
      );
      return flashcardsWithoutCategory.length === 0;
    },

    checkAllFlashcardsHaveSameCategory() {
      return this.cards
        .map((card) => {
          if (card?.category) {
            return card.category;
          } else {
            return null;
          }
        })
        .filter((item) => item !== null)
        .every((val, i, arr) => val === arr[0]);
    }
  }
};
</script>

<style scoped lang="scss">
.flashcard {
  display: grid;
  max-width: 100%;
  grid-column-gap: 20px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);

  &__title {
    padding-bottom: 15px;
    text-align: center !important;
    font-weight: normal !important;
    font-size: 27px;
    font-family: google-sans, sans-serif;
  }
}
</style>
