<template>
  <SimpleForm
    :onSubmit="submit"
    @done="$route.path == '/sub-category' ? $router.back() : (isCreated = true)"
    :disabled="loading"
  >
    <p class="span-2 form__title">
      {{ isEdit ? 'Update Sub Category' : 'Add a new Sub Category' }}
    </p>

    <v-img
      v-if="icon || subCategory.icon"
      :src="imgObjectUrl() ? imgObjectUrl() : subCategory.icon"
      class="span-2 mb-6"
      max-height="200px"
      contain
      @load="iconLoading = false"
    >
      <template v-slot:placeholder>
        <div class="w-full h-full d-flex justify-center align-center">
          <v-progress-circular
            v-if="iconLoading"
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
        </div>
      </template>
    </v-img>

    <v-file-input
      v-model="icon"
      accept="image/png, image/jpeg, image/bmp"
      class="span-2"
      outlined
      dense
      :label="
        subCategory.icon
          ? subCategory.icon.split('%2F').pop().split('?')[0]
          : 'Select Icon'
      "
      prepend-icon="mdi-image"
      placeholder="Select an icon"
    >
    </v-file-input>

    <v-text-field
      v-model="subCategory.name"
      dense
      label="Sub Category Name"
      :rules="[required('You must write a name')]"
      class="span-2"
      outlined
    />

    <v-card-text class="span-1">
      <p>Category Status:</p>
    </v-card-text>
    <v-checkbox
      v-model="subCategory.status"
      label="Active"
      class="span-1"
      color="primary"
      hide-details
    />

    <p v-if="isCreated" class="span-2 modified-message">
      <v-alert type="success" outlined elevation="0" icon="mdi-check">
        {{ isEdit ? 'Sub Category Updated' : 'Sub Category Created' }}
      </v-alert>
    </p>

    <loading-dialog v-model="loading" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';

import { required } from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';
import { SubCategoryService } from '@/services/subCategory-service';
import { storage } from '@/plugins/firebase';

export default {
  name: 'SubCategoryForm',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    iconLoading: true,
    isEdit: false,
    loading: false,
    service: new SubCategoryService(),
    isCreated: false,

    icon: null,
    oldIcon: null,

    subCategory: {
      name: '',
      icon: '',
      status: true
    }
  }),

  mounted() {
    this.loadSubCategory();
  },

  watch: {
    isCreated: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.isCreated = false;
          }, 3000);
        }
      }
    }
  },

  methods: {
    required,

    imgObjectUrl() {
      if (!this.icon) return null;
      return URL.createObjectURL(this.icon);
    },

    async loadSubCategory() {
      if (!this.$route.query.id || this.$route.path == '/category') return;
      this.isEdit = true;
      this.loading = true;
      this.subCategory = await this.service.fetchOne(this.$route.query.id);
      this.oldIcon = this.subCategory.icon;
      this.loading = false;
    },

    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Sub Category');
        try {
          if (this.icon) {
            const ref = storage
              .ref()
              .child(`/subcategory_icons/${this.subCategory.name}`);
            await ref.put(this.icon);
            this.subCategory.icon = await ref.getDownloadURL();
          }

          await this.service.update(this.subCategory);

          if (this.subCategory.icon && this.oldIcon) {
            const ref = storage.refFromURL(this.oldIcon);
            await ref.delete();
          }

          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while updating Sub Category',
            description: e.response?.data?.error
          });

          return false;
        }
      } else {
        context.changeLoadingMessage('Creating A New Sub Category');
        try {
          if (this.icon) {
            const ref = storage
              .ref()
              .child(`/subcategory_icons/${this.subCategory.name}`);
            await ref.put(this.icon);
            this.subCategory.icon = await ref.getDownloadURL();
          }

          await this.service.create(this.subCategory);
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating Sub Category',
            description: e.response?.data?.error
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
.modified-message >>> div {
  text-align: center;
}

p {
  font-weight: bold;
  text-align: left;
}

.file-pickers {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto;
}
</style>
