<template>
  <data-table
    :loader="loadData"
    :headers="headers"
    title="Passage Questions of the Day"
    :allow-add="false"
    @done="$router.back()"
    :delete-handler="null"
    :edit-handler="
      getUser()
        ? getUser().scopes.includes('scheduledQotd:edit')
          ? edit
          : null
        : null
    "
  >
    <template #day="{ item }">
      <span class="font-weight-black body-1">{{ item.day }}</span>
    </template>

    <template #heading="{ item }">
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="text-truncate"
            style="max-width: 300px"
          >
            {{ item.heading }}
          </div>
        </template>
        <span>{{ item.heading }}</span>
      </v-tooltip>
    </template>

    <!-- <template v-slot:passage="{ item }">
      {{ item.passage.substring(0, 100) }}
    </template> -->

    <template #passage="{ item }">
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="text-truncate"
            style="max-width: 300px"
          >
            {{ item.passage }}
          </div>
        </template>
        <span>{{ item.passage }}</span>
      </v-tooltip>
    </template>

    <template #updatedAt="{ item }">
      {{ formatDate(item.updatedAt) }}
    </template>
  </data-table>
</template>

<script>
import dayjs from 'dayjs';
import DataTable from '../../components/DataTable';
import { getDays, getUser } from '@/utils/local';
import { PassageQuestionsService } from '@/services/passage_questions-service';

export default {
  components: { DataTable },
  data: () => ({
    items: [],
    loading: false,
    service: new PassageQuestionsService(),

    headers: [
      {
        text: 'Day',
        value: 'day',
        sortable: false
      },

      {
        text: 'Date',
        value: 'updatedAt',
        sortable: true,
        width: 150
      },
      {
        text: 'Passage Title',
        value: 'heading',
        sortable: false
      },
      {
        text: 'Question Passage',
        value: 'passage',
        sortable: false
      }
    ]
  }),

  methods: {
    getUser,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY');
    },
    edit(item) {
      this.$router.push(
        `/passage-question-of-the-day?id=${item.id}&index=${item.index}`
      );
    },
    async loadData() {
      this.items = await this.service.fetchPassageQuestionsOfTheDay();
      const startDate = new Date(this.items.updatedAt);
      this.items.passages = this.items.passages.map((item, index) => {
        item.index = index;
        item.day = getDays(index + 1);
        const newDate = new Date(startDate);
        newDate.setDate(startDate.getDate() + index);
        item.updatedAt = newDate;
        return item;
      });

      return this.items.passages;
    }
  }
};
</script>

<style scoped></style>
