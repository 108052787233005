<template>
  <div>
    <data-table
        :loader="loadData"
        :headers="headers"
        title="Flagged Questions"
        @done="$router.back()"
        :allow-add="false"
        :delete-handler="
        getUser()
          ? getUser().scopes.includes('flagged-questions:delete')
            ? deleteItem
            : null
          : null
      "
        :edit-handler="
        getUser()
          ? getUser().scopes.includes('flagged-questions:edit')
            ? editQuestion
            : null
          : null
      "
    >
      <template #primary-action>
        <v-btn
            color="primary"
            elevation="0"
            @click="showEmailTemplate = true"
        >
          <v-icon class="v-btn__pre-icon" small>mdi-eye</v-icon>&nbsp; View Email Template
        </v-btn>
      </template>
      <!--    <template #extra-actions="{item}">-->
      <!--      <v-icon @click="changeValidStatus(true,item)" small color="green">mdi-check</v-icon>-->
      <!--      <v-icon @click="changeValidStatus(false,item)" small color="red">mdi-close</v-icon>-->
      <!--    </template>-->
      <template v-slot:username="{ item }">
        {{ item.user_data.name }}
      </template>
      <template v-slot:email="{ item }">
        {{ item.user_data.email }}
      </template>
      <template v-slot:reason="{ item }">
        {{ item.reason.split('_-_')[0] }}
      </template>
      <template v-slot:explanation="{ item }">
        {{ item.reason.split('_-_')[1] }}
      </template>
      <template v-slot:is_valid="{ item }">
        <v-checkbox v-model="item.is_valid" readonly></v-checkbox>
      </template>
      <template #timestamp="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template #extra-actions="{ item }">
        <v-icon
            small
            color="primary"
            @click="
            flagQuestionItem = item;
            emailDialog = true;
          "
            v-if="!item.is_resolved"
            title="Send Confirmation Email"
        >mdi-send
        </v-icon
        >
        <v-icon v-if="item.is_resolved" small color="green">mdi-check</v-icon>
      </template>
    </data-table>

    <v-dialog v-model="showEmailTemplate" max-width="600">
      <v-card class="pa-5">
        <v-card-text class="mb-n8 text-center">
          <div class="top-bar">&nbsp;</div>
          <img src="../../assets/emailLogo.png" alt="logo" height="100" class="mb-5">
          <div class="text-left">
            <p class="text-justify">
              Dear <strong>{name}</strong> <br/><br/>
              We would like to thank you for your valuable contribution to <strong>King Of The Curve</strong>.<br/><br/>
              <strong>Flagged Type:</strong> {flaggedType}<br/><br/>
              <strong>Reason:</strong> {reason} <br/><br/>
              <strong>{message}</strong><br/><br/>
              Please contact at this <a href="mailto:support@kingofthecurve.org">support@kingofthecurve.org</a>
              if you have anyother concerns. Good luck studying, future docs!
              <br/>
              <br/>
              For more information please visit <a href="https://kingofthecurve.org/privacy-policy">Privacy Policy</a>
              and <a href="https://kingofthecurve.org/terms-of-service">Terms of Service</a> of King of the Curve.
            </p>
          </div>
          <img
              src="https://hannoq.stripocdn.email/content/guids/CABINET_366a10fbe7f9d369d05574ccfabe4264/images/undraw_news_re_6uub.png"
              alt="" width="390" height="306">
          <p class="text-left mt-4">Cheers,<br/><strong>The KOTC Team</strong></p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="emailDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Send Email</v-card-title>
        <v-card-text class="mb-n8">
          <v-textarea
              v-model="flagQuestionItem.custom_message"
              label="Email Message"
              :rules="[(v) => !!v || 'Message is required']"
              outlined
              rows="5"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="emailDialog = false"
          >Close
          </v-btn
          >
          <v-btn
              color="primary"
              elevation="0"
              @click="sendEmail"
              :loading="emailLoading"
              :disabled="!flagQuestionItem || !flagQuestionItem.custom_message"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import axios from 'axios';
import {getUser} from '@/utils/local';
import dayjs from 'dayjs';

export default {
  components: {DataTable},

  data: () => ({
    loading: false,
    emailLoading: false,
    emailDialog: false,
    flagQuestionItem: {},
    showEmailTemplate: false,

    headers: [
      {
        text: 'User Name',
        value: 'username'
      },
      {
        text: 'User Email',
        value: 'email'
      },
      {
        text: 'Reason',
        value: 'reason'
      },
      {
        text: 'Explanation',
        value: 'explanation'
      },
      {
        text: 'Question',
        value: 'question_id',
        sortable: false
      },
      {
        width: 180,
        text: 'Timestamp',
        value: 'timestamp'
      }
      // {
      //   width: 50,
      //   text: 'Valid',
      //   value: 'is_valid',
      //   sortable: false
      // }
    ]
  }),

  methods: {
    getUser,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },
    async editQuestion(item) {
      if (item.user.token) {
        await this.$router.push(
            `/question?id=${item.question_id}&token=${item.user.token}&flag=${item.id}`
        );
      } else {
        await this.$router.push(
            `/question?id=${item.question_id}&flag=${item.id}`
        );
      }
    },
    async deleteItem(item) {
      return await axios.delete(`/flagged-questions/${item.id}`);
    },
    async loadData() {
      return (await axios.get('/flagged-questions')).data;
    },
    // async changeValidStatus(event, item) {
    //   if (event) {
    //     if (confirm('Are you sure')) {
    //       try {
    //         this.loading = true;
    //         item.is_valid = true;
    //         await axios.patch(`/flagged-questions/${item.id}`, item);
    //       } catch (e) {
    //         console.log(e);
    //       }
    //       this.loading = false;
    //     }
    //   } else {
    //     let reason;
    //     while (!reason && reason !== null) {
    //       reason = prompt('Reason');
    //     }
    //     if (reason) {
    //       try {
    //         this.loading = true;
    //         item.is_valid = false;
    //         item.invalid_reason = reason;
    //         await axios.patch(`/flagged-questions/${item.id}`, item);
    //       } catch (e) {
    //         console.log(e);
    //       }
    //       this.loading = false;
    //     }
    //   }
    // },

    async sendEmail() {
      if (this.flagQuestionItem && this.flagQuestionItem.custom_message) {
        try {
          this.emailLoading = true;
          this.flagQuestionItem.is_resolved = true;

          await axios.patch(
              `/flagged-questions/${this.flagQuestionItem.id}`,
              this.flagQuestionItem
          );

          this.flagQuestionItem = {};
          this.emailLoading = false;
          this.emailDialog = false;
          this.$toast.success('Email Sent');
        } catch (e) {
          this.emailLoading = false;
          this.$toast.error('Error while sending email');
          console.log(e);
        }
        this.loading = false;
      }
    }
  }
};
</script>
<style scoped>
.top-bar {
  height: 60px;
  background-color: blue;
  margin-bottom: 3rem;
}
</style>