<template>
  <SimpleForm
    :onSubmit="submit"
    @done="$router.back()"
    :disabled="
      loading || !passageQuestion || loadingQuestions || loadingCategories
    "
  >
    <p class="span-2 form__title">Update Passage Question Of The Day</p>

    <div class="span-2">
      <h3 class="mb-2">Current Passage</h3>
    </div>

    <v-text-field
      v-model="currentPassage"
      dense
      label="Question Title"
      :rules="[required('You must write a question title')]"
      class="span-2"
      outlined
      disabled
    />

    <div class="span-2">
      <h3 class="mb-2">Replace Passage</h3>
    </div>
    <v-autocomplete
      v-model="passageQuestion"
      :items="allPassages"
      :loading="loadingPassages"
      :rules="[required('Select a passage')]"
      dense
      item-text="heading"
      item-value="id"
      label="Select Passage"
      outlined
      return-object
      class="span-2"
      :disabled="loadingPassages"
      @change="loadPassageQuestion"
    />

    <div class="span-2 mb-2">
      <h3 class="mb-2">Passage Details</h3>
    </div>

    <v-text-field
      v-model="passageQuestion.heading"
      dense
      label="Question Title"
      :rules="[required('You must write a question title')]"
      class="span-2"
      outlined
      disabled
    />

    <v-textarea
      v-model="passageQuestion.passage"
      dense
      :rules="[required('You must write a question passage')]"
      label="Question Passage"
      class="span-2"
      outlined
      no-resize
      disabled
    />

    <div class="span-2 mb-8">
      <v-checkbox
        v-model="passageQuestion.premium"
        dense
        label="Premium"
        hide-details
        disabled
      />
      <v-checkbox
        v-model="passageQuestion.active"
        dense
        label="Active"
        hide-details
        disabled
      />
    </div>

    <v-text-field
      v-if="passageQuestion.category"
      v-model="passageQuestion.category.name"
      dense
      label="Category"
      class="span-2"
      outlined
      disabled
    />

    <v-text-field
      v-if="passageQuestion.sub_category"
      v-model="passageQuestion.sub_category.name"
      dense
      label="Subcategory"
      class="span-2"
      outlined
      disabled
    />

    <div class="d-flex justify-space-between span-2">
      <h4>Questions</h4>
    </div>
    <div class="span-2 my-3">
      <div
        v-for="(question, i) of passageQuestion.questions"
        :key="i"
        class="span-2"
      >
        <v-autocomplete
          v-model="question.question"
          :label="'Select Question # ' + (i + 1)"
          item-text="statement"
          item-value="id"
          return-object
          :items="questions"
          :loading="loadingQuestions"
          class="span-2"
          :rules="[
            required('Select a question'),
            passageQuestionsValidator(question, passageQuestion.questions)
          ]"
          outlined
          dense
          disabled
        >
        </v-autocomplete>
      </div>
    </div>

    <div class="span-2 carousel-display">
      <h4
        class="mt-3 text-center font-weight-light"
        v-if="
          selectedData.images.length <= 0 &&
          selectedData.videos.length <= 0 &&
          selectedData.pdfs.length <= 0
        "
      >
        No Media Available
      </h4>

      <h2 class="mt-2 mb-3" v-if="selectedData.images.length === 1">Image</h2>
      <h2 class="mt-2 mb-3" v-if="selectedData.images.length > 1">Images</h2>
      <v-carousel
        :show-arrows="selectedData.images.length > 1"
        v-if="selectedData.images.length > 0"
        hide-delimiters
        style="width: 100%; height: fit-content"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item
          v-for="(item, i) in selectedData.images"
          :key="i"
          :src="item"
        >
        </v-carousel-item>
      </v-carousel>

      <h2 class="mt-2 mb-3" v-if="selectedData.videos.length === 1">Video</h2>
      <h2 class="mt-2 mb-3" v-if="selectedData.videos.length > 1">Videos</h2>
      <v-carousel
        :show-arrows="selectedData.videos.length > 1"
        v-if="selectedData.videos.length > 0"
        hide-delimiters
        style="width: 100%; height: fit-content"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item v-for="(item, i) in selectedData.videos" :key="i">
          <video
            style="width: 100%; height: 100%"
            width="auto"
            height="auto"
            :src="item"
            controls
          />
        </v-carousel-item>
      </v-carousel>

      <h2 class="mt-2 mb-3 float-start" v-if="selectedData.pdfs.length === 1">
        PDF
      </h2>
      <h2 class="mt-2 mb-3 float-start" v-if="selectedData.pdfs.length > 1">
        PDFs
      </h2>

      <a
        v-if="selectedData.pdfs.length > 0"
        :href="selectedData.pdfs[displayedPdfIndex]"
        class="text-center float-end text-decoration-none"
        target="_blank"
      >
        <v-btn small class="mt-3" elevation="0" color="black">
          <v-icon size="20" color="white"> mdi-arrow-expand-all </v-icon>
        </v-btn>
      </a>

      <v-carousel
        :show-arrows="selectedData.pdfs.length > 1"
        v-if="selectedData.pdfs.length > 0"
        @change="pdfChange"
        hide-delimiters
        style="width: 100%; height: fit-content"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item v-for="(item, i) in selectedData.pdfs" :key="i">
          <iframe style="width: 100%; height: 100%" :src="item" />
        </v-carousel-item>
      </v-carousel>
    </div>

    <loading-dialog v-model="loading" message="Loading Passage Question Data" />
    <v-dialog
      :width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'"
      v-model="showQuestionDialog"
    >
    </v-dialog>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';

import { required, passageQuestionsValidator } from '@/utils/validators';
import { QuestionsService } from '@/services/questions-service';
import LoadingDialog from '../../components/LoadingDialog';
import { PassageQuestionsService } from '@/services/passage_questions-service';
import { CategoryService } from '@/services/category-service';

export default {
  name: 'PassageQuestionFormComponent',
  components: { LoadingDialog, SimpleForm },
  data: () => ({
    currentPassage: '',
    displayedPdfIndex: null,
    showQuestionDialog: false,
    selectedData: {
      images: [],
      videos: [],
      pdfs: []
    },
    passageQuestion: {
      questions: [{}]
    },
    loading: false,
    questions: [],
    allPassages: [],
    loadingPassages: false,

    service: new PassageQuestionsService(),
    questionsService: new QuestionsService(),
    loadingQuestions: false,

    images: [],

    // Category & subcategory configure
    categories: [],
    subCategories: [],
    categoriesService: new CategoryService(),
    loadingCategories: false
  }),

  mounted() {
    this.loadPassageQuestion().then(() => {
      this.loadAllPassages();
      this.loadQuestions();
      this.loadCategories();

      this.currentPassage = this.passageQuestion.heading;
    });
  },

  methods: {
    required,
    passageQuestionsValidator,
    async loadCategories() {
      this.loadingCategories = true;
      this.categories = await this.categoriesService.fetchAll();
      for (const item of this.categories) {
        if (this.passageQuestion.category?.id === item.id) {
          this.subCategories = item.subCategories;
          break;
        }
      }
      this.loadingCategories = false;
    },

    async loadQuestions() {
      this.loadingQuestions = true;
      try {
        this.questions = await this.questionsService.getPassageQuestions();
      } catch (e) {
        window.console.log(e);
      }
      this.loadingQuestions = false;
    },

    async loadPassageQuestion() {
      if (!this.$route.query.id) return;
      this.loading = true;
      this.passageQuestion = await this.service.fetchOne(
        this.passageQuestion.id || this.$route.query.id
      );
      this.selectedData.images = this.passageQuestion.images;

      const questions = [];
      this.passageQuestion.questions.forEach((item) => {
        const obj = {
          question: {
            id: item
          }
        };
        questions.push(obj);
      });
      this.passageQuestion.questions = questions;
      this.loading = false;
    },

    async loadAllPassages() {
      this.loadingPassages = true;
      this.allPassages = await this.service.fetchAll({});
      this.allPassages = this.allPassages.filter((item) => item.active);
      this.loadingPassages = false;
    },

    async submit(context) {
      context.changeLoadingMessage('Updating Passage Question Of The Day');
      try {
        await this.service.updatePassageOfTheDay(
          this.$route.query.index,
          this.passageQuestion.id
        );
        return true;
      } catch (e) {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.modified-message >>> div {
  text-align: center;
}

p {
  font-weight: bold;
  text-align: left;
}

.file-pickers {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto;
}

.customEditor {
  background: #cccbc9;
  border-radius: 8px !important;
}
</style>

<style>
.ql-toolbar {
  border-bottom: 1px solid #fff !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.ql-container {
  border: none !important;
}

.image {
  width: 240px;
  height: 240px;
  object-fit: cover;
}

.fileIcon {
  border: 1px solid #4396e4;
}
</style>
