<template>
  <SimpleForm :onSubmit="submit">
    <p class="span-2 form__title">
      {{ isEdit ? 'Update Chapter' : 'Add a new Chapter' }}
    </p>

    <div class="d-flex justify-space-around span-2">
      <div class="d-flex flex-col items-center">
        <h4 class="mb-2">Active Icon</h4>
        <image-upload
          :hasError="showActiveImageError"
          :image_obj="old_image_active"
          @uploadedImage="getUploadedActiveImage"
        />
      </div>

      <div class="d-flex flex-col items-center">
        <h4 class="mb-2">Inactive Icon</h4>
        <image-upload
          :hasError="showInactiveImageError"
          :image_obj="old_image_inactive"
          @uploadedImage="getUploadedInactiveImage"
        />
      </div>
    </div>

    <v-checkbox
      v-model="isActiveCategoryChapter"
      label="Is chapter for active categories?"
      class="span-2"
      color="primary"
      @change="
        loadCategories();
        loadPassageQuestions();
        loadFlashcards();
        chapter.questions = [];
        items = [];
      "
      :disabled="isEdit"
    />

    <v-text-field
      class="span-2"
      v-model="chapter.title"
      outlined
      dense
      label="Title"
      :rules="[required()]"
    />

    <div v-if="!isEdit" class="d-flex justify-space-between span-2">
      <h4>Category</h4>
    </div>
    <div class="span-2 mt-3">
      <v-autocomplete
        v-model="chapter.category"
        label="Select Category"
        item-text="name"
        item-value="id"
        :items="categories"
        :loading="loadingCategories"
        :disabled="isEdit"
        class="span-2"
        :rules="[required('Select a category')]"
        outlined
        dense
      />
      <!-- @change="loadChaptersByCategory(chapter.category)" -->
    </div>

    <div class="mb-2">
      <v-checkbox v-model="chapter.active" dense label="Active" hide-details />
    </div>

    <div class="d-flex justify-space-between span-2">
      <h4>Flashcards</h4>
      <div>
        <v-btn elevation="0" color="primary" @click="addFlashcard">
          <v-icon small>mdi-plus</v-icon>
          Add
        </v-btn>
      </div>
    </div>
    <div class="span-2 my-3">
      <div
        v-for="(flashcard, i) of chapter.flashcards"
        :key="i"
        class="span-2 flex"
      >
        <v-autocomplete
          v-model="chapter.flashcards[i]"
          :label="'Select Flashcard # ' + (i + 1)"
          item-text="name"
          item-value="_id"
          :items="flashcards"
          :loading="loadingFlashcards"
          class="span-2"
          :rules="[required('Select a flashcard')]"
          outlined
          dense
          @click:append-outer="removeFlashcard(i)"
        >
          <template v-slot:append-outer>
            <v-icon
              @click="removeFlashcard(i)"
              v-if="chapter.flashcards.length > 1"
              color="red"
              >mdi-delete
            </v-icon>
            <v-icon
              class="ml-2"
              @click="editFlashcard(flashcard)"
              v-if="chapter.flashcards[i]"
              color="primary"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-autocomplete>
      </div>
    </div>

    <div class="d-flex justify-space-between span-2">
      <h4>Questions</h4>
      <div>
        <v-btn elevation="0" color="primary" @click="addQuestion">
          <v-icon small>mdi-plus</v-icon>
          Add
        </v-btn>
      </div>
    </div>
    <div class="span-2 my-3">
      <div v-for="(question, i) of chapter.questions" :key="i" class="span-2">
        <div class="d-flex" v-if="chapter.questions.length > 0">
          <v-text-field
            :value="question.statement"
            :label="'Select Question # ' + (i + 1)"
            outlined
            dense
            disabled
          ></v-text-field>
          <v-icon
            @click="removeQuestion(i)"
            v-if="chapter.questions.length > 1"
            color="red"
            class="mb-7 ml-2"
            >mdi-delete
          </v-icon>
        </div>
      </div>
    </div>

    <div class="d-flex justify-space-between span-2">
      <h4>Passage Questions</h4>
      <div>
        <v-btn elevation="0" color="primary" @click="addPassageQuestion">
          <v-icon small>mdi-plus</v-icon>
          Add
        </v-btn>
      </div>
    </div>
    <div class="span-2 my-3">
      <div
        v-for="(passageQuestion, i) of chapter.passageQuestion"
        :key="i"
        class="span-2"
      >
        <v-autocomplete
          v-model="chapter.passageQuestion[i]"
          :label="'Select Passage Question # ' + (i + 1)"
          item-text="heading"
          item-value="id"
          :items="passageQuestions"
          :loading="loadingPassageQuestions"
          class="span-2"
          :rules="[required('Select a passage question')]"
          outlined
          dense
          @click:append-outer="removePassageQuestion(i)"
        >
          <template v-slot:append-outer>
            <v-icon
              @click="removePassageQuestion(i)"
              v-if="chapter.passageQuestion.length > 1"
              color="red"
              >mdi-delete
            </v-icon>
          </template>
        </v-autocomplete>
      </div>
    </div>

    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      style="max-height: 100vh; overflow: hidden"
    >
      <v-card
        style="padding: 20px; display: flex; max-height: 90vh; overflow: hidden"
      >
        <div style="flex: 1" class="mr-3">
          <QuestionsView
            :isActiveCategory="isActiveCategoryChapter"
            :question-view-modal="true"
            @click:row="handleQuestionSelection"
          />
        </div>
        <v-card
          style="
            padding: 20px;
            width: 370px;
            max-height: 100%;
            overflow: hidden;
            position: relative;
          "
        >
          <v-list three-line max-height="100%" style="overflow-y: auto">
            <div class="d-flex items-center justify-space-between">
              <h3>Selected Questions</h3>
              <v-btn
                color="primary"
                elevation="0"
                @click="addSelectedQuestions"
              >
                Done
              </v-btn>
            </div>
            <div
              class="text-center"
              style="
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              "
            >
              <p v-if="items.length === 0">
                <span class="font-weight-bold">
                  Click on any question to select it for this chapter
                </span>
              </p>
            </div>
            <template v-for="(item, index) in items">
              <v-list-item :key="item.title">
                <template v-slot:default>
                  <v-list-item-content>
                    <v-list-item-title class="mb-1">
                      {{ item.category }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.statement }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-icon color="red" @click="removeSelectedQuestion(item)">
                      mdi-delete
                    </v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider
                v-if="index < items.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" :message="loadingMessage" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '@/components/Form';
import LoadingDialog from '@/components/LoadingDialog.vue';
import { PassageQuestionsService } from '@/services/passage_questions-service';
import { QuestionsService } from '@/services/questions-service';
import { RoadmapModeService } from '@/services/roadmap-mode-service';
import { required } from '@/utils/validators';
import ImageUpload from '@/components/ImageUpload.vue';
import { storage } from '@/plugins/firebase';
import { CategoryService } from '@/services/category-service';
import QuestionsView from '@/views/roadmap/chapters/components/QuestionsViewModal.vue';

export default {
  name: 'ChapterForm',

  components: {
    SimpleForm,
    LoadingDialog,
    ImageUpload,
    QuestionsView
  },

  data: () => ({
    isEdit: false,
    loading: false,
    loadingMessage: 'Loading Chapter...',
    showDialog: false,
    isActiveCategoryChapter: true,
    chapter: {
      title: '',
      category: '',
      flashcards: [''],
      questions: [''],
      passageQuestion: [''],
      active: false
    },

    activeImage: null,
    displayImageActive: null,
    showActiveImageError: false,

    inactiveImage: null,
    displayImageInactive: null,
    showInactiveImageError: false,

    disabled: false,

    questions: [],
    loadingQuestions: false,
    categories: [],
    passageQuestions: [],
    loadingPassageQuestions: false,
    loadingCategories: false,
    flashcards: [],
    loadingFlashcards: false,

    service: new RoadmapModeService(),
    categoryService: new CategoryService(),
    questionsService: new QuestionsService(),
    passageQuestionsService: new PassageQuestionsService(),

    selected: [],
    items: []
  }),

  computed: {
    old_image_active() {
      return {
        url: this.displayImageActive
      };
    },
    old_image_inactive() {
      return {
        url: this.displayImageInactive
      };
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    required,

    getUploadedActiveImage(_image_obj) {
      if (_image_obj) {
        this.activeImage = _image_obj.file;
        this.showActiveImageError = false;
        this.disabled = false;
      } else {
        this.showActiveImageError = true;
        this.disabled = true;
      }
    },

    getUploadedInactiveImage(_image_obj) {
      if (_image_obj) {
        this.inactiveImage = _image_obj.file;
        this.showInactiveImageError = false;
        this.disabled = false;
      } else {
        this.showInactiveImageError = true;
        this.disabled = true;
      }
    },

    async loadData() {
      if (this.$route.query?.isActiveCategory === 'false') {
        this.isActiveCategoryChapter = false;
      }
      const chapterId = this.$route.query.id;
      if (chapterId) {
        this.isEdit = true;
        this.loading = true;
        this.loadingMessage = 'Loading Chapter...';
        let chapter = await this.service.fetchOne(chapterId);

        this.chapter = {
          title: chapter.name,
          category: chapter.category,
          flashcards: chapter.flashcards || [],
          questions: chapter.questions || [],
          passageQuestion: chapter.passages || [],
          index: chapter.index,
          active: chapter.active,
          createdAt: chapter.createdAt,
          updatedAt: chapter.updatedAt
        };
        this.displayImageActive = chapter.icon.active;
        this.displayImageInactive = chapter.icon.inactive;
        await this.loadQuestionsByIds(this.chapter.category);
        this.loading = false;
      }

      this.loadCategories();
      this.loadPassageQuestions();
      this.loadFlashcards();

      if (!this.isEdit) {
        this.chapter.questions = [];
      }
    },

    async loadPassageQuestions() {
      this.loadingPassageQuestions = true;
      this.passageQuestions = await this.passageQuestionsService.fetchAll({
        inactiveCategory: this.isActiveCategoryChapter === true ? false : true
      });
      this.passageQuestions = this.passageQuestions.filter(
        (question) => question.active
      );
      this.loadingPassageQuestions = false;
    },

    async loadFlashcards() {
      this.loadingFlashcards = true;
      this.flashcards = this.isActiveCategoryChapter
        ? await this.service.fetchAllFlashcard()
        : await this.service.fetchAllFlashcard({ active: 0 });
      this.loadingFlashcards = false;
    },

    async loadQuestionsByIds() {
      this.loadingQuestions = true;
      this.chapter.questions = await this.questionsService.fetchQuestionByIds(
        this.chapter.questions
      );
      this.loadingQuestions = false;
    },

    // async loadChaptersByCategory(categoryId) {
    //   this.loadingQuestions = true;
    //   this.questions = await this.questionsService.fetchAllForCategory(
    //     categoryId
    //   );
    //   this.cha;
    //   this.loadingQuestions = false;
    // },

    async loadCategories() {
      this.loadingCategories = true;
      this.categories = this.isActiveCategoryChapter
        ? await this.categoryService.fetchAll()
        : await this.categoryService.fetchAllInactive();
      this.loadingCategories = false;
    },

    addQuestion() {
      this.showDialog = true;
      // this.chapter.questions.push('')
    },

    removeQuestion(i) {
      if (this.chapter.questions.length > 1) {
        this.chapter.questions.splice(i, 1);
      }
    },

    addFlashcard() {
      this.chapter.flashcards.push('');
    },

    editFlashcard(id) {
      const routeData = this.$router.resolve({
        path: '/roadmap-flashcard',
        query: { id: id, close_tab: 'true' }
      });
      window.open(routeData.href, '_blank');
    },

    removeFlashcard(i) {
      if (this.chapter.flashcards.length > 1) {
        this.chapter.flashcards.splice(i, 1);
      }
    },

    addPassageQuestion() {
      this.chapter.passageQuestion.push('');
    },

    removePassageQuestion(i) {
      if (this.chapter.passageQuestion.length > 1) {
        this.chapter.passageQuestion.splice(i, 1);
      }
    },

    async addSelectedQuestions() {
      this.showDialog = false;
      const questionIds = this.items.map((question) => question.id);
      this.chapter.questions = [
        ...new Set([
          ...this.chapter.questions.map((question) => question.id),
          ...questionIds
        ])
      ];
      await this.loadQuestionsByIds();
    },

    handleQuestionSelection(item) {
      if (!this.items.every((el) => el.id !== item.id)) {
        alert('Question is already selected');
        return;
      }
      this.items.push({
        id: item.id,
        category: item?.categoryName,
        subCategory: item?.subCategoryName,
        statement: item?.statement
      });
    },

    removeSelectedQuestion(item) {
      this.items = this.items.filter((question) => question.id !== item.id);
    },

    async submit(context) {
      try {
        if (!this.isEdit) {
          // validate Image
          if (!this.activeImage) {
            this.showActiveImageError = true;
            return false;
          }

          // validate Image
          if (!this.inactiveImage) {
            this.showInactiveImageError = true;
            return false;
          }

          context.changeLoadingMessage('Creating New Chapter');
        } else {
          context.changeLoadingMessage('Updating Chapter');
        }

        if (this.activeImage) {
          let fileName = this.activeImage.name;

          // Create custom file name
          fileName =
            fileName.split('.').slice(0, -1).join('') +
            '~' +
            new Date().getTime() +
            fileName.split('.').slice(-1)[0];

          // Upload File
          let reference = storage.ref('chapters/icon/' + fileName);
          await reference.put(this.activeImage);

          // Get download url from firebase storage and add to newChapter object
          this.displayImageActive = await storage
            .ref('chapters/icon/')
            .child(fileName)
            .getDownloadURL();
        }

        if (this.inactiveImage) {
          let fileName = this.inactiveImage.name;

          // Create custom file name
          fileName =
            fileName.split('.').slice(0, -1).join('') +
            '~' +
            new Date().getTime() +
            fileName.split('.').slice(-1)[0];

          // Upload File
          let reference = storage.ref('chapters/icon/' + fileName);
          await reference.put(this.inactiveImage);

          // Get download url from firebase storage and add to newChapter object
          this.displayImageInactive = await storage
            .ref('chapters/icon/')
            .child(fileName)
            .getDownloadURL();
        }

        const obj = {
          name: this.chapter.title,
          category: this.chapter.category,
          icon: {
            active: this.displayImageActive || '',
            inactive: this.displayImageInactive || ''
          },
          flashcards: this.chapter.flashcards,
          questions: this.chapter.questions?.map((question) => question.id),
          passages: this.chapter.passageQuestion,
          active: this.chapter.active,
          index: this.chapter.index,
          ...(this.chapter.createdAt && {
            createdAt: this.chapter.createdAt,
            updatedAt: this.chapter.updatedAt
          })
        };

        if (this.isEdit) {
          obj._id = this.$route.query.id;
          await this.service.update(obj);
        } else {
          await this.service.create(obj);
        }
        this.isEdit = false;

        await this.$router.push('/roadmap-chapters');
      } catch (e) {
        console.log(e);
        this.loading = false;
        context.reportError({
          title: `Error while ${this.isEdit ? 'Updating' : 'Creating'} Chapter`,
          description: e?.data?.error ?? 'Some error occurred'
        });
      }
    }
  }
};
</script>

<style scoped></style>
