export const toCSV = (items) => {
  const array = typeof items !== 'object' ? JSON.parse(items) : items;
  let str = 'First Name,Last Name,Email,Status\r\n';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    line += array[i].firstName + ',';
    line += array[i].lastName + ',';
    line += array[i].email + ',';
    line += array[i].status;
    line += '\r\n';
    str += line;
  }

  return str;
};

export const exportAsCSV = async (items) => {
  let csv = toCSV(items);
  const name = 'uploaded-customers-status.csv';
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
