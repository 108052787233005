import PopupsView from './PopupsView';
import PopupForm from './PopupForm';
import PopupDetails from './PopupDetails';
import { getUser } from '@/utils/local';

const popupNotificationsRoutes = [
  getUser()?.scopes.includes('popup-notifications:view')
    ? {
        name: 'PopupNotifications',
        path: '/popup-notifications',
        component: PopupsView
      }
    : null,
  getUser()?.scopes.includes('popup-notifications:view')
    ? {
        name: 'PopupDetails',
        path: '/popup-notification-details',
        component: PopupDetails
      }
    : null,
  getUser()?.scopes.includes('popup-notifications:new') ||
  getUser()?.scopes.includes('popup-notifications:edit')
    ? {
        name: 'NewPopupNotification',
        path: '/popup-notification',
        component: PopupForm
      }
    : null
];

export const popupNotificationsRouter = popupNotificationsRoutes.filter(
  function (x) {
    return x !== null;
  }
);
