import axios from 'axios';

export class VideoSectionService {
  async fetchByCategoryAndSubcategory(category, subcategory) {
    return (
      await axios.get(
        `/video-section/all/web?category=${category}&sub_category=${subcategory}`
      )
    ).data;
  }

  async update(data) {
    return (await axios.patch('/video-section', data)).data;
  }

  async create(data) {
    return (await axios.post('/video-section', data)).data;
  }

  async delete(id) {
    return (await axios.delete(`/video-section/${id}`)).data;
  }
}
