<template>
  <div>
    <v-card>
      <v-tabs v-model="tab" @change="clearFilters">
        <v-tab>Active Categories</v-tab>
        <v-tab>InActive Categories</v-tab>
      </v-tabs>
      <data-table
        :loader="loadData"
        :headers="headers"
        title="Passage Questions"
        allow-filters
        @filter="openFilter = true"
        :allow-add="
          getUser() ? getUser().scopes.includes('passage-questions:new') : false
        "
        @add-new="addNew"
        @done="$router.back()"
        :delete-handler="
          getUser()
            ? getUser().scopes.includes('passage-questions:delete')
              ? service.delete
              : null
            : null
        "
        :edit-handler="
          getUser()
            ? getUser().scopes.includes('passage-questions:edit')
              ? edit
              : null
            : null
        "
        :key="dataTableKey"
      >
        <template #primary-action>
          <v-btn
            v-if="
              getUser() && getUser().scopes.includes('passage-questions:import')
            "
            @click="openUploadQuestions"
            elevation="0"
            class="mr-3"
            >Import
          </v-btn>
          <v-btn
            v-if="
              getUser() &&
              getUser().scopes.includes('passage-questions:export') &&
              items.length > 0 &&
              !loading
            "
            @click="exportQuestions"
            elevation="0"
            color="primary"
            class="mr-3"
            >Export
          </v-btn>
          <v-btn
            v-if="
              getUser() && getUser().scopes.includes('passage-questions:new')
            "
            @click="generateIdeas"
            elevation="0"
            class="primary mr-2"
          >
            <v-icon left> mdi-lightbulb-on </v-icon>
            Generate Ideas
          </v-btn>
        </template>

        <template #extra-actions="extraActionsProps">
          <v-icon
            color="primary"
            v-if="getUser().scopes.includes('passage-questions:view')"
            small
            @click="showStats(extraActionsProps.item)"
          >
            mdi-chart-bar
          </v-icon>
        </template>

        <template v-slot:createdAt="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:passage="{ item }">
          {{ item.passage.substring(0, 100) }}
        </template>
        <template #premium="{ item }">
          <v-checkbox dense v-model="item.premium" readonly hide-details />
        </template>
        <template #active="{ item }">
          <v-checkbox dense v-model="item.active" readonly hide-details />
        </template>
      </data-table>
    </v-card>
    <v-dialog persistent width="50%" v-model="showFileUpload">
      <v-card class="px-5 py-7">
        <div v-if="!isUploading">
          <v-card-title class="px-0"
            >{{
              isUploaded
                ? 'Questions Imported'
                : 'Import Passage Questions By CSV'
            }}
          </v-card-title>
          <v-form ref="import">
            <div v-if="isUploaded">
              <div class="d-flex py-5">
                <v-chip class="mr-2" color="primary"
                  >Total: {{ this.questions.length }}
                </v-chip>
                <v-chip class="mr-2" color="success"
                  >Completed: {{ this.added }}
                </v-chip>
                <v-chip class="mr-2" color="error"
                  >Rejected: {{ this.questions.length - this.added }}
                </v-chip>
              </div>
              <div class="mb-2">
                <ul
                  style="color: red"
                  v-for="(error, i) of importErrors"
                  :key="i"
                >
                  <li>{{ error.text }} at entry # {{ error.item }}</li>
                </ul>
              </div>
              <div class="d-flex justify-end">
                <v-btn @click="cancelUpload" class="mr-2">OK</v-btn>
                <v-btn @click="newImport" class="ml-2" color="primary"
                  >New Import
                </v-btn>
              </div>
            </div>
            <div v-else>
              <p>
                Download a
                <a
                  href="/passage_question_template.csv"
                  download="passage_question_template.csv"
                  >sample CSV template</a
                >
                to see an example of the format required. <br />
                Use Pipe Operator (|) to separate multiple question ids in one
                cell.
              </p>
              <p>
                Download
                <a href="javascript:void(0)" @click="download">Questions</a>
                reference sheet to refer to their ids.
              </p>
              <v-file-input
                :rules="[required()]"
                v-model="uploadFile"
                outlined
                dense
                label="Upload CSV"
                prepend-icon=""
                accept=".csv"
                @change="bulkUpload"
              />
              <div class="d-flex justify-end">
                <div>
                  <v-btn
                    @click="cancelUpload"
                    class="mr-2"
                    color="primary"
                    outlined
                    >Cancel</v-btn
                  >
                  <v-btn
                    @click="uploadQuestions"
                    class="ml-2"
                    color="primary"
                    elevation="0"
                    >Upload
                  </v-btn>
                </div>
              </div>
            </div>
          </v-form>
        </div>
        <div v-else class="pa-16 d-flex justify-center align-center">
          <v-progress-circular indeterminate class="mr-3" />
          <p class="ma-0">Importing Questions...</p>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" width="800">
      <v-card class="pa-8" v-if="selectedItem">
        <div class="text-h4 text-center">Passage Question Stats</div>

        <div class="d-flex align-center mt-3 mb-1">
          <div class="font-weight-bold mr-4">Title :</div>
          <div>
            {{ selectedItem.heading }}
          </div>
        </div>

        <div class="d-flex align-center mt-3 mb-1">
          <div class="font-weight-bold mr-4">Total Passage Played :</div>
          <div>
            {{ selectedItem.total_played }}
          </div>
        </div>

        <div class="d-flex align-center mt-3 mb-1">
          <div class="font-weight-bold mr-4">Overall Right Answers :</div>
          <div>
            {{ selectedItem.overall_right }}
          </div>
        </div>

        <div class="d-flex align-center mt-3 mb-1">
          <div class="font-weight-bold mr-4">Overall Wrong Answers :</div>
          <div>
            {{ selectedItem.overall_wrong }}
          </div>
        </div>

        <div class="d-flex align-center mt-3 mb-1">
          <div class="font-weight-bold mr-4">Passage Right Percentage :</div>
          <div>{{ selectedItem.right_percentage.toFixed(2) }} %</div>
        </div>

        <div class="d-flex align-center mt-3 mb-1">
          <div class="font-weight-bold mr-4">Passage Wrong Percentage :</div>
          <div>{{ selectedItem.wrong_percentage.toFixed(2) }} %</div>
        </div>

        <v-expansion-panels class="mt-5">
          <v-expansion-panel
            v-for="(question, i) in selectedItem.data"
            :key="i"
          >
            <v-expansion-panel-header>
              {{ question.statement }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                class="d-flex align-center mt-3 mb-1"
                v-for="(optionKey, i) in Object.keys(question.options)"
                :key="i"
              >
                <div class="mr-4 col-9">{{ optionKey }} :</div>
                <div class="col-3 text-center">
                  {{ question.options[optionKey].toFixed(2) }} %
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openFilter" width="500">
      <v-card>
        <v-card-title>Filter</v-card-title>
        <v-card-text>
          <v-select
            v-model="category"
            :items="categories"
            item-text="name"
            return-object
            label="Category"
            outlined
            dense
            @change="subCategories = category.subCategories"
          ></v-select>
          <v-select
            v-model="subCategory"
            :items="subCategories"
            item-text="name"
            return-object
            label="Sub Category"
            outlined
            dense
            clearable
            @click:clear="subCategory = ''"
            :disabled="!category"
          ></v-select>

          <v-checkbox
            v-model="filterByPremium"
            label="Premium"
            hide-details
            dense
            class="mt-n2"
          ></v-checkbox>

          <v-checkbox
            v-model="filterByActive"
            label="Active"
            hide-details
            dense
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="clearFilters()" color="error" outlined>Clear</v-btn>

          <v-spacer></v-spacer>
          <v-btn @click="openFilter = false" color="primary" outlined
            >Cancel</v-btn
          >
          <v-btn @click="applyFilters()" color="primary" elevation="0"
            >Apply</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" message="Loading..." />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import { getUser } from '@/utils/local';
import { required } from '@/utils/validators';
import LoadingDialog from '@/components/LoadingDialog';
import moment from 'moment';
import { PassageQuestionsService } from '@/services/passage_questions-service';
import { QuestionsService } from '@/services/questions-service';
import { CategoryService } from '../../services/category-service';
import Papa from 'papaparse';

export default {
  components: { LoadingDialog, DataTable },

  data: () => ({
    tab: 0,
    dataTableKey: 0,
    openFilter: false,
    loading: false,
    dialog: false,
    selectedItem: null,
    service: new PassageQuestionsService(),
    questionsService: new QuestionsService(),
    categoryService: new CategoryService(),

    draftChanges: [],

    headers: [
      {
        text: 'Passage Title',
        value: 'heading'
      },
      {
        text: 'Question Passage',
        value: 'passage',
        sortable: false
      },
      {
        text: 'Category',
        value: 'categoryName'
      },
      {
        text: 'Sub Category',
        value: 'subCategoryName'
      },
      {
        text: 'Premium',
        value: 'premium'
      },
      {
        text: 'Active',
        value: 'active'
      },
      {
        text: 'Created At',
        value: 'createdAt'
      }
    ],

    filterByPremium: false,
    filterByActive: false,
    category: '',
    subCategories: [],
    subCategory: '',
    categories: [],
    items: [],

    showFileUpload: false,
    allSubs: [],
    uploadFile: null,
    isUploaded: false,
    isUploading: false,
    questions: [],
    importErrors: [],
    added: 0
  }),

  methods: {
    getUser,
    required,

    async loadCategories() {
      this.categories =
        this.tab === 0
          ? await this.categoryService.fetchAll()
          : await this.categoryService.fetchAllInactive();
    },

    clearFilters() {
      this.$router.replace({
        query: {
          category: '',
          subCategory: '',
          premium: '',
          active: ''
        }
      });
      this.category = '';
      this.subCategory = '';
      this.filterByPremium = false;
      this.filterByActive = false;
      this.openFilter = false;
      this.dataTableKey += 1;
    },

    applyFilters() {
      this.$router.replace({
        query: {
          category: this.category?.id,
          subCategory: this.subCategory?.id,
          premium: this.filterByPremium,
          active: this.filterByActive
        }
      });
      this.openFilter = false;
      this.dataTableKey += 1;
    },

    addToDraftChanges(passageId, key, value) {
      const passage = this.items.find((item) => item.id === passageId);
      if (this.draftChanges.find((item) => item.id === passageId)) {
        this.draftChanges = this.draftChanges.map((item) => {
          if (item.id === passageId) {
            return {
              ...item,
              [key]: value
            };
          }
          return item;
        });
      } else {
        this.draftChanges.push({
          ...passage,
          [key]: value
        });
      }
    },

    async saveChanges() {
      this.loading = true;
      await Promise.all(
        this.draftChanges.map((item) => this.service.update(item))
      );
      this.draftChanges = [];
      this.loading = false;
    },

    generateIdeas() {
      this.$router.push('/passage-questions/ideas');
    },

    async showStats(item) {
      this.loading = true;
      const res = await this.service.getStats(item.id);
      this.selectedItem = res.passages[0];
      this.dialog = true;
      this.loading = false;
    },

    addNew() {
      this.$router.push(
        '/passage-question' + (this.tab === 0 ? '' : '?isActiveCategory=false')
      );
    },
    edit(item) {
      this.$router.push(
        `/passage-question?id=${item.id}&isActiveCategory=${
          this.tab === 0 ? true : false
        }`
      );
    },
    async loadData() {
      this.items = await this.service.fetchAll({
        inactiveCategory: this.tab === 1 ? true : false,
        category: this.$route.query.category,
        subCategory: this.$route.query.subCategory,
        premium: this.$route.query.premium,
        active: this.$route.query.active
      });

      await this.loadCategories();

      if (this.$route.query.category) {
        this.category = this.categories.find(
          (x) => x.id === this.$route.query.category
        );

        this.subCategories = this.category.subCategories;

        if (this.$route.query.subCategory) {
          this.subCategory = this.category.subCategories.find(
            (x) => x.id === this.$route.query.subCategory
          );
        }
      }

      if (this.$route.query.premium === 'true') {
        this.filterByPremium = true;
      }

      if (this.$route.query.active === 'true') {
        this.filterByActive = true;
      }

      return (this.items = this.items.map((item) => {
        let category = this.categories.find((x) => x.id === item.category) || {
          name: 'No Category',
          subCategories: []
        };
        let subCategory = category.subCategories.find(
          (x) => x.id === item.sub_category
        ) || { name: 'No Sub-Category' };
        item.category = category;
        item.categoryName = category.name;
        item.sub_category = subCategory;
        item.subCategoryName = subCategory.name;

        return item;
      }));
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY - hh:mm A');
    },
    openUploadQuestions() {
      this.showFileUpload = true;
    },
    bulkUpload(item) {
      if (item) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const result = Papa.parse(data.toString(), {
            header: true,
            transformHeader: function (header) {
              return header.toLowerCase();
            },
            skipEmptyLines: true
          });
          this.questions = result.data;
        };
        reader.readAsText(item);
      }
    },
    async uploadQuestions() {
      if (this.$refs.import.validate()) {
        this.added = 0;
        this.isUploading = true;
        this.isUploaded = false;
        this.importErrors = [];
        for (let i = 0; i < this.questions.length; i++) {
          let item = this.questions[i];
          let error = false;
          const index = this.questions.indexOf(item);
          const question = {};
          if (item.title) {
            question.heading = item.title;
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'Question Title is missing'
            });
            error = true;
          }
          if (item.passage) {
            question.passage = item.passage;
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'Question Passage is missing'
            });
            error = true;
          }
          const selectedQuestions = item.questions
            .split('|')
            .filter((o) => o !== '');
          const questions = [];
          if (selectedQuestions && selectedQuestions.length > 0) {
            selectedQuestions.forEach((selectedQuestion) => {
              questions.push(selectedQuestion);
            });
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'At least one question is required'
            });
            error = true;
          }
          question.questions = questions;
          question.images = [];
          question.active = item.active.toLowerCase() === 'true';
          question.premium = item.premium.toLowerCase() === 'true';
          question.createdAt = new Date();
          if (!error) {
            await this.service
              .create(question)
              .then(() => {
                this.added += 1;
                return true;
              })
              .catch((e) => {
                this.importErrors.push({
                  item: index + 2,
                  text: e
                });
              });
          }
        }
        this.isUploading = false;
        this.isUploaded = true;
      }
    },

    cancelUpload() {
      if (this.isUploaded) {
        document.getElementById('refresh').click();
      }
      this.uploadFile = null;
      this.isUploaded = false;
      this.isUploading = false;
      this.showFileUpload = false;
    },

    newImport() {
      this.questions = [];
      this.uploadFile = null;
      this.importErrors = [];
      this.isUploading = false;
      this.isUploaded = false;
    },

    exportQuestions() {
      this.loading = true;
      const items = [...this.items];
      const csv = this.toCSVQuestions(items);
      const name = 'passage_questions.csv';
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false;
    },
    toCSVQuestions(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : [...items];
      let str = 'Title,Passage,Premium,Active,Questions\r\n';
      for (let i = 0; i < array.length; i++) {
        const item = { ...array[i] };
        let line = '';
        item.heading = '"' + item.heading.replaceAll('"', "'") + '"';
        item.passage = '"' + item.passage.replaceAll('"', "'") + '"';
        line +=
          item.heading +
          ',' +
          item.passage +
          ',' +
          item.premium +
          ',' +
          item.active +
          ',' +
          item.questions.join('|');
        str += line + '\r\n';
      }

      return str;
    },
    async download() {
      this.loading = true;
      const questions = await this.questionsService.getPassageQuestions();
      let name, blob;
      const csv = this.toCSV(questions);
      name = 'questions.csv';
      blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false;
    },
    toCSV(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : items;
      let str = 'ID,Statement\r\n';

      for (let i = 0; i < array.length; i++) {
        let line = '';
        array[i].statement =
          '"' + array[i].statement.replaceAll('"', "'") + '"';
        line += array[i].id + ',' + array[i].statement;
        line += '\r\n';

        str += line;
      }

      return str;
    }
  }
};
</script>

<style scoped></style>
