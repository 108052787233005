<template>
  <SimpleForm
    :onSubmit="submit"
    :style="[isModal && { margin: 0, width: '100%' }]"
    @done="isModal ? close() : $router.push('/questions')"
  >
    <div class="span-2 mb-3 flex justify-space-between">
      <v-btn
        v-show="isEdit"
        color="primary"
        outlined
        @click="goToUrl(question.previous)"
        :disabled="!question.previous"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <p class="form__title flex-grow-1">
        {{ isEdit ? 'Update Question' : 'Add a new Question' }}
      </p>

      <v-btn
        v-show="isEdit"
        color="primary"
        outlined
        @click="goToUrl(question.next)"
        :disabled="!question.next"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>

    <v-btn
      color="primary"
      outlined
      @click="editPassage"
      class="span-2 ml-auto mb-4"
      small
      v-if="$route.query.flag"
    >
      <v-progress-circular
        v-if="passageQuestionLoading"
        indeterminate
        color="primary"
        size="20"
      ></v-progress-circular>

      <span v-else>
        <v-icon color="primary" small>mdi-pencil</v-icon>
        {{
          question && question.passageIds && question.passageIds.length > 1
            ? 'Edit Passages'
            : 'Edit Passage'
        }}
      </span>
    </v-btn>
    <v-card v-if="flagQuestion" class="span-2 mb-3 pa-4">
      <v-card-title>Flag Reason</v-card-title>
      <p class="body-1 px-4">{{ flagQuestion.reason }}</p>
      <div></div>
    </v-card>
    <v-checkbox
      v-model="isActiveQuestion"
      label="Is this question for active category?"
      class="span-2"
      @change="
        loadCategories();
        question.category = null;
        question.subCategory = null;
      "
    />
    <v-select
      v-model="question.type"
      :items="types"
      :rules="[required('You must select a question type')]"
      class="span-2"
      dense
      filled
      item-text="text"
      item-value="value"
      label="Select Type"
    />
    <div class="span-2 mb-6" style="position: relative">
      <h3 class="mb-2">Question Statement</h3>
      <v-textarea
        v-model="question.statement"
        dense
        :rules="[required('You must write a question statement')]"
        label="Question Statement"
        class="span-2"
        outlined
        no-resize
      />
    </div>

    <answers-picker
      v-model="question.options"
      :is-edit="isEdit"
      :picker-type="question.type"
      :question-percentage="question.percentage"
      :selected="question.answer"
      style="margin-top: -25px; margin-left: 25px"
      @selected="question.answer = $event"
    />

    <v-select
      v-model="question.category"
      :items="categories"
      :loading="loadingCategories"
      :rules="[required('Select a category')]"
      dense
      item-text="name"
      item-value="id"
      label="Select Category"
      outlined
      return-object
      @change="subCategories = question.category.subCategories"
    />

    <v-select
      v-model="question.subCategory"
      :disabled="!question.category"
      :items="subCategories"
      :rules="[required('Select a sub-category')]"
      dense
      item-text="name"
      label="Select Subcategory"
      outlined
      return-object
    />

    <v-divider class="span-2" style="margin-bottom: 20px" />

    <v-select
      v-model="question.trial"
      :items="trials"
      :rules="[required('Select Trial')]"
      dense
      item-text="text"
      label="Trial"
      outlined
    />

    <v-select
      v-model="question.for_passage"
      :items="trials"
      :rules="[required('Select Passage')]"
      dense
      item-text="text"
      label="Passage"
      outlined
    />

    <v-text-field
      v-if="isEdit"
      v-model="totalPlayed"
      class="span-2"
      dense
      label="Total Played"
      outlined
      readonly
    />

    <div class="span-2 mb-6">
      <h3 class="mb-2">Answer Explanation</h3>
      <v-textarea
        v-model="question.explanation"
        dense
        :rules="[required('You must write a answer explanation')]"
        label="Answer Explanation"
        class="span-2"
        outlined
        no-resize
      />
    </div>

    <div class="mb-4 d-flex justify-space-between items-center span-2">
      <h3>Select Media</h3>
      <v-btn
        color="primary"
        elevation="0"
        outlined
        @click="openMediaPickerModal"
        >Select</v-btn
      >
    </div>
    <MediaPicker
      id="mediaPickerBtn1"
      :selectedData="selectedData"
      @SetNewSelectedData="SetNewSelectedData"
      :isActive="isActiveQuestion"
    />

    <div class="span-2 carousel-display">
      <h4
        v-if="
          selectedData.images &&
          selectedData.images.length <= 0 &&
          selectedData.videos &&
          selectedData.videos.length <= 0 &&
          selectedData.pdfs &&
          selectedData.pdfs.length <= 0
        "
        class="mt-3 text-center font-weight-light"
      >
        No Media Selected
      </h4>

      <h2
        v-if="selectedData.images && selectedData.images.length === 1"
        class="mt-2 mb-3"
      >
        Image
      </h2>
      <h2
        v-if="selectedData.images && selectedData.images.length > 1"
        class="mt-2 mb-3"
      >
        Images
      </h2>
      <v-carousel
        v-if="selectedData.images && selectedData.images.length > 0"
        :show-arrows="selectedData.images && selectedData.images.length > 1"
        hide-delimiters
        style="width: 100%; height: fit-content"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            color="#4396e4"
            style="padding: 10px; background-color: white; border-radius: 50%"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            color="#4396e4"
            style="padding: 10px; background-color: white; border-radius: 50%"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item
          v-for="(item, i) in selectedData.images"
          :key="i"
          :src="item"
        >
          <v-btn
            @click="goToEditMedia(item, '1')"
            style="position: absolute; top: 10px; right: 10px"
            color="primary"
            title="Edit Media"
            small
          >
            <v-progress-circular
              v-if="questionMediaLoading"
              indeterminate
              color="white"
              size="20"
            ></v-progress-circular>

            <v-icon v-else color="white" small>mdi-pencil</v-icon> Question
            Media
          </v-btn>
        </v-carousel-item>
      </v-carousel>

      <h2
        v-if="selectedData.videos && selectedData.videos.length === 1"
        class="mt-2 mb-3"
      >
        Video
      </h2>
      <h2
        v-if="selectedData.videos && selectedData.videos.length > 1"
        class="mt-2 mb-3"
      >
        Videos
      </h2>
      <v-carousel
        v-if="selectedData.videos && selectedData.videos.length > 0"
        :show-arrows="selectedData.videos && selectedData.videos.length > 1"
        hide-delimiters
        style="width: 100%; height: fit-content"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            color="#4396e4"
            style="padding: 10px; background-color: white; border-radius: 50%"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            color="#4396e4"
            style="padding: 10px; background-color: white; border-radius: 50%"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item v-for="(item, i) in selectedData.videos" :key="i">
          <video
            :src="item"
            controls
            height="auto"
            style="width: 100%; height: 100%"
            width="auto"
          />

          <v-btn
            @click="goToEditMedia(item, '2')"
            style="position: absolute; top: 10px; right: 10px"
            color="primary"
            title="Edit Media"
            small
          >
            <v-progress-circular
              v-if="questionMediaLoading"
              indeterminate
              color="white"
              size="20"
            ></v-progress-circular>

            <v-icon v-else color="white" small>mdi-pencil</v-icon> Question
            Media
          </v-btn>
        </v-carousel-item>
      </v-carousel>

      <h2
        v-if="selectedData.pdfs && selectedData.pdfs.length === 1"
        class="mt-2 mb-3 float-start"
      >
        PDF
      </h2>
      <h2
        v-if="selectedData.pdfs && selectedData.pdfs.length > 1"
        class="mt-2 mb-3 float-start"
      >
        PDFs
      </h2>

      <a
        v-if="selectedData.pdfs && selectedData.pdfs.length > 0"
        :href="selectedData.pdfs && selectedData.pdfs[displayedPdfIndex]"
        class="text-center float-end text-decoration-none"
        target="_blank"
      >
        <v-btn class="mt-3" color="black" elevation="0" small>
          <v-icon color="white" size="20"> mdi-arrow-expand-all </v-icon>
        </v-btn>
      </a>

      <v-carousel
        v-if="selectedData.pdfs && selectedData.pdfs.length > 0"
        :show-arrows="selectedData.pdfs && selectedData.pdfs.length > 1"
        hide-delimiters
        style="width: 100%; height: fit-content"
        @change="pdfChange"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            color="#4396e4"
            style="padding: 10px; background-color: white; border-radius: 50%"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            color="#4396e4"
            style="padding: 10px; background-color: white; border-radius: 50%"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item v-for="(item, i) in selectedData.pdfs" :key="i">
          <iframe :src="item" style="width: 100%; height: 100%" />

          <v-btn
            @click="goToEditMedia(item, '3')"
            style="position: absolute; top: 10px; right: 10px"
            color="primary"
            title="Edit Media"
            small
          >
            <v-progress-circular
              v-if="questionMediaLoading"
              indeterminate
              color="primary"
              size="20"
            ></v-progress-circular>

            <v-icon v-else color="white" small>mdi-pencil</v-icon> Question
            Media
          </v-btn>
        </v-carousel-item>
      </v-carousel>
    </div>

    <v-dialog v-model="questionMediaDialog" max-width="800">
      <v-card>
        <div
          class="d-flex justify-space-between items-center"
          style="padding: 5px 10px; background-color: #f5f5f5"
        >
          <v-card-title>Question Medias</v-card-title>
          <v-icon
            @click="questionMediaDialog = false"
            style="cursor: pointer"
            color="primary"
          >
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <v-card
            v-for="(item, index) in questionMedias"
            :key="item.id"
            outlined
            class="mt-4"
          >
            <v-card-text
              class="d-flex justify-space-between items-center my-n4"
            >
              <v-card-title> {{ index + 1 }}. </v-card-title>

              <v-btn
                color="primary"
                small
                outlined
                @click="openQuestionMediaInNewTab(item.id)"
              >
                Edit
              </v-btn>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  style="
                    max-height: 200px;
                    overflow-y: auto;
                    overflow-x: hidden;
                  "
                >
                  <p>Questions:</p>
                  <v-chip
                    v-for="question in item.questions"
                    :key="question"
                    class="ml-2 mb-2"
                    small
                    hide-details
                  >
                    {{ question.statement }}
                  </v-chip>
                  <p v-if="item.questions.length == 0">
                    No Questions are associated with this media
                  </p>
                </v-col>
                <v-col cols="12">
                  <p>Keywords:</p>
                  <v-chip
                    v-for="keyword in item.keywords"
                    :key="keyword"
                    color="primary"
                    class="ml-2 mb-2"
                    small
                    hide-details
                  >
                    {{ keyword }}
                  </v-chip>
                  <p v-if="item.keywords.length == 0">
                    No Keywords are associated with this media
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="passageQuestionsDialog" max-width="800">
      <v-card>
        <v-card-title class="headline"
          >Select Passage Question to Edit
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(item, i) in passageQuestions"
              :key="i"
              @click="goToEditPassage(item.id)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ i + 1 }}. {{ item.heading }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="passageQuestionsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" message="Loading Question Data" />
    <ErrorDialog v-model="error" :error="errorVal" @value="$router.back()" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import AnswersPicker from '../../components/questions/AnswersPicker';

import { required } from '@/utils/validators';
import { CategoryService } from '@/services/category-service';
import { QuestionsService } from '@/services/questions-service';
import { MediaGalleryService } from '@/services/media-gallery-service';
import { QuestionMediaService } from '@/services/question_media-service';
import { PassageQuestionsService } from '@/services/passage_questions-service';
import LoadingDialog from '../../components/LoadingDialog';
import { storage } from '@/plugins/firebase';
import axios from 'axios';
import ErrorDialog from '@/components/ErrorDialog';
import moment from 'moment';
import MediaPicker from '../../components/media/MediaPicker';

async function uploadWithMessage(context, list, message, type) {
  context.changeLoadingMessage(message + ' ...');

  const newList = [];
  for (const item of list) {
    const fileName =
      item.filename + '~' + new Date().getTime() + '' + item.fileExtension;
    let reference = storage.ref(type + '/' + fileName);
    let task = reference.put(item.file);
    await task
      .then(async () => {
        if (type === 'videos') {
          const thumbnail = await generateThumbnail(item);
          let thumbRef = storage.ref(type + '/thumbnails/' + fileName);
          let thumbTask = thumbRef.put(thumbnail);
          await thumbTask
            .then(() => {
              window.console.log('thumbnail posted');
            })
            .catch((e) => window.console.log('uploading image error => ', e));
        }
        newList.push(await storage.ref(type).child(fileName).getDownloadURL());
      })
      .catch((e) => window.console.log('uploading image error => ', e));
    context.changeLoadingMessage(
      message + ': ' + item.filenameWithoutExtension
    );
  }

  return newList;
}

async function generateThumbnail(item) {
  const binaryData = [];
  binaryData.push(item.file);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const video = document.createElement('video');
  video.setAttribute('src', URL.createObjectURL(new Blob(binaryData)));
  video.load();
  let thumbnail = await new Promise((resolve) => {
    video.onloadedmetadata = async () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      video.cur2rentTime = video.duration / 2;
      await video.play();
      context.drawImage(video, 0, 0);
      video.pause();
      const blob = await new Promise((resolve) => {
        return canvas.toBlob(function (blob) {
          resolve(blob);
        });
      });
      resolve(blob);
    };
  });
  return thumbnail;
}

export default {
  name: 'QuestionForm',
  components: {
    ErrorDialog,
    LoadingDialog,
    AnswersPicker,
    SimpleForm,
    MediaPicker
  },

  computed: {
    uploadedVideos() {
      return (this.question?.videos || []).map((image) => {
        return {
          source: image,
          options: { type: 'local', metadata: { uploaded: true } }
        };
      });
    },
    uploadedPdfs() {
      return (this.question?.pdfs || []).map((image) => {
        return {
          source: image,
          options: { type: 'local', metadata: { uploaded: true } }
        };
      });
    },
    uploadedImages() {
      return (this.question?.images || []).map((image) => {
        return {
          source: image,
          options: { type: 'local', metadata: { uploaded: true } }
        };
      });
    }
  },

  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    initialData: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    passageQuestions: [],
    passageQuestionLoading: false,
    passageQuestionsDialog: false,
    isActiveQuestion: true,
    displayedPdfIndex: null,
    questionMediaLoading: false,
    questionMediaDialog: false,
    questionMedias: [],
    selectedData: {
      images: [],
      videos: [],
      pdfs: []
    },

    customToolbar: [
      [{ header: [false, 2, 3, 4] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      ['clean']
    ],
    editorOptions: {
      modules: {
        toolbar: false
      }
    },
    explanationHtmlMissing: false,
    statementHtmlMissing: false,
    videosServer: null,
    isEdit: false,

    error: false,
    errorVal: {},

    question: {
      options: [],
      selected: null
    },
    loading: false,
    categories: [],

    subCategories: [],
    service: new QuestionsService(),
    categoriesService: new CategoryService(),
    mediaService: new MediaGalleryService(),
    questionMediaService: new QuestionMediaService(),
    passageQuestionService: new PassageQuestionsService(),
    loadingCategories: false,

    types: [
      {
        text: 'Multiple Choice Question',
        value: 'choices'
      },
      {
        text: 'Boolean (True/False)',
        value: 'bool'
      }
    ],
    trials: [
      {
        text: 'Yes',
        value: 'true'
      },
      {
        text: 'No',
        value: 'false'
      }
    ],

    pdfs: [],
    images: [],
    videos: [],

    pdfsToBeDeleted: [],
    imagesToBeDeleted: [],
    videosToBeDeleted: [],

    flagQuestion: null,
    totalPlayed: 0
  }),

  mounted() {
    if (this.$route.query.isActiveCategory) {
      this.isActiveQuestion = this.$route.query.isActiveCategory === 'true';
    }

    this.loadQuestion().then(() => {
      this.loadCategories().then(() => {
        this.setInitialData();
      });
    });
  },

  watch: {
    initialData: {
      handler() {
        this.setInitialData();
      },
      deep: true
    },

    $route() {
      if (this.$route.query.isActiveCategory) {
        this.isActiveQuestion = this.$route.query.isActiveCategory === 'true';
      }

      this.loadQuestion().then(() => {
        this.loadCategories().then(() => {
          this.setInitialData();
        });
      });
    }
  },

  methods: {
    required,

    goToUrl(url) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          id: url
        }
      });
    },

    goToEditPassage(id) {
      window.open(`/passage-question?id=${id}`, '_blank');
    },

    editPassage() {
      if (this.question.passageIds.length === 1) {
        this.goToEditPassage(this.question.passageIds[0]);
      } else if (this.question.passageIds.length > 1) {
        this.passageQuestionLoading = true;
        Promise.all(
          this.question.passageIds.map((id) =>
            this.passageQuestionService.fetchOne(id)
          )
        )
          .then((data) => {
            this.passageQuestions = data;
          })
          .catch((e) => {
            console.error(e);
            this.$toast.error('Error fetching passage questions');
          })
          .finally(() => {
            this.passageQuestionLoading = false;
          });

        this.passageQuestionsDialog = true;
      } else {
        this.$toast.error('No passage found for this question');
      }
    },

    openQuestionMediaInNewTab(id) {
      window.open('/question-media?id=' + id, '_blank');
    },

    async goToEditMedia(mediaLink, mediaType) {
      let mediaIds = (
        await this.$axios.post(
          `/question-media/idsByMediaUrl?mediaType=${mediaType}`,
          {
            url: mediaLink
          }
        )
      ).data;

      if (mediaIds && mediaIds.length === 1) {
        this.openQuestionMediaInNewTab(mediaIds[0]);
      } else if (mediaIds && mediaIds.length > 1) {
        this.questionMediaLoading = true;
        Promise.all(
          mediaIds.map(async (item) => {
            return (await this.questionMediaService.fetchOne(item)).current;
          })
        ).then((data) => {
          this.questionMedias = data;
          this.questionMediaLoading = false;
        });

        this.questionMediaDialog = true;
      } else {
        this.$toast.error('Question Media not found for this media');
      }
    },

    async setInitialData() {
      if (this.initialData?.question) {
        this.isActiveQuestion = this.initialData?.isActive;
        await this.loadCategories();

        if (
          this.initialData?.choices &&
          this.initialData?.choices.length === 2
        ) {
          this.question.type = 'bool';
        } else {
          this.question.type = 'choices';
        }
        this.question.options = [...this.initialData?.choices];
        this.question.answer = Number(this.initialData?.correctAnswer);
        this.question.statement = this.initialData?.question;
        this.question.explanation = this.initialData?.explanation;
        this.question.category = this.initialData?.category;
        this.subCategories = this.initialData?.category?.subCategories;
        this.question.subCategory = this.initialData?.subCategory;
        this.question.for_passage = this.initialData?.isPassage
          ? 'true'
          : 'false';
        this.question.trial = this.initialData?.trial ? 'true' : 'false';
      }
    },

    pdfChange(index) {
      this.displayedPdfIndex = index;
    },

    close() {
      this.question = {
        options: [],
        selected: null
      };

      this.selectedData = {
        images: [],
        videos: [],
        pdfs: []
      };
      this.$emit('close');
    },

    async loadCategories() {
      this.loadingCategories = true;
      this.categories = this.isActiveQuestion
        ? await this.categoriesService.fetchAll()
        : await this.categoriesService.fetchAllInactive();
      for (const item of this.categories) {
        if (this.question.category?.id === item.id) {
          this.subCategories = item.subCategories;
          break;
        }
      }
      this.loadingCategories = false;
    },

    async loadQuestion() {
      try {
        if (this.isModal) return;
        if (!this.$route.query.id) return;
        if (this.$route.query.inactive) {
          this.isActiveQuestion = false;
        }
        this.isEdit = true;
        this.loading = true;

        if (this.$route.query.field === 'all') {
          this.question = await this.service.fetchOne(this.$route.query.id);
        } else {
          let query = '';

          if (this.$route.query.field === 'category') {
            query = `type=0&category=${this.$route.query.category}${
              this.$route.query.tab === '1' ? '&tab=inactive' : ''
            }`;
          } else if (this.$route.query.field === 'subcategory') {
            query = `type=1&category=${
              this.$route.query.category
            }&subcategory=${this.$route.query.subcategory}${
              this.$route.query.tab === '1' ? '&tab=inactive' : ''
            }`;
          } else if (this.$route.query.field === 'trial') {
            query = `type=2&trial=${this.$route.query.trial}${
              this.$route.query.tab === '1' ? '&tab=inactive' : ''
            }`;
          } else if (this.$route.query.field === 'type') {
            query = `type=3&question-type=${this.$route.query.type}${
              this.$route.query.tab === '1' ? '&tab=inactive' : ''
            }`;
          } else if (this.$route.query.field === 'passage') {
            query = `type=5&passage=${this.$route.query.passage}${
              this.$route.query.tab === '1' ? '&tab=inactive' : ''
            }`;
          } else if (this.$route.query.field === 'explanation') {
            query = `type=6&explanation=${this.$route.query.explanation}${
              this.$route.query.tab === '1' ? '&tab=inactive' : ''
            }`;
          } else if (this.$route.query.field === 'date') {
            const date = new Date(this.$route.query.created);
            const startDate = moment(date).format('YYYY-MM-DD');
            const nextDay = new Date(date);
            nextDay.setDate(date.getDate() + 1);
            const endDate = moment(nextDay).format('YYYY-MM-DD');
            query = `type=4&from=${startDate}&to=${endDate}${
              this.$route.query.tab === '1' ? '&tab=inactive' : ''
            }`;
          } else if (this.$route.query.field === 'mediaCount') {
            query = `sort_by=mediaCount${
              this.$route.query.tab === '1' ? '&tab=inactive' : ''
            }`;
          }

          this.question = await this.service.fetchOne(
            this.$route.query.id,
            this.$route.query.sort,
            query
          );
        }

        this.question.passageIds =
          await this.service.getPassageQuestionsByQuestionId(
            this.$route.query.id
          );
        this.totalPlayed = (
          await this.service.fetchTotalPlayedCount(this.$route.query.id)
        ).count;
        this.selectedData = {
          images: this.question.images,
          videos: this.question.videos,
          pdfs: this.question.pdfs
        };

        this.question.trial = this.question.trial === true ? 'true' : 'false';
        this.question.for_passage =
          this.question.for_passage === true ? 'true' : 'false';
        if (this.$route.query.flag) {
          this.flagQuestion = (
            await axios.get('/flagged-questions/' + this.$route.query.flag)
          ).data;
        }
        if (!this.question.htmlStatement)
          this.question.htmlStatement =
            '<span style="color: rgb(255, 255, 255);">' +
            this.question.statement +
            '</span>';
        if (!this.question.htmlExplanation)
          this.question.htmlExplanation =
            '<span style="color: rgb(255, 255, 255);">' +
            this.question.explanation +
            '</span>';
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.errorVal = {
          title: 'Question does not exist anymore',
          description: 'This question has been deleted by someone.'
        };
      }
    },

    openMediaPickerModal() {
      document.getElementById('mediaPickerBtn1').click();
    },

    SetNewSelectedData(data) {
      this.selectedData = data;
      this.question.images = data.images;
      this.question.videos = data.videos;
      this.question.pdfs = data.pdfs;
    },

    async submit(context) {
      this.statementHtmlMissing = false;
      this.explanationHtmlMissing = false;

      if (this.question.type === 'choices') {
        if (this.question.options.length < 4) {
          context.reportError({
            title: 'Invalid Question Data',
            description:
              'Provided Question does not have enough answer choices, A Multiple Choice question must have at least 4 options'
          });
          return false;
        }
      }
      if (this.question.images && this.question.images.length > 0) {
        this.question.images = [
          ...this.question.images,
          ...(await uploadWithMessage(
            context,
            this.images,
            'Uploading Images',
            'images'
          ))
        ];
      } else {
        this.question.images = [
          ...(await uploadWithMessage(
            context,
            this.images,
            'Uploading Images',
            'images'
          ))
        ];
      }
      if (this.question.pdfs && this.question.pdfs.length > 0) {
        this.question.pdfs = [
          ...this.question.pdfs,
          ...(await uploadWithMessage(
            context,
            this.pdfs,
            'Uploading Pdfs',
            'pdfs'
          ))
        ];
      } else {
        this.question.pdfs = [
          ...(await uploadWithMessage(
            context,
            this.pdfs,
            'Uploading Pdfs',
            'pdfs'
          ))
        ];
      }
      if (this.question.videos && this.question.videos.length > 0) {
        this.question.videos = [
          ...this.question.videos,
          ...(await uploadWithMessage(
            context,
            this.videos,
            'Uploading Videos',
            'videos'
          ))
        ];
      } else {
        this.question.videos = [
          ...(await uploadWithMessage(
            context,
            this.videos,
            'Uploading Videos',
            'videos'
          ))
        ];
      }
      this.question.trial = this.question.trial === 'true';
      this.question.for_passage = this.question.for_passage === 'true';

      if (this.isEdit) {
        context.changeLoadingMessage('Updating Question');
        try {
          this.question.category = this.question.category.id;
          this.question.subCategory = this.question.subCategory.id;
          await this.service.update(this.question);
          if (this.$route.query.token) {
            const notification = {
              title: 'Flagged Question Updated',
              description:
                'The question you flagged has been rectified. Thank you for your feedback!',
              isForIOS: true,
              isForAndroid: true,
              channel: this.$route.query.token
            };
            await axios
              .post('/notifications/send-to-user', notification)
              .then(() => {
                return true;
              })
              .catch(() => {
                return false;
              });
          }
          this.$toast.success('Question Updated Successfully');
          return true;
        } catch (e) {
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating A New Question');
        try {
          this.question.category = this.question.category.id;
          this.question.subCategory = this.question.subCategory.id;

          await this.service.create(this.question);
          this.$toast.success('Question Created Successfully');
          return true;
        } catch (e) {
          return false;
        }
      }
    }
  }
};
</script>
