import axios from 'axios';

export class PassagePromptsService {
  async fetchAll() {
    return (await axios.get('/passage-generation')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/passage-generation/' + id)).data;
  }

  async create(fact) {
    return (await axios.post('/passage-generation', fact)).data;
  }

  async delete(fact) {
    return (await axios.delete('/passage-generation/' + fact.id)).data;
  }

  async update(fact) {
    return (await axios.patch('/passage-generation', fact)).data;
  }
}
