<template>
  <div>
    <div
      :class="[hasError ? 'invalid' : 'valid']"
      class="drop mb-4"
      :style="{ cursor: disabled ? 'default' : 'pointer' }"
      @drop="onDrop"
      @dragover.prevent
      @click.self="!disabled ? clickOnBox() : null"
    >
      <input
        ref="file-input"
        id="fileInput"
        name="image"
        style="display: none"
        type="file"
        @change="onChange"
        accept="image/*"
      />
      <div
        v-if="!(image && image.url)"
        class="mx-4 cursor-pointer"
        style="margin-top: 80px"
        @click="$refs['file-input'].click()"
      >
        + Select/Drop Image
      </div>

      <div
        v-else
        class="d-flex align-start"
        style="position: relative"
        v-bind:class="{ image: true }"
      >
        <img :src="image.url" alt="" class="img" />
        <v-icon v-if="!disabled" class="icon" @click="removeFile"
          >mdi-close</v-icon
        >
      </div>
    </div>
    <span v-if="hasError" style="color: red">Image is required!</span>
  </div>
</template>

<script>
export default {
  name: 'ImageUpload',
  props: {
    hasError: {
      type: Boolean,
      defaults: false
    },
    image_obj: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      image: null
    };
  },

  watch: {
    image: {
      handler(newVal) {
        this.$emit('uploadedImage', newVal);
      },
      deep: true
    },

    image_obj(newVal) {
      this.image = newVal;
    }
  },

  mounted() {
    this.image = this.image_obj;
  },

  methods: {
    clickOnBox() {
      this.$refs['file-input'].click();
    },

    // for image storage control
    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      let files = e.dataTransfer.files;
      this.createFile(files[0]);
    },

    onChange(e) {
      let files = e.target.files;
      this.createFile(files[0]);
    },

    createFile(file) {
      if (file) {
        this.image = {
          file: file,
          url: URL.createObjectURL(file),
          name: file.name,
          type: file.type,
          size: file.size
        };
      } else {
        console.log('File Selection canceled');
      }
    },

    removeFile() {
      this.image = '';
      document.getElementById('fileInput').value = '';
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}

.icon {
  position: absolute;
  color: red;
  top: 0;
  right: 5px;
}

html,
body {
  height: 100%;
  text-align: center;
}

.invalid {
  border: 2px dashed red;
}

.valid {
  border: 2px dashed #ccc;
}

.btn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.btn:hover {
  background-color: #722040;
}

input[type='file'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

.helper {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  width: 0;
}

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.img {
  border: 1px solid #f6f6f6;
  display: inline-block;
  height: 200px;
  width: 200px;
  /*margin-left: -2px;*/
  /*margin-top: -2px;*/
  object-fit: contain;
}

.drop {
  cursor: pointer;
  background-color: #f2f2f2;
  border-radius: 2px;
  height: 200px;
  width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
