<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title">{{ isEdit ? 'Update Flashcard' : 'Create New Flashcard' }}</p>
    <v-autocomplete
        v-model="flashcard.deck"
        :items="decks"
        item-text="name"
        item-value="id"
        :rules="[required('Select a Deck')]"
        dense
        label="Deck"
        outlined
        class="span-2"
    ></v-autocomplete>
    <v-select
        v-model="selectedType"
        :items="cardTypes"
        label="Card Type"
        outlined
        dense
        class="span-2"
        required
    ></v-select>
    <div class="span-2 mb-5">
      <v-slide-y-reverse-transition>
        <v-card v-if="selectedType === 'Text'" style="padding:20px ">
          <v-select
              v-model="selectedQuestionType"
              :items="questionsTypes"
              label="Question Type"
              outlined
              dense
              class="span-2"
          ></v-select>
          <div class="span-2">
            <v-slide-y-transition>
              <div v-if="selectedQuestionType === 'Plain'">
                <v-text-field
                    :rules="[required('A Question must be provided')]"
                    dense
                    label="Question"
                    outlined
                    v-model="front_text"

                />
                <v-textarea
                    :rules="[required('An Answer must be provided')]"
                    outlined
                    v-model="back_text"
                    name="input-7-4"
                    label="Answer"

                ></v-textarea>
              </div>
            </v-slide-y-transition>
            <v-slide-y-transition>
              <div v-if="selectedQuestionType === 'Cloze Deletion'">
                <v-textarea
                    :rules="[dynamicQuestion('A Question must be provided with atleast one Cloze Deletion value') ]"
                    ref="textarea"
                    outlined
                    rows="3"
                    append-icon="mdi-database"
                    @click:append="addDynamicText"
                    label="Question"
                    auto-grow
                    v-model="dynamicText"
                ></v-textarea>
                <v-textarea
                    outlined
                    v-model="back_text"
                    rows="2"
                    label="Answer"
                    auto-grow
                ></v-textarea>
              </div>
            </v-slide-y-transition>
          </div>
        </v-card>
      </v-slide-y-reverse-transition>
    </div>

    <div v-if="selectedType === 'Image'" class="mb-4 d-flex justify-space-between items-center span-2">
      <h3>Select Front Media</h3>
      <v-btn outlined elevation="0" color="primary" @click="openFrontMediaPickerModal">Select</v-btn>
    </div>
    <MediaPicker v-if="selectedType === 'Image'" allowedType="image" id="frontMediaPicker" :selectedData="selectedDataFront" @SetNewSelectedData="setSelectedDataFront"/>
    <div v-if="selectedType === 'Image'" class="span-2 carousel-display mb-5">
      <h4 class="mt-3 text-center font-weight-light"
          v-if="selectedDataFront.images.length<=0 && selectedDataFront.videos.length<=0 && selectedDataFront.pdfs.length<=0">
        No Front Media
        Selected</h4>

      <h2 class="mt-2 mb-3" v-if="selectedDataFront.images.length===1">Front Image</h2>
      <h2 class="mt-2 mb-3" v-if="selectedDataFront.images.length>1">Front Images</h2>
      <v-carousel :show-arrows="selectedDataFront.images.length>1" v-if="selectedDataFront.images.length>0"
                  hide-delimiters
                  style="width: 100%; height: fit-content;">

        <template v-slot:prev="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item
            v-for="(item,i) in selectedDataFront.images"
            :key="i"
            :src="item"
        >
        </v-carousel-item>
      </v-carousel>

      <h2 class="mt-2 mb-3" v-if="selectedDataFront.videos.length===1">Video</h2>
      <h2 class="mt-2 mb-3" v-if="selectedDataFront.videos.length>1">Videos</h2>
      <v-carousel :show-arrows="selectedDataFront.videos.length>1" v-if="selectedDataFront.videos.length>0"
                  hide-delimiters
                  style="width: 100%; height: fit-content;">

        <template v-slot:prev="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item
            v-for="(item,i) in selectedDataFront.videos"
            :key="i"
        >
          <video style="width: 100%; height: 100%;" width="auto" height="auto" :src="item" controls/>
        </v-carousel-item>
      </v-carousel>

      <h2 class="mt-2 mb-3 float-start" v-if="selectedDataFront.pdfs.length===1">PDF</h2>
      <h2 class="mt-2 mb-3 float-start" v-if="selectedDataFront.pdfs.length>1">PDFs</h2>

      <a v-if="selectedDataFront.pdfs.length>0" :href="selectedDataFront.pdfs[displayedPdfIndexFront]"
         class="text-center float-end text-decoration-none" target="_blank">
        <v-btn small class="mt-3" elevation="0" color="black">
          <v-icon size="20" color="white">
            mdi-arrow-expand-all
          </v-icon>
        </v-btn>
      </a>

      <v-carousel :show-arrows="selectedDataFront.pdfs.length>1" v-if="selectedDataFront.pdfs.length>0"
                  @change="pdfChange"
                  hide-delimiters style="width: 100%; height: fit-content;">
        <template v-slot:prev="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item
            v-for="(item,i) in selectedDataFront.pdfs"
            :key="i"
        >
          <iframe style="width: 100%; height: 100%;" :src="item"/>
        </v-carousel-item>
      </v-carousel>

    </div>

    <v-divider class="my-5 span-2"/>

    <div class="mb-4 d-flex justify-space-between items-center span-2">
      <h3>Select Back Media</h3>
      <v-btn outlined elevation="0" color="primary" @click="openBackMediaPickerModal">Select</v-btn>
    </div>
    <MediaPicker allowedType="image" id="backMediaPicker" :selectedData="selectedDataBack" @SetNewSelectedData="setSelectedDataBack"/>
    <div class="span-2 carousel-display">
      <h4 class="mt-3 text-center font-weight-light"
          v-if="selectedDataBack.images.length<=0 && selectedDataBack.videos.length<=0 && selectedDataBack.pdfs.length<=0">
        No Back Media
        Selected</h4>

      <h2 class="mt-2 mb-3" v-if="selectedDataBack.images.length===1">Back Image</h2>
      <h2 class="mt-2 mb-3" v-if="selectedDataBack.images.length>1">Back Images</h2>
      <v-carousel :show-arrows="selectedDataBack.images.length>1" v-if="selectedDataBack.images.length>0"
                  hide-delimiters
                  style="width: 100%; height: fit-content;">

        <template v-slot:prev="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item
            v-for="(item,i) in selectedDataBack.images"
            :key="i"
            :src="item"
        >
        </v-carousel-item>
      </v-carousel>

      <h2 class="mt-2 mb-3" v-if="selectedDataBack.videos.length===1">Video</h2>
      <h2 class="mt-2 mb-3" v-if="selectedDataBack.videos.length>1">Videos</h2>
      <v-carousel :show-arrows="selectedDataBack.videos.length>1" v-if="selectedDataBack.videos.length>0"
                  hide-delimiters
                  style="width: 100%; height: fit-content;">

        <template v-slot:prev="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item
            v-for="(item,i) in selectedDataBack.videos"
            :key="i"
        >
          <video style="width: 100%; height: 100%;" width="auto" height="auto" :src="item" controls/>
        </v-carousel-item>
      </v-carousel>

      <h2 class="mt-2 mb-3 float-start" v-if="selectedDataBack.pdfs.length===1">PDF</h2>
      <h2 class="mt-2 mb-3 float-start" v-if="selectedDataBack.pdfs.length>1">PDFs</h2>

      <a v-if="selectedDataBack.pdfs.length>0" :href="selectedDataBack.pdfs[displayedPdfIndexBack]"
         class="text-center float-end text-decoration-none" target="_blank">
        <v-btn small class="mt-3" elevation="0" color="black">
          <v-icon size="20" color="white">
            mdi-arrow-expand-all
          </v-icon>
        </v-btn>
      </a>

      <v-carousel :show-arrows="selectedDataBack.pdfs.length>1" v-if="selectedDataBack.pdfs.length>0"
                  @change="pdfChange"
                  hide-delimiters style="width: 100%; height: fit-content;">
        <template v-slot:prev="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
              style="padding:10px; background-color: white; border-radius: 50%;"
              color="#4396e4"
              v-bind="attrs"
              v-on="on"
          >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item
            v-for="(item,i) in selectedDataBack.pdfs"
            :key="i"
        >
          <iframe style="width: 100%; height: 100%;" :src="item"/>
        </v-carousel-item>
      </v-carousel>

    </div>

    <loading-dialog v-model="loading" message="Fetching Flashcard Data"/>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import {required, dynamicQuestion} from '@/utils/validators';
import {getDays} from "@/utils/local";
import {FlashcardsService} from "@/services/flashcards-service";
import {DecksService} from "@/services/deck-service";
import {storage} from "@/plugins/firebase";
import MediaPicker from "@/components/media/MediaPicker";


async function deleteFiles(context, list, message) {
  context.changeLoadingMessage(message + ' ...');
  for (const item of list) {
    context.changeLoadingMessage(message + ': ' + item);
    const fileRef = storage.refFromURL(item);
    fileRef.delete().then(() => {
      window.console.log('file deleted')
    }).catch((e) => {
      window.console.log(e)
    })
  }
}

async function uploadWithMessage(context, list, message, type) {
  context.changeLoadingMessage(message + ' ...');

  const newList = [];
  for (const item of list) {
    const fileName = item.filename + '~' + new Date().getTime() + '' + item.fileExtension
    let reference = storage.ref(type + '/' + fileName);
    let task = reference.put(item.file);
    await task.then(async () => {
      newList.push(await storage.ref(type).child(fileName).getDownloadURL())
    }).catch((e) => window.console.log('uploading image error => ', e));
    context.changeLoadingMessage(message + ': ' + item.filenameWithoutExtension);
  }

  return newList;
}

export default {
  name: 'Form',
  components: {LoadingDialog, SimpleForm, MediaPicker},
  computed: {
    uploadedFrontImages() {
      return (this.flashcard?.front_images || []).map((image) => {
        return {
          source: image,
          options: {type: 'local', metadata: {uploaded: true}}
        };
      });
    },
    uploadedBackImages() {
      return (this.flashcard?.back_images || []).map((image) => {
        return {
          source: image,
          options: {type: 'local', metadata: {uploaded: true}}
        };
      });
    }
  },

  data: () => ({
    displayedPdfIndexFront: null,
    selectedDataFront: {
      images: [],
      videos: [],
      pdfs: [],
    },

    displayedPdfIndexBack: null,
    selectedDataBack: {
      images: [],
      videos: [],
      pdfs: [],
    },
    isEdit: false,
    loading: false,
    service: new FlashcardsService(),
    deckService: new DecksService(),
    flashcard: {
      from_admin: true,
      front_images: [],
      back_images: [],
    },
    decks: [],
    imagesToBeDeleted: [],
    frontImages: [],
    backImages: [],
    question: '',
    answer: '',
    cardTypes: ['Text', 'Image'],
    selectedType: 'Image',
    questionsTypes: ['Plain', 'Cloze Deletion'],
    selectedQuestionType: 'Plain',
    insertText: '{}',
    dynamicText: '',
    dynamicValueNumber: 1,
    front_text: null,
    back_text: null,
  }),

  mounted() {
    this.loadFlashcard();
    this.getDecks();
  },

  methods: {
    required,
    getDays,
    dynamicQuestion,

    openFrontMediaPickerModal() {
      document.getElementById("frontMediaPicker").click();
    },

    openBackMediaPickerModal() {
      document.getElementById("backMediaPicker").click();
    },

    setSelectedDataFront(data) {
      this.selectedDataFront = {
        images: data.images,
        videos: [],
        pdfs: [],
      };
      this.flashcard.front_images = data.images;
    },

    setSelectedDataBack(data) {
      this.selectedDataBack = {
        images: data.images,
        videos: [],
        pdfs: [],
      };
      this.flashcard.back_images = data.images;
    },

    addDynamicText() {
      let txt = ''
      if (window.getSelection) {
        txt = window.getSelection();
      } else if (window.document.getSelection) {
        txt = window.document.getSelection();
      } else if (window.document.selection) {
        txt = window.document.selection.createRange().text;
      }
      if (this.dynamicText === '')
        this.dynamicValueNumber = 1
      if (this.dynamicText.includes('{c' + this.dynamicValueNumber)) {
        ++this.dynamicValueNumber
      }
      this.insertText = '{{c' + (this.dynamicValueNumber) + '::' + txt + '}}'
      const insertText = this.insertText
      const textarea = this.$refs.textarea.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart

      const before = sentence.substr(0, pos)
      let after = sentence.substr(pos, len);
      if (txt !== '' && txt != null && txt) {
        after = after.substr(before.length - 1, txt.length - 1)
        this.dynamicText = before + insertText + after
      } else {
        this.dynamicText = before + insertText + after
      }
    },

    async loadFlashcard() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.flashcard = await this.service.fetchOne(this.$route.query.id);

      this.selectedDataFront.images = this.flashcard.front_images;
      this.selectedDataBack.images = this.flashcard.back_images;


      this.front_text = this.flashcard.frontText
      this.back_text = this.flashcard.backText
      if (this.flashcard.ISClosedDeletion) {
        this.selectedType = 'Text'
        this.selectedQuestionType = 'Cloze Deletion'
        this.dynamicText = this.flashcard.frontText
      } else if (this.flashcard.frontText) {
        this.selectedType = 'Text'
        this.selectedQuestionType = 'Plain'
      } else {
        this.selectedType = 'Image'
      }
      this.loading = false;
    },
    async getDecks() {
      this.decks = await this.deckService.fetchAll()
    },
    preCheck(context) {
      if (!context.validate()) return false;
      if (this.selectedType === 'Image') {
        if ((this.flashcard.front_images === null || this.flashcard.front_images.length <= 0) && (this.frontImages === null || this.frontImages.length <= 0)) {
          context.reportError({
            title: 'Invalid Flashcard Data',
            description:
                'Front image is required'
          });

          return false;
        }
        if ((this.flashcard.back_images === null || this.flashcard.back_images.length <= 0) && (this.backImages === null || this.backImages.length <= 0)) {
          context.reportError({
            title: 'Invalid Flashcard Data',
            description:
                'Back image is required'
          });
          return false;
        }
      }

      return true;
    },
    async submit(context) {
      if (this.preCheck(context)) {
        if (this.isEdit) {
          await deleteFiles(context, this.imagesToBeDeleted, 'Deleting Images');
        }
        if (this.frontImages && this.frontImages.length > 0) {
          this.flashcard.front_images =
              [...(await uploadWithMessage(context, this.frontImages, 'Uploading Front Images', 'flashcards'))]
        }
        if (this.backImages && this.backImages.length > 0) {
          this.flashcard.back_images =
              [...(await uploadWithMessage(context, this.backImages, 'Uploading Back Images', 'flashcards'))]
        }

        if (this.selectedType === 'Text') {
          this.flashcard.frontText = this.front_text
          this.flashcard.backText = this.back_text
          if (this.selectedQuestionType === 'Cloze Deletion') {
            this.flashcard.ISClosedDeletion = true
            this.flashcard.frontText = this.dynamicText
          }
        }

        if (this.isEdit) {
          context.changeLoadingMessage('Updating Flashcard');
          try {
            await this.service.update(this.flashcard);
            return true
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating Flashcard',
              description: e.toString()
            });
            return false
          }
        } else {
          try {
            context.changeLoadingMessage('Creating A New Flashcard');
            await this.service.create(this.flashcard);
            return true
          } catch (e) {
            context.reportError({
              title: 'Error occurred while creating Flashcard',
              description: e?.data?.error
            });
            return false
          }
        }
      }
    },
    // new
    imageRemovedFront(error, file) {
      if (!error && file.getMetadata().uploaded) {
        this.imagesToBeDeleted.push(file.source);
        this.flashcard.front_images.splice(
            this.flashcard.front_images.indexOf(file.source),
            1
        );
      }
      this.frontImages.splice(this.frontImages.indexOf(file))
    },
    imageAddedFront(error, file) {
      if (!error && !file.getMetadata().uploaded) this.frontImages.push(file);
    },
    imageRemovedBack(error, file) {
      if (!error && file.getMetadata().uploaded) {
        this.imagesToBeDeleted.push(file.source);
        this.flashcard.back_images.splice(
            this.flashcard.back_images.indexOf(file.source),
            1
        );
      }
      this.backImages.splice(this.backImages.indexOf(file))
    },
    imageAddedBack(error, file) {
      if (!error && !file.getMetadata().uploaded) this.backImages.push(file);
    },

    async loadFile(source, load) {
      await fetch(source.image_url || source.url || source)
          .then((res) => res.blob().then(load));
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}

.image {
  width: 240px;
  height: 240px;
  object-fit: cover;
}

.fileIcon {
  border: 1px solid #4396e4;
}

</style>
