<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <div class="text-right span-2" @click="edit">
      <v-icon class="v-btn__pre-icon" color="primary" style="cursor: pointer"
        >mdi-pencil</v-icon
      >
    </div>
    <p class="span-2 form__title">Update QOTD</p>
    <div class="span-2 mb-6" style="position: relative">
      <h3 class="mb-2">Question Statement</h3>
      <v-textarea
        v-model="question.statement"
        dense
        readonly
        label="Question Statement"
        class="span-2"
        outlined
        no-resize
      />
    </div>

    <v-select
      v-model="question.category"
      :items="categories"
      :loading="loadingCategories"
      :rules="[required('Select a category')]"
      dense
      item-text="name"
      item-value="id"
      label="Select Category"
      outlined
      return-object
      @change="selectCategory"
      class="span-2"
    />
    <v-select
      v-model="question.subCategory"
      :disabled="!question.category"
      :items="subCategories"
      :rules="[required('Select a sub-category')]"
      dense
      item-text="name"
      label="Select Subcategory"
      class="span-2"
      outlined
      return-object
      @change="
        () => {
          question.statement = null;
        }
      "
    />

    <v-autocomplete
      v-if="!destroy"
      v-model="question"
      :disabled="!question.subCategory"
      label="Select Question"
      item-text="statement"
      item-value="id"
      return-object
      :items="questions"
      :loading="loadingQuestions"
      class="span-2"
      :rules="[required('Select a question')]"
      outlined
      dense
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content
            style="max-width: 400px"
            v-text="data.item"
          ></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content style="max-width: 400px">
            <v-list-item-title>{{ data.item.statement }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>

    <loading-dialog v-model="loading" message="Loading Question Data" />
    <ErrorDialog v-model="error" :error="errorVal" @value="$router.back()" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';

import { required } from '@/utils/validators';
import { CategoryService } from '@/services/category-service';
import { QuestionsService } from '@/services/questions-service';
import { ScheduledQotdService } from '@/services/scheduled-qotd-service';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '@/components/ErrorDialog';

export default {
  name: 'QuestionForm',
  components: {
    ErrorDialog,
    LoadingDialog,
    SimpleForm
  },

  data: () => ({
    destroy: false,
    isEdit: false,
    error: false,
    errorVal: {},

    questions: [],
    questionId: null,
    previousQuestion: null,
    question: {
      category: null,
      subCategory: null,
      statement: null
    },
    loading: false,
    categories: [],

    subCategories: [],
    service: new QuestionsService(),
    sheduledQotdService: new ScheduledQotdService(),
    categoriesService: new CategoryService(),
    loadingCategories: false,
    loadingQuestions: false
  }),

  mounted() {
    this.loadQuestion().then(() => {
      this.loadCategories();
    });
  },

  watch: {
    question: {
      async handler() {
        if (
          this.question?.subCategory &&
          this.question?.subCategory?.id !==
            this.previousQuestion?.subCategory?.id
        ) {
          this.loadingQuestions = true;
          this.questions = await this.service.fetchQuestionsBySubcategory(
            this.question.category.id,
            this.question.subCategory.id
          );
          this.loadingQuestions = false;
        }
      },
      deep: true
    }
  },

  methods: {
    required,
    async loadCategories() {
      this.loadingCategories = true;
      this.categories = await this.categoriesService.fetchAll();
      for (const item of this.categories) {
        if (this.question.category?.id === item.id) {
          this.subCategories = item.subCategories;
          break;
        }
      }
      this.loadingCategories = false;
    },
    selectCategory() {
      this.subCategories = this.question.category.subCategories;
      this.previousQuestion = JSON.parse(JSON.stringify(this.question));
      this.question = {
        statement: null,
        category: this.question.category,
        subCategory: null
      };
    },
    async loadQuestion() {
      try {
        if (!this.$route.query.day && !this.$route.query.id) return;
        this.isEdit = true;
        this.loading = true;
        this.question = await this.service.fetchOne(this.$route.query.id);
        this.question.name = this.question?.statement;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.errorVal = {
          title: 'Question does not exist anymore',
          description: 'This question has been deleted by someone.'
        };
      }
    },

    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Question');
        try {
          const qotd = await this.sheduledQotdService.fetchAll();
          const updatedQotd = {
            ...qotd
          };
          updatedQotd.questions = qotd.questions.map((item) => item.id);
          updatedQotd.questions[this.$route.query.day] = this.question.id;
          await this.sheduledQotdService.update(updatedQotd);
          this.destroy = true;
          return true;
        } catch (e) {
          return false;
        }
      }
    },
    edit() {
      this.$router.push(`/question?id=${this.$route.query.id}`);
    }
  }
};
</script>

<style scoped>
.modified-message >>> div {
  text-align: center;
}

p {
  font-weight: bold;
  text-align: left;
}
</style>
