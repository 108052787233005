<template>
  <v-dialog :value="flag" @input="$emit('value', false)" width="470">
    <v-card style="padding: 10px 0">
      <v-card-title
        style="font-family: google-sans, sans-serif; font-size: 24px"
      >
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" elevation="0" @click="confirm">Confirm</v-btn>
        <v-btn color="secondary" elevation="0" @click="$emit('value', false)"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',

  model: {
    prop: 'flag',
    event: 'value'
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Confirm'
    },
    message: {
      type: String,
      default: 'Are you sure you want to proceed?'
    }
  },

  methods: {
    confirm() {
      this.$emit('confirm');
      this.$emit('value', false);
    }
  }
};
</script>
