import axios from 'axios';

export class DashboardService {
  async fetch(mode, data = null, sgId = null) {
    let url = '/user-stats/dashboard?mode=' + mode;
    if (data) {
      if (data.year) {
        url += '&type=1';
        url += '&year=' + data.year;
      } else {
        url += '&type=0';
      }

      if (data.from && data.to) {
        url += '&from=' + data.from;
        url += '&to=' + data.to;
      }
    } else {
      url += '&type=0';
    }

    if (sgId) {
      url += '&gsid=' + sgId;
    }

    const res = (await axios.get(url)).data;
    return res;
  }

  async fetchUserAnalytics(mode = 4, data = null, type, sgId = null) {
    let url = '/user-stats/dashboard?mode=' + mode + `&type=${type}`;
    if (data.year) url += '&year=' + data.year;
    if (data.from && data.to) {
      url += '&from=' + data.from;
      url += '&to=' + data.to;
    }
    if (sgId) {
      url += '&gsid=' + sgId;
    }
    const res = (await axios.get(url)).data;
    return res;
  }
}
