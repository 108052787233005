<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title">
      {{ isEdit ? 'Update Passage Prompt' : 'Create New Passage Prompt' }}
    </p>

    <v-text-field
      v-model="passagePrompt.title"
      :rules="[required('Title must be provided')]"
      label="Title"
      outlined
      class="span-2"
    />

    <v-textarea
      v-model="passagePrompt.prompt"
      :rules="[required('Prompt must be provided')]"
      label="Prompt"
      outlined
      class="span-2"
      height="300"
    />

    <loading-dialog v-model="loading" message="Fetching Passage Prompt Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import { PassagePromptsService } from '@/services/passage-prompts-service';
import { required } from '@/utils/validators';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    service: new PassagePromptsService(),
    passagePrompt: {
      title: '',
      prompt: ''
    }
  }),

  mounted() {
    this.loadPassagePrompt();
  },

  methods: {
    required,

    async loadPassagePrompt() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.passagePrompt = await this.service.fetchOne(this.$route.query.id);
      this.loading = false;
    },

    preCheck(context) {
      return context.validate();
    },

    async submit(context) {
      if (this.preCheck(context)) {
        if (this.isEdit) {
          context.changeLoadingMessage('Updating Passage Prompt');
          try {
            await this.service.update(this.passagePrompt);
            return true;
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating Passage Prompt',
              description: e.toString()
            });
            return false;
          }
        } else {
          try {
            context.changeLoadingMessage('Creating A New Passage Prompt');
            await this.service.create(this.passagePrompt);
            return true;
          } catch (e) {
            context.reportError({
              title: 'Error occurred while creating Passage Prompt',
              description: e.toString()
            });
            return false;
          }
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
